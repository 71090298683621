import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'customerFilter'
})
export class CustomerFilterPipe implements PipeTransform {
  transform(items: any[], argMdmAccountId:any,argBusinessName:any,argCity:any,argState:any,argZipCode:any ): any[] {
    if (!items) return [];
    if (argMdmAccountId==null) argMdmAccountId="";
    
   return items.filter(it => {
      return  it.narcId !=null && it.narcId.toString().toLowerCase().includes(argMdmAccountId.toString().toLowerCase())
         &&  it.customerName !=null && it.customerName.toLowerCase().includes(argBusinessName.toLowerCase())
         &&  it.city !=null && it.city.toLowerCase().includes(argCity.toLowerCase())
         &&  it.state !=null && it.state.toLowerCase().includes(argState.toLowerCase())
         &&  it.zipCode !=null && it.zipCode.toLowerCase().includes(argZipCode.toLowerCase());
    });
  }
}