import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-session-out',
  templateUrl: './session-out.component.html',
  styleUrls: ['./session-out.component.css']
})
export class SessionOutComponent implements OnInit {

  constructor(private authService: AuthenticationService,
              private router:Router) { }

  ngOnInit(): void {
  }

  tryAgain() {
    //this.authService.logoutSSO();

    localStorage.setItem("LoggedOut", "1");
    this.router.navigate(['/login']);
  }

}
