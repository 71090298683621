import { Component, OnInit } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  breadCrumbs = [];

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.breadCrumbs = this.sharedService.breadCrumbs;
    });
  }
}
