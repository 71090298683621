import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { AppConstants } from '../appConstants';
import { SharedService } from './shared-service.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
    sessionTime = AppConstants.sessionTime; //Session Time: 8 Hours
    userActivity:any;
    userInactive: Subject<any> = new Subject();
    constructor(private authService: AuthenticationService, private sharedService: SharedService) {}

    initSession() {
        this.setSessionTimeout();
        setInterval(() => {
            let st:any=localStorage.getItem('cppLastSessionTime');
            if ((new Date()).getTime() - parseInt(st) > this.sessionTime) {
                if (this.authService.isLoggedIn()) {
                    this.userInactive.next(undefined);
                }
            }
        }, 1000);
    }

    setSessionTimeout() {
        localStorage.setItem('cppLastSessionTime', '' + (new Date()).getTime());
        // this.sharedService.userActivity = setTimeout(() => {
        //     if (this.authService.isLoggedIn()) {
        //         this.userInactive.next(undefined);
        //     }
        // }, this.sessionTime);
    }

    resetTimer(that:any) {
        //clearTimeout(that.sharedService.userActivity);
        that.setSessionTimeout();
    }
}
