import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { AppSettings } from '../appSettings';
import { HeaderService } from './header.service';
declare var $: any;

@Injectable()
export class CustomerService {

  constructor(public _http: HttpClient, private headerService: HeaderService) { }

  public getAllCustomers() {
    let ummUserId = localStorage.getItem("UmmUserId");
    if (localStorage.getItem('isadmin') == '1') {
      let selectedUmmUserId = localStorage.getItem('SelectedUmmUserId');
      if (selectedUmmUserId) {
        ummUserId = localStorage.getItem('SelectedUmmUserId');
      }
    }
    let getAllCustomersUrl: string = AppSettings._getAllCustomersUrl + "?ummUser=" + ummUserId;

    console.log('url:', getAllCustomersUrl);
    console.log('data passed with the url:', '');

    let options = this.headerService.getHeader();

    return this._http.get(getAllCustomersUrl, {headers:options});
  }

  saveCustomer(market:any) {

    let saveCustomerUrl = AppSettings._saveCustomerUrl;
    let options = this.headerService.getHeader();

    console.log('url:', saveCustomerUrl);
    console.log('data passed with the url:', market);

    return this._http.post(saveCustomerUrl, market, {headers:options});
  }

}
