import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../_services/shared-service.service';
import { AppSettings } from '../appSettings';
import { CreateReportService } from '../_services/create-report.service';
import { HeaderService } from '../_services/header.service';
import { DataChangeService } from '../_services/data-change.service';
import * as _ from 'underscore';
import { ReportListService } from '../_services';
import { GoogleAnalyticsService } from '../_services/google-analytics.service';
import { SupportService } from '../_services/support.service';
import { SupportRequestFor } from '../_enums/support-request-for';
import { CustomerListService } from '../_services/customer-list.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  public currentTime = new Date();
  recentReportLists: any=[];
  getAllCust: any;
  public editReportData: any;
  fname = localStorage.getItem('fname');
  date = this.sharedService.formatDate(new Date());
  public name = localStorage.getItem('fname') || '';
  public isAdmin = localStorage.getItem('isadmin') == '1';
  showLoader = false;
  ummUserId: any;
  pageIndex = 1;
  pageSize = 15;
  totalCount = 0;
  viewReportErrorMessage: string = this.sharedService.viewReportErrorMessageText;
  notifyReport: any;
  constructor(private router: Router,
    private customerListService: CustomerListService,
    private http: HttpClient,
    private sharedService: SharedService,
    public createReportService: CreateReportService,
    private headerService: HeaderService,
    private dataChangeService: DataChangeService,
    private gaService: GoogleAnalyticsService,
    private supportService: SupportService) {

    this.sharedService.setData('date', this.date);
    this.sharedService.setData('fname', this.fname);
    this.ummUserId = localStorage.getItem("UmmUserId");
    if (localStorage.getItem('isadmin') == '1') {
      this.ummUserId = localStorage.getItem('SelectedUmmUserId');
      this.viewReportErrorMessage = this.sharedService.viewReportErrorMessageText;
    }
    this.loadDashboard();
  }

  ngOnInit() {
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Dashboard', url: '/dashboard' });
    // $('.modal-backdrop').removeClass("modal-backdrop");
    // $('#selectCustomerModal').modal('hide');
  }

  loadDashboard() {
    this.showLoader = true;
    let header = this.headerService.getHeader();
    this.ummUserId='0362f4ab-a029-4bd2-aac3-1b0300931962'
    let dashboardApiUrl = AppSettings._recentReportByIdUrl + '?userId=' + this.ummUserId + "&pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;
    console.log(dashboardApiUrl);
    this.http.get(dashboardApiUrl, { headers: header })
      .subscribe({
        next:(res:any) =>{
          console.log(res)
          let response = res;
          this.totalCount = response.totalCount;
          let reports = response.report;
          if (!this.recentReportLists) {
            this.recentReportLists = reports;
          } else {
            this.recentReportLists = _.union(this.recentReportLists, reports);
          }
          this.recentReportLists = this.filterReports(this.recentReportLists);
          this.showLoader = false;
        }, error:(err:any)=>{
          console.log(err)
        }, complete:()=> {
          console.log('completed')
        },
      });
  }

  private filterReports(reports: any) {
    let filteredReports: any = [];
    if(reports) {
      reports.forEach((report: any) => {
        if(report && report.businessName != null && report.businessName.length != 0) {
          filteredReports.push(report);
        }
      })
    }
    return filteredReports;
  }

  viewReport(report: any) {
    this.notifyReport = report;
    // if (report.IsReportCreated == 0) {
    //   $('#viewReportDashboardErrorMessage').modal({ backdrop: 'static', keyboard: false });
    //   $('#viewReportDashboardErrorMessage').modal('show');
    // } else {
    this.gaService.sendEvent('ViewReport', 'View Report', 'Called view report');
    this.sharedService.setReport(report);
    this.router.navigate(['./viewreport']);
    //}
  }

  cloneReportConfirm(reportId: any, customerId: any, reportExcelVersion: any) {
    this.createReportService.cloneReportConfirm(reportId, customerId, reportExcelVersion);
  }

  clickCreateReport() {
    console.log('clickCreateReport')
    this.loadCustomerList();
    this.dataChangeService.emitResetSelectCustomerEvent(true);
  }

  onScrollUp() { }

  onScrollDown() {
    console.log('scrolled down!!');
    if (this.recentReportLists.length < this.totalCount) {
      this.pageIndex = this.pageIndex + 1;
      this.loadDashboard();
    }
  }

  onViewReportError() {
  }

  notifySupport() {
    this.supportService.notifySupport(this.notifyReport.reportId, '', SupportRequestFor[SupportRequestFor.ViewReport], null, function (obj:any) {
    })
  }

  // @Input() id: any;
  allCustomer: any = [];
  selectedcustomer: any;
  public intervalID:any;
  comparestring = "Selected Customer";
  isvalid: boolean = false;
  newReportSubscription: any;
  resetSelectCustomerSubscription: any;
  intervalId: any;

  customer: any;
  filteredCustomers: any = [];

  // constructor(private customerListService: CustomerListService,
  //   private sharedService: SharedService,
  //   private dataChangeService: DataChangeService,
  //   private router: Router) 
    // {
  //   this.newReportSubscription = this.dataChangeService.getNewReportAddedEmitter()
  //     .subscribe(item => {
  //       console.log('customer reload after report creation..');
  //       this.loadCustomerList();
  //     });

  //   this.resetSelectCustomerSubscription = this.dataChangeService.getResetSelectCustomerEmitter()
  //     .subscribe((item:any) => {
  //       console.log(item)
  //       this.selectedcustomer = null;
  //     });
  // }

//   ngOnInit() {
// console.log('SelectCustomerComponent')
//     this.intervalId = setInterval(() => {
//       var Tkn = sessionStorage.getItem("Token");

//       let ummUserId = localStorage.getItem("UmmUserId");
//       if (localStorage.getItem('isadmin') == '1') {
//         ummUserId = localStorage.getItem('SelectedUmmUserId');
//       }

//       if (Tkn != null && ummUserId != null) {
//         this.loadCustomerList();
//       }
//     }, 1000);
//   }

  loadCustomerList() {
    clearInterval(this.intervalId);
    this.customerListService.getCustomerListByUserId().subscribe((response:any) => {
      if (response.result != "No record found") {
        let customers = response;
        this.allCustomer = this.filterCustomers(customers);
        console.log(this.allCustomer)
        this.selectedcustomer = null;
        this.customer = null;
      }
    });
  }

  private filterCustomers(customers: any) {
    let filteredCustomers = customers.filter(this.checkForCustomerBusinessName);
    filteredCustomers = this.concatenateCityNdStateWithBusinessName(filteredCustomers);
    return filteredCustomers;
  }

  private checkForCustomerBusinessName(customer: any) {
    if(customer && customer.businessName != null && customer.businessName.trim().length != 0) {
      return true;
    }
    return false;
  }

  private concatenateCityNdStateWithBusinessName(customers: any) {
    customers.forEach((customer: any) => {
      let concatenatedOption = customer.businessName;
      if(customer.city && customer.city.length != 0) {
        concatenatedOption += ", " + customer.city;
      }

      if(customer.stateProvince && customer.stateProvince.length != 0) {
        concatenatedOption += ", " + customer.stateProvince;
      }

      if(customer.custId && customer.custId.length != 0) {
        concatenatedOption += ", " + customer.custId;
      }
      customer.optionToDisplay = concatenatedOption;
    });
    return customers;
  }

  validatecustomer() {
    if (this.customer === 'Select Customer') this.isvalid = false;
    else if (this.customer === '') this.isvalid = false;
    else this.isvalid = true;
  }

  createReportContinue() {
    this.setSelectedCustomer();
    this.sharedService.businessNameTitle = this.selectedcustomer.businessName;
    localStorage.setItem('businessNameTitle', this.selectedcustomer.businessName);

    localStorage.setItem('customer', JSON.stringify(this.selectedcustomer));
    this.sharedService.setCustomer(this.selectedcustomer);

    this.sharedService.report = null;
    localStorage.setItem('report', '');

    this.dataChangeService.emitBusinessNameTitleChangeEvent({ businessNameTitle: this.selectedcustomer.businessName });

    this.router.navigate(['./createreport']);

  }

  private setSelectedCustomer() {
    const splits: any[] = this.customer.split(",");
    const customerId = splits[splits.length-1];

    this.allCustomer.forEach((cust: any) => {
      if(customerId == cust.custId) {
        this.selectedcustomer = cust;
      }
    })
  }

  ngOnDestroy() {
    if (this.newReportSubscription != null) {
      this.newReportSubscription.unsubscribe();
    }

    if (this.resetSelectCustomerSubscription != null) {
      this.resetSelectCustomerSubscription.unsubscribe();
    }
  }
}
