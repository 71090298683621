import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
// import "offline-js";
import { SharedService } from './_services/shared-service.service';
import { AuthenticationService } from './_services';
// import "rxjs/add/operator/distinctUntilChanged"
import { AppConstants } from './appConstants';
import { GoogleAnalyticsService } from './_services/google-analytics.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'app';
  public slide = false;
  pingSeconds: number = 1;
  constructor(public router: Router,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private gaService:GoogleAnalyticsService) {
    console.log('app.component->constructor()');
  }

  ngOnInit() {
    console.log('app.component->ngOnInit()');
    console.log('ami code)');
    this.gaService.sendPageViewInGA();
    this.initObjectsAtStartApp();
    // this.registerServiceWorker(this);
    // this.initOffLineSettings();
  }

  initObjectsAtStartApp() {
    let that = this;
    var element:any = document.getElementById("toggle-wrapper");
    var Tkn = sessionStorage.getItem("Token");
    if (Tkn) {
      this.slide = true;
      element.classList.add("main-wrapper");
    }
    else {
      this.slide = false;
      element.classList.remove("main-wrapper");
    }

    $('#datepicker').datepicker({
      uiLibrary: 'bootstrap'
    });
    $('#datepicker2').datepicker({
      uiLibrary: 'bootstrap'
    });
    $('#datepicker3').datepicker({
      uiLibrary: 'bootstrap'
    });
    $('#datepicker4').datepicker({
      uiLibrary: 'bootstrap'
    });

    $('#menu1').on('click', function () {
      if ($('#menu1 span').is(":visible")) {
        $('#menu1 span').toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
      }
    });

    $("#toggle-wrapper").click(function (e:any) {
      if ($('body').hasClass('toggled'))
        $("body").removeClass("toggled");
    });
  }

  // registerServiceWorker(caller: any) {

  //   if (!navigator.serviceWorker)
  //     return;

  //   navigator.serviceWorker.register('/service-worker.js', { scope: '/' }).then(function (reg:any) {
  //     // registration worked
  //     console.log('Registration succeeded. Scope is ' + reg.scope);
  //   }).catch((err:any) => console.error('**SW FAIL: ' + err));
  // }

  initOffLineSettings() {
    // Offline.options = {
    //   checkOnLoad: true,
    //   interceptRequests: false,
    //   checks: { xhr: { url: '?bypass-sw' } },
    //   requests: true,
    //   reconnect: {
    //     delay: 1000,
    //     initialDelay: 1000
    //   }
    // };

    // Offline.on('up', function () { console.log('up'); document.documentElement.classList.remove('offline-css'); });
    // Offline.on('down', function () { console.log('down'); document.documentElement.classList.add('offline-css'); });

    // setInterval(this.OfflinePing, 1000);
  }

  OfflinePing() {
    let req = new XMLHttpRequest();

    // req.onload = function (e) {
    //   if (navigator.onLine) {
    //     document.documentElement.classList.remove('offline-css');
    //   } else {
    //     document.documentElement.classList.add('offline-css');
    //   }

    // };

    // req.onerror = function (e) {
    //   if (!navigator.onLine) {
    //     document.documentElement.classList.add('offline-css');
    //   } else {
    //     document.documentElement.classList.remove('offline-css');
    //   }
    // };

    // req.timeout = this.pingSeconds * 1000;
    // req.open('GET', '?bypass-sw', true);
    // req.send();
  }

}



