import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { CreateSettingService } from '../_services/create-reportsetting.service';
import { SharedService } from '../_services/shared-service.service';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { DataChangeService } from '../_services/data-change.service';
declare var $: any;

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  @Output() settingsDataChange: EventEmitter<number> = new EventEmitter<any>();
  defaultSettingsData: any;
  @Input() settingsId: any = 0;
  @Input() type: any = "Report";
  @Input() copyFrom: any;
  currencySymbol:any='$';
  popOverValidationTextForSettings: any = "Please fill all the values within the range";
  showLoader = false;
  currencySymbolChangeSubscription: any;
  constructor(private router: Router,
    private createSettingService: CreateSettingService,
    private sharedService: SharedService,
    private dataChangeService:DataChangeService) {
  }

  ngOnInit() {
    let id = _.isNull(this.settingsId) || _.isUndefined(this.settingsId) ? 0 : this.settingsId;
    this.loadSettingsdata(id);
    if (this.router.url != '/createreport') {
      this.currencySymbol = this.sharedService.getCurrencySymbol();
    }
    this.currencySymbolChangeSubscription = this.dataChangeService.getCurrencySymbolChangeEvent()
      .subscribe(symbol => {
        this.currencySymbol=symbol;
      });
  }

  loadSettingsdata(id:any) {
    let that = this;
    this.showLoader = true;
    this.createSettingService.getGlobalSettings(function (data:any) {
      console.log(data);
      let settingsData:any = {};

      for (var key in data) {
        if (!_.isNull(data[key])) {
          settingsData[key] = data[key].toString().replace('%', '');
        }
      }

      that.defaultSettingsData = settingsData;
      that.settingsDataChange.emit(that.defaultSettingsData);
      that.showLoader = false;
      console.log(settingsData);
    }, id, that.type, that.copyFrom);
  }

  settingReset() {
    this.loadSettingsdata(0);
    localStorage.setItem('isSettingsTouched', 'false');
  }

  resetOnEmpty() {
    if ($('#checkIfDisabled').is(':disabled') == true) {
      this.settingReset();
    }
  }

  validateAll(){
    var callFn = true;
    $("#mySetting p").each(function(this:any){
      if ($("p").hasClass('settingsPopup')){
          $(".panel-collapse.in").removeClass("in");
          $(this).closest(".panel-collapse").addClass("in").css("height","auto");
          $(this).focus();
          callFn = false;       
      }        
    });
    return callFn;
  }
  validateGlobalSettings(){ 
     if(this.validateAll())
     {
      this.applySettings();
     }      
}

  applySettings(): void {
    let that = this;
    let data: any;
    this.defaultSettingsData.ummUserIdSettings = localStorage.getItem('UmmUserId');
    that.settingsDataChange.emit(that.defaultSettingsData);
    localStorage.setItem('isSettingsTouched', 'true');
    $('#mySetting').modal('toggle');
  }
}
