import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { CustomerDetailService } from '../_services/customer-detail.service';
import { isNullOrUndefined } from 'util';
declare var $: any;

@Component({
  selector: 'app-scenario-output',
  templateUrl: './scenario-output.component.html',
  styleUrls: ['./scenario-output.component.css']
})
export class ScenarioOutputComponent implements OnInit {

  @Input() scenarioName:any;
  @Input() scenarioOutput:any;
  @Input() sIndex:any;
  @Input() showCheckbox = false;
  reportData = [];
  isNullOrUndefined=isNullOrUndefined;
  constructor(public SharedService: SharedService,
    private CustomerDetailService: CustomerDetailService) { }

  ngOnInit() {
    console.log('Passed scenarioOutput in ScenarioOutputComponent', this.scenarioOutput);
  }
}
