export class AppSettings {

  /*******************************/
  /***********Azure SSO App Setup************/
  /*******************************/

  // Local - Hari
  // public static authority= 'https://login.microsoftonline.com/9e026c5a-9d05-440b-aff5-86f99b57b13c';
  // public static clientId= '25b4a8d4-bf69-4b75-8bb6-57d1169102b4';
  // public static redirectUrl= 'http://localhost:4200';

  // Clar-DEV
  // public static authority= 'https://login.microsoftonline.com/a10ba484-6331-40ee-b0ab-cb737ca60a80';
  // public static clientId= '56064e78-0230-438f-a17f-36679663364e';
  // public static redirectUrl= 'http://localhost:4200';
  // public static redirectUrl= 'https://clar-dev-app.azurewebsites.net';

  // Production
  public static authority= 'https://login.microsoftonline.com/a10ba484-6331-40ee-b0ab-cb737ca60a80';
  public static clientId= '35f58636-47e5-4571-8f91-cb375e7d5a44';
  public static redirectUrl= 'https://www.clarifideprofitplanner.com';


  /*******************************/
  /***********APP URLs************/
  /*******************************/

  //Local
  //public static web_url = 'http://localhost:4200';

  //DEV
  // public static web_url = 'https://clar-dev-app.azurewebsites.net';

  //Production
  public static web_url = 'https://www.clarifideprofitplanner.com';

  /*******************************/
  /***********API URLs************/
  /*******************************/

  //DEV
  // public static base_url = "https://clar-dev-api.azurewebsites.net";// New MP Azure URL-DEV

  //Production
  public static base_url = "https://api.clarifideprofitplanner.com";

  /*******************************/

  public static _logInUrl = AppSettings.base_url + "/api/Login/";

  public static _tokenUrl = AppSettings.base_url + "/api/Token";

  public static _urlPlanA = AppSettings.base_url + "/api/createReport";

  public static _settingDetailsUrl = AppSettings.base_url + "/api/getSettingDetailsbyId/";

  public static _customerDetailurl = AppSettings.base_url + '/api/getCustomerDetailswithReportAndScenariobyId/';

  public static _customerReportCountUrl = AppSettings.base_url + '/api/getTotalReportCount/';

  public static _customerListServiceurl = AppSettings.base_url + "/api/getCustomerListbyUserId";

  public static _deleteReportServiceurl = AppSettings.base_url + "/api/deleteReportAndScenariobyId?reportId=";

  public static _urlPlanB = AppSettings.base_url + "/api/createScenario";

  public static _searchCustomerServiceurl = AppSettings.base_url + '/api/GetRepAccountDetails/?email=';

  public static _updateReportServiceurl = AppSettings.base_url + '/api/updateReportByReportId';

  public static _updateScenarioServiceurl = AppSettings.base_url + '/api/updateScenarioByScenarioId';

  public static _recentCustomerServiceurl = AppSettings.base_url + '/api/GetRecentReportOfAdmin';

  public static _exportToPdfReportBlobUrl = AppSettings.base_url + '/api/exportToPdfReportDownload/';

  public static _exportToPdfScenariotBlobUrl = AppSettings.base_url + '/api/exportToPdfScenarioDownload/';

  public static _exportExcelReportBlobUrl = AppSettings.base_url + '/api/exportExcelReport/';

  public static _exportExcelScenarioBlobUrl = AppSettings.base_url + '/api/exportExcelScenario';

  public static _getHistoricalReportUrl = AppSettings.base_url + '/api/getHistoricalReportwithScenariobyId';

  public static _adminSearchUrl = AppSettings.base_url + '/api/getUserListofAdmin';

  public static _generateOutputUrl = AppSettings.base_url + '/api/GetReportOutput/';

  public static _scenarioOutputUrl = AppSettings.base_url + '/api/GetScenarioOutput/';

  public static _viewReportUrl = AppSettings.base_url + '/api/getReportdetailsWithScenarioDetails';

  public static _generateAnalyticsReportPdfBlob = AppSettings.base_url + '/api/exportToPdfAnalyticsReportDownload';

  public static _viewAnalyticsReportPdfBlob_url = AppSettings.base_url + '/api/viewPdfAnalyticsReportDownload';

  public static _generateAnalyticsReportExcelBlob_url = AppSettings.base_url + '/api/exportToExcelAnalyticsReport';

  public static _cloneReporturl = AppSettings.base_url + "/api/CloneReportWithScenario";

  public static _cloneReportToUpgradeVersionUrl = AppSettings.base_url + "/api/getCloneReportdetailsWithScenarioDetails";

  public static _defaultAdditionalInputUrl = AppSettings.base_url + "/api/getAdditionalInputs/";

  public static _excelMappingUrl = AppSettings.base_url + "/api/GetExcelMapping";

  public static _recentReportByIdUrl = AppSettings.base_url + "/api/GetRecentReportbyId";

  public static _supportUrl = AppSettings.base_url + "/api/SendEmailToSuuport";

  public static _getAllMarketsUrl = AppSettings.base_url + "/api/getAllMarkets";

  public static _saveMarketUrl = AppSettings.base_url + "/api/saveMarket";

  public static _getAllUsersUrl = AppSettings.base_url + "/api/getAllUsers";

  public static _saveUserUrl = AppSettings.base_url + "/api/saveUser";

  public static _getAllCustomersUrl = AppSettings.base_url + "/api/getAllCustomers";

  public static _saveCustomerUrl = AppSettings.base_url + "/api/saveCustomer";

  public static _getAllCountriesWithCurrencyUrl = AppSettings.base_url + "/api/getAllCountries";

  public static _reInviteUserUrl = AppSettings.base_url + "/api/ReInviteUser";

  public static _getAllGlobalCustomersUrl = AppSettings.base_url + "/api/getAllGlobalCustomers";

  public static _authorizationApi = AppSettings.base_url + "/api/GetUserDetailsByEmail";

  public static _updateReportMarketUrl = AppSettings.base_url + "/api/updateReportMarket";
  
  public static _getExcelVersion = AppSettings.base_url + "/api/GetExcelVersion";


  //poc test Urls

  public static _postPlanAresult = AppSettings.base_url + "/api/POC/PostPlanAinput";

  public static _postPlanBresult = AppSettings.base_url + "/api/POC/PostPlanBresult";

  public static _getOriginalExcelsheet = AppSettings.base_url + "/api/POC/GetOriginalExcelsheet";

  public static _getPlaneAData = AppSettings.base_url + "/api/POC/GetPlanAresult";


  //Logs View Urls
  public static _searchLogs = AppSettings.base_url + "/api/GetLogsDetails";



}
