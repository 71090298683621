import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { CreateReportService } from '../_services/create-report.service';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { AppConstants } from '../appConstants';
declare var require: any

declare var $: any;
@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.css']
})
export class GenerateReportComponent implements OnInit {

  public currentTime = new Date();
  public isadmin = localStorage.getItem('isadmin');
  public r:any=localStorage.getItem('report');
  public report = this.sharedService.getReport() || JSON.parse(this.r);

  reportId: any;
  scenarioIds: any;
  analysisColumns: any;

  nonEmptyAnalysisColumns: any;
  analysisRows = [];

  nonGroupHeaders: any;
  groupHeaders: any;
  genReportHeaders: any;

  businessNameTitle: any;
  showLoader = false;

  analysisRowGroups: any = [];
  fieldGroups: any;
  singleLabelSelections = this.sharedService.singleLabelSelections;
  selectiveGroupCollectionData = this.sharedService.selectiveGroupCollectionData;
  constructor(public sharedService: SharedService,
    private createReportService: CreateReportService,
    private router: Router) {

    this.businessNameTitle = this.report.businessName;
    this.analysisColumns = this.sharedService.analysisColumns;

    if (this.analysisColumns == null) {
      this.router.navigateByUrl('/viewreport');
      return;
    }

    this.reportId = this.sharedService.analysisReportId;
    this.scenarioIds = this.sharedService.analysisScenarioIds;

    this.nonEmptyAnalysisColumns = this.sharedService.analysisColumns.filter((c:any) => c != '');
    this.analysisRows = this.sharedService.analysisRows;

    this.nonGroupHeaders = this.sharedService.getNonGroupHeaders();
    this.groupHeaders = this.sharedService.getOutputFieldgroups().map((f:any) => f.groupName);
    this.genReportHeaders = _.union(this.nonGroupHeaders, this.groupHeaders);
  }

  ngOnInit() {
    console.log(this.analysisRows);
    console.log(this.sharedService);
    let grp = _.groupBy(this.analysisRows, function (row:any) { return row.fieldId; });
    for (var key in grp) {
      this.analysisRowGroups.push(grp[key]);
    }
    
    let selectedDistinctGroupdIds = this.analysisRowGroups.map((r:any) => r[0].fieldId);

    this.fieldGroups = this.sharedService.getOutputFieldgroups();
    this.fieldGroups = this.fieldGroups.filter((f:any) => selectedDistinctGroupdIds.indexOf(f.groupId.toString()) != -1);
   
    this.analysisRowGroups=this.reArrangeAnalysisRowGroups();

    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Generate Report', url: '/generatereport' });
    this.analysisColumns[1] = 'Plan A';
  }

  reArrangeAnalysisRowGroups(){
    let that=this;
    let retAnalysisRowGroups:any=[];
    this.fieldGroups.forEach((fGroup:any) => {
      let groupId=fGroup.groupId;
      let objRowGroup=that.analysisRowGroups.filter((a:any)=>a[0].fieldId==groupId)[0];
      retAnalysisRowGroups.push(objRowGroup);
    });
    return retAnalysisRowGroups;
  }

  generateAnalyticsReportPdf() {
    let that = this;
    this.showLoader = true;
    let genReportHtmlString = '<div style="width: 100%;font-size:13px; border-color: #000; border-collapse: collapse;text-align: center;margin-top: 40px;text-align: left;">' + $('#generate-report-section').html() + '</div>';
    let paramScenarioIds = this.scenarioIds.toArray().join('!');
    this.createReportService.generateAnalyticsReportPdfBlob(genReportHtmlString, this.reportId, paramScenarioIds).subscribe(
      (res:any) => {
        let fileName = 'analyticsReport' + '_' + this.reportId;
        this.sharedService.saveFile(res, fileName, AppConstants._pdfExtention);
        that.showLoader = false;
      }
    );
  }

  generateAnalyticsReportExcel() {
    let that = this;
    this.showLoader = true;
    console.log(this.scenarioIds)
    let genReportDataString = JSON.stringify(this.analysisRowGroups);
    let paramScenarioIds = this.scenarioIds.join('!');
    let paramColumns = _.union(["fieldName", "fieldId"], this.nonEmptyAnalysisColumns).join('!');
    this.createReportService.generateAnalyticsReportExcelBlob(genReportDataString, paramColumns, this.reportId, paramScenarioIds).subscribe(function (res: any) {
      that.showLoader = false;
      let fileName = 'analyticsReport' + '_' + that.reportId;
      that.sharedService.saveFile(res, fileName, AppConstants._excelExtention);
    });
  }

  isSingleCell(obj:any) {
    return typeof (obj) != 'object';
  }

  getKeysById(groupId:any) {
    return this.selectiveGroupCollectionData.filter((s:any) => s.tabId == groupId)[0].data.map(k => k.display)
  }

  getGroupRows(groupId:any) {
    return this.analysisRows.filter((r:any) => r.fieldId == groupId).slice(1);
  }

  checkMinusVal(val:any) {
    let retVal = this.sharedService.checkMinusVal(val);
    return retVal;
  }

  setLoader(isShowLoader:any){
    this.showLoader=isShowLoader;
  }
}
