//NgModule Imports - Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//import { PopoverModule } from "ng2-popover";
//import { TooltipModule } from "ng2-tooltip";
// import { CustomFormsModule } from 'ng2-validation';

//NgModule Declarations - Components
// import { routing } from './app.routing';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { GenerateReportComponent } from './generate-report/generate-report.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { HistoricalReportsComponent } from './historical-reports/historical-reports.component';
import { AdminSearchComponent } from './admin-search/admin-search.component';
import { ViewReportComponent } from './view-report/view-report.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { AdminMyReportsComponent } from './admin-my-reports/admin-my-reports.component';
import { ReportComponent } from './report/report.component';
import { ScenarioComponent } from './scenario/scenario.component';
import { ReportOutputComponent } from './report-output/report-output.component';
import { ScenarioOutputComponent } from './scenario-output/scenario-output.component';
import { DataInputComponent } from './data-input/data-input.component';
import { GenerateReportModalComponent } from './generate-report-modal/generate-report-modal.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PagerComponent } from './pager/pager.component';
import { MessagePopupComponent } from './message-popup/message-popup.component';
import { SelectCustomerComponent } from './select-customer/select-customer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SettingComponent } from './setting/setting.component';
import { AddNewScenarioComponent } from './add-new-scenario/add-new-scenario.component';

import { CustomerFilterPipe } from '../app/_pipes/customer-filter.pipe';
import { ReportFilterPipe } from '../app/_pipes/report-filter.pipe';
import { UserFilterPipe } from '../app/_pipes/user-filter.pipe';
import { AddNewScenarioFilterPipe } from '../app/_pipes/add-new-scenario-filter.pipe';

import { NoopAnimationsModule } from '@angular/platform-browser/animations';

//NgModule Providers - services
import { AuthGuard } from './_guards/index';
import { AuthenticationService, CreateReportService, ReportListService, CreateSettingService, AdminSearchService } from './_services/index';
import { EditReportService } from './_services/edit-report.service';
import { RecentCustomerService } from './_services/recent-customer.service';
import { HistoricalReportService } from './_services/historical-report.service';
import { SharedService } from './_services/shared-service.service';
import { SearchCustomerService } from './_services/search-customer.service';
import { CustomerDetailService } from './_services/customer-detail.service';
import { DeleteReportService } from './_services/deleteReport.service';
import { DataChangeService } from './_services/data-change.service';
import { CustomerListService } from './_services/customer-list.service';
import { ViewReportService } from './_services/view-report.service';
import { ScrollToDirective } from './_directives/scroll-to.directive';
import { PagerService } from './_services/pager.service';
import { HeaderService } from './_services/header.service';
import { ComponentHeaderComponent } from './component-header/component-header.component';
import { LoginFooterComponent } from './login-footer/login-footer.component';
import { LoaderComponent } from './loader/loader.component';
// import { FocusModule } from 'angular2-focus';
// import { CookieService } from 'angular2-cookie/services/cookies.service';
// import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { GoogleAnalyticsService } from './_services/google-analytics.service';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { AnalysisReportPptComponent } from './analysis-report-ppt/analysis-report-ppt.component';
import { SupportService } from './_services/support.service';
import { RegisterComponent } from './register/register.component';
import { MarketSetupComponent } from './market-setup/market-setup.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageCustomersComponent } from './manage-customers/manage-customers.component';

// import { UiSwitchModule } from 'ngx-toggle-switch';
import { UiSwitchModule } from 'ngx-ui-switch';

import { environment } from '../environments/environment';
import { MarketSetupService } from './_services/market-setup.service';
import { UserService } from './_services/user.service';
import { CustomerService } from './_services/customer.service';
import { PaginationComponent } from './pagination/pagination.component';
import { MarketFilterPipe } from './_pipes/market-filter.pipe';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
// import {DpDatePickerModule} from 'ng2-date-picker';


// import { Configuration } from 'msal';
// import {
//   MsalModule,
//   MsalInterceptor,
//   MSAL_CONFIG,
//   MSAL_CONFIG_ANGULAR,
//   MsalService,
//   MsalAngularConfiguration
// } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppSettings } from './appSettings';
import { UserHasNoAccesComponent } from './user-has-no-acces/user-has-no-acces.component';
import { SessionOutComponent } from './session-out/session-out.component';
import { LabTechReportComponent } from './lab-tech-report/lab-tech-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PocComponent } from './poc/poc.component';
import { LogsViewComponent } from './logs-view/logs-view.component';
// import { DatepickerModule } from 'ng2-datepicker';

// function MSALConfigFactory(): Configuration {
//   return {
//     auth: {
//       clientId: AppSettings.clientId,
//       authority: AppSettings.authority,
//       validateAuthority: true,
//       redirectUri: AppSettings.redirectUrl,
//       postLogoutRedirectUri: AppSettings.redirectUrl,
//       navigateToLoginRequestUrl: true

//     },
//     cache: {
//       storeAuthStateInCookie: false,
//     }
//   };
// }

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: AppSettings.redirectUrl,
      postLogoutRedirectUri: AppSettings.redirectUrl,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    },
    loginFailedRoute: '/login'
  };
}



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CreateReportComponent,
    HeaderComponent,
    FooterComponent,
    SearchCustomerComponent,
    ReportListComponent,
    ResetpasswordComponent,
    GenerateReportComponent,
    AdminDashboardComponent,
    HistoricalReportsComponent,
    AdminSearchComponent,
    ViewReportComponent,
    CustomerDetailsComponent,
    AdminMyReportsComponent,
    ReportComponent,
    ScenarioComponent,
    ReportOutputComponent,
    ScenarioOutputComponent,
    DataInputComponent,
    GenerateReportModalComponent,
    ChangePasswordComponent,
    ScrollToDirective,
    PagerComponent,
    MessagePopupComponent,
    SelectCustomerComponent,
    BreadcrumbComponent,
    SettingComponent,
    AddNewScenarioComponent,
    CustomerFilterPipe,
    ReportFilterPipe,
    UserFilterPipe,
    AddNewScenarioFilterPipe,
    ComponentHeaderComponent,
    LoginFooterComponent,
    LoaderComponent,
    UpdateProfileComponent,
    AnalysisReportPptComponent,
    RegisterComponent,
    MarketSetupComponent,
    ManageUsersComponent,
    ManageCustomersComponent,
    PaginationComponent,
    MarketFilterPipe,
    UserHasNoAccesComponent,
    SessionOutComponent,
    LabTechReportComponent,
    PocComponent,
    LogsViewComponent
  ],
  imports: [
    BrowserModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    FormsModule,
    NoopAnimationsModule,
    HttpClientModule,
    RouterModule,
    MsalModule,
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule,
    UiSwitchModule
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    CreateReportService,
    ReportListService,
    CreateSettingService,
    SearchCustomerService,
    CustomerDetailService,
    SharedService,
    EditReportService,
    RecentCustomerService,
    HistoricalReportService,
    AdminSearchService,
    ViewReportService,
    DeleteReportService,
    DataChangeService,
    PagerService,
    CustomerListService,
    HeaderService,
    // CookieService,
    GoogleAnalyticsService,
    SupportService,
    MarketSetupService,
    UserService,
    CustomerService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    // {
    //   provide: MSAL_CONFIG,
    //   useFactory: MSALConfigFactory
    // },
    // {
    //   provide: MSAL_CONFIG_ANGULAR,
    //   useFactory: MSALAngularConfigFactory
    // },
    // MsalService
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }

