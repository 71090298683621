import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var gigya: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  userRegistrationSuccessMessage='The user is registered successfully.'
  constructor() { }

  ngOnInit() {
    this.loadRegisterGigyaWidget();
  }

  loadRegisterGigyaWidget() {
    gigya.accounts.showScreenSet({ screenSet: 'RegistrationLogin', containerID: 'register-gigya-widget-container', startScreen: 'gigya-signup-screen', onBeforeScreenLoad: this.fnOnBeforeScreenLoad });
    gigya.socialize.addEventHandlers({ onLogin: this.onUserCreated, context: this });
  }

  fnOnBeforeScreenLoad(eventObj:any) {
  }

  onUserCreated(response:any) {
    $('#userRegistrationModal').modal({ backdrop: 'static', keyboard: false });
    $('#userRegistrationModal').modal('show');
  }

  confirmUserSuccessMsg(){
    //window.location.href = '/#/login';
  }
}
