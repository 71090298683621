// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mySetting .modal-body{
    max-height: 350px;
    overflow-y: scroll;
  }
  .modal-dialog {
    width: 900px;
    margin: 30px auto;
}

.panel-title {
  font-size: 16px;
  color: inherit;
  display: inline;
  background: #0075bc !important;
  padding: 4px 21px;
  color: #fff;
  cursor: pointer;
  border-radius: 20px;
  padding-left: 34px;
}
.panel-title > a.collapsed:before {
  float: left !important;
  content: "\\f067";
  color: #fff;
  position: relative;
  left: 30px;
}
.panel-title > a:before {
  float: left !important;
  font-family: FontAwesome;
  content: "\\f068";
  padding-right: 5px;
  color: #fff;
  position: relative;
  left: 30px;
}
.panel{
  border: none;
}
.panel-default {
  border: none;
}

.nopadding{padding: 0 !important}
#mySetting .panel-body .form-group {
  padding: 0;
  MARGIN: 0 AUTO;
  WIDTH: 98%;
  margin-bottom: 15px;
}

#mySetting  .form-horizontal .control-label {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/app/setting/setting.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,YAAY;IACZ,iBAAiB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,8BAA8B;EAC9B,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,sBAAsB;EACtB,wBAAwB;EACxB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;;AAEA,WAAW,qBAAqB;AAChC;EACE,UAAU;EACV,cAAc;EACd,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["#mySetting .modal-body{\r\n    max-height: 350px;\r\n    overflow-y: scroll;\r\n  }\r\n  .modal-dialog {\r\n    width: 900px;\r\n    margin: 30px auto;\r\n}\r\n\r\n.panel-title {\r\n  font-size: 16px;\r\n  color: inherit;\r\n  display: inline;\r\n  background: #0075bc !important;\r\n  padding: 4px 21px;\r\n  color: #fff;\r\n  cursor: pointer;\r\n  border-radius: 20px;\r\n  padding-left: 34px;\r\n}\r\n.panel-title > a.collapsed:before {\r\n  float: left !important;\r\n  content: \"\\f067\";\r\n  color: #fff;\r\n  position: relative;\r\n  left: 30px;\r\n}\r\n.panel-title > a:before {\r\n  float: left !important;\r\n  font-family: FontAwesome;\r\n  content: \"\\f068\";\r\n  padding-right: 5px;\r\n  color: #fff;\r\n  position: relative;\r\n  left: 30px;\r\n}\r\n.panel{\r\n  border: none;\r\n}\r\n.panel-default {\r\n  border: none;\r\n}\r\n\r\n.nopadding{padding: 0 !important}\r\n#mySetting .panel-body .form-group {\r\n  padding: 0;\r\n  MARGIN: 0 AUTO;\r\n  WIDTH: 98%;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n#mySetting  .form-horizontal .control-label {\r\n  text-transform: capitalize;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
