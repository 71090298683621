import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'reportFilter'
})
export class ReportFilterPipe implements PipeTransform {
  transform(items: any[], argReportName: any, argBusinessName: any, argGeneratedBy: any, argsFromDate: any, argsToDate: any): any[] {
    if (!items) return [];
    if (argReportName == null) argReportName = "";
    if (argBusinessName == null) argBusinessName = "";
    if (argGeneratedBy == null) argGeneratedBy = "";
    if (argsFromDate == null) {
      let firstReportDate: any = items.map(a => a.createdDate).sort(
        function (date1, date2) {
          if (date1 > date2) return 1;
          if (date1 < date2) return -1;
          return 0;
        }
      )[0];
      argsFromDate = new Date(firstReportDate);
    } 
     else {
      argsFromDate=new Date(argsFromDate)
      //  argsFromDate = new Date(argsFromDate.singleDate.date.year,argsFromDate.singleDate.date.month-1,argsFromDate.singleDate.date.day); ;
     }

    if (argsToDate == null) { 
      argsToDate = new Date(); 
    } else {
      argsToDate =new Date(argsToDate) 
      // argsToDate =new Date(argsToDate.singleDate.date.year,argsToDate.singleDate.date.month-1,argsToDate.singleDate.date.day); 
    };
    return items.filter(it => {
      return it.reportName != null && it.reportName.toLowerCase().includes(argReportName.toLowerCase())
        && it.businessName != null && it.businessName.toLowerCase().includes(argBusinessName.toLowerCase())
        && it.generatedBy != null && it.generatedBy.toLowerCase().includes(argGeneratedBy.toLowerCase())
        && it.createdDate != null && (new Date(it.createdDate) >= argsFromDate && new Date(it.createdDate) <= argsToDate);
    });
  }
}