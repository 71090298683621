import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataChangeService } from '../_services/data-change.service';
import { PagerService } from '../_services/pager.service';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit {

  @Input() data: any;
  @Output() pagedItemsChange: EventEmitter<number> = new EventEmitter<number>();

  pager: any = {};
  pagerReloadSubscription: any;
  constructor(public pagerService: PagerService, public dataChangeService: DataChangeService) { }

  ngOnInit() {
    this.setPage(1);

    this.pagerReloadSubscription = this.dataChangeService.getPagerReloadEvent()
      .subscribe(data => {
        this.data = data;
        this.setPage(1);
      });


  }

  setPage(page: number) {

    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.data?.length, page);

    // get current page of items
    let pagedItems = this.data?.slice(this.pager.startIndex, this.pager.endIndex + 1);

    this.pagedItemsChange.emit(pagedItems);
  }

}
