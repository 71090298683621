import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { SharedService } from '../_services/shared-service.service';
import { HeaderService } from '../_services/header.service';
import { AppConstants } from '../appConstants';

declare var $: any;
@Injectable()
export class EditReportService {
    UserEmail = localStorage.getItem("email");
    refEmployeeId = '';
    public lastRecordData: Object ={};
    excelVersion: string = "";
    constructor(public _http: HttpClient,
        public sharedService: SharedService,
        public headerService: HeaderService) {
            this.headerService.getCurrentExcelVersion().subscribe((version: string) => {
                this.excelVersion = version;
              })
    }

    public updateReport(sreportData: any, settingsData: any) {

        let excelVersion = "";
        this.headerService.getCurrentExcelVersion().subscribe((version: string) => {
            excelVersion = version;
        })

        let updateReportServiceurl: string = AppSettings._updateReportServiceurl;

        this.lastRecordData = sreportData;

        let fileInfo = { ReportName: sreportData.name, ReportDesc: sreportData.description };

        let data: any = {};

        data = this.sharedService.bindBodyData(data, sreportData);

        this.sharedService.checkZeroAndConvertToString(data);

        data = this.sharedService.bindDataNumberInputFields(data, sreportData);

        data["annualCowCullRateGoal"] = parseInt(sreportData["annualCowCullRateGoal"]) / 100;
        let cc:any=localStorage.getItem('customer') 
        let customer = this.sharedService.getCustomer() || JSON.parse(cc);
        if (customer != null) {
            data['CustomerId'] = parseInt(customer["custId"]);
            data['globalCustomerId'] = parseInt(customer["globalCustomerId"]);
            data["businessName"] = customer["businessName"];
            data["addressLine1"] = customer["addressLine1"];
            data["stateProvince"] = customer["stateProvince"];
            data["city"] = customer["city"];
            data["postalCode"] = customer["postalCode"];
        }

        data["ummUserId"] = localStorage.getItem("AdminUmmUserId") || localStorage.getItem("UmmUserId");

        data["reportId"] = sreportData.reportId;
        data["ReportName"] = sreportData.name;
        data["ReportDesc"] = sreportData.description;
        data["status"] = sreportData.status;
        data["canEdit"] = 0;
        data["isChangeReport"] = "Yes";
        data["isChangeScenario"] = "No";

        data["isAdminChange"] = "";
        data["userId"] = 0;
        data["settingId"] = 0;
        data["ummUserIdSettings"] = 0;

        data["excelVersion"] = excelVersion;

        data = this.sharedService.bindSettingsData(data, settingsData);
        data.InputCheckBoxId = sreportData.InputCheckBoxId;

        data['MarketId'] =localStorage.getItem('editReportMarketId');

        //data=this.sharedService.inputConversionUSD(data);
        console.log('url:', updateReportServiceurl);
        console.log('data passed with the url:', data);
        let options = this.headerService.getHeader();
        return this._http.put(updateReportServiceurl, data, {headers:options});
    }

    public updateScenario(sScenarioData: any, settingsData: any) {
        let updateScenarioServiceurl: string = AppSettings._updateScenarioServiceurl;
        this.lastRecordData = sScenarioData;
        let fileInfo = { ReportName: sScenarioData.name, ReportDesc: sScenarioData.description };

        let data: any = {};

        data = this.sharedService.bindBodyData(data, sScenarioData);

        this.sharedService.checkZeroAndConvertToString(data);

        data = this.sharedService.bindDataNumberInputFields(data, sScenarioData);

        data["milkingCowsGoal"] = sScenarioData["milkingCowsGoal"];
        data["annualCowCullRateGoal"] = parseInt(sScenarioData["annualCowCullRateGoal"]) / 100;
        let cc:any=localStorage.getItem('customer');
        let customer = this.sharedService.getCustomer() || JSON.parse(cc);

        if (customer != null) {
            data['CustomerId'] = parseInt(customer["custId"]);
            data['globalCustomerId'] = parseInt(customer["globalCustomerId"]);
            data["businessName"] = customer["businessName"];
            data["addressLine1"] = customer["addressLine1"];
            data["stateProvince"] = customer["stateProvince"];
            data["city"] = customer["city"];
            data["postalCode"] = customer["postalCode"];
        }

        data["ummUserId"] = localStorage.getItem("AdminUmmUserId") || localStorage.getItem("UmmUserId");

        data["reportId"] = sScenarioData.reportId;
        data["scenarioId"] = sScenarioData.scenarioId;
        data["scenarioName"] = sScenarioData.name;
        data["scenarioDesc"] = sScenarioData.description;
        data["status"] = sScenarioData.status;
        data["canEdit"] = 0;
        data["isChangeReport"] = "No";
        data["isChangeScenario"] = "Yes";
        data["flagscenario"] = "scenario",
            data["isAdminChange"] = "";
        data["userId"] = 0;
        data["settingId"] = 0;
        data["ummUserIdSettings"] = 0;

        data = this.sharedService.bindSettingsData(data, settingsData);
        data.InputCheckBoxId = sScenarioData.InputCheckBoxId;

        data["excelVersion"] = this.excelVersion;

        //data=this.sharedService.inputConversionUSD(data);
        console.log('url:', updateScenarioServiceurl);
        console.log('data passed with the url:', data);
        let options = this.headerService.getHeader();
        
        return this._http.put(updateScenarioServiceurl, data, {headers:options});
    }

    getLastReportData(): any {
        return this.lastRecordData;
    }

    private extractData(res: Response, callback:any, fileInfo:any) {
        console.log('extractData');
        let body:any = res.json();
        let data = body[0];
        var statusres = data.status;
        data["ReportName"] = fileInfo.ReportName;
        data["ReportDesc"] = fileInfo.ReportDesc;
        data["reportId"] = data.reportId;

        this.lastRecordData = data;
        callback(body);
    }

    private handleErrorObservable(error: Response | any) {
    }
}

