import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

export enum KEY_CODE {
  ENTER = 13
}
@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.css']
})
export class MessagePopupComponent implements OnInit {

  @Input() id: any;
  @Input() title: any;
  @Input() message: any;
  @Input() isSupport: boolean = false;
  @Input() isOk: boolean = true;
  @Input() isCancel: any = false;
  @Input() btnOkText: any = 'OK';
  @Output() support: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }

  fnSupport() {
    this.support.emit(true);
  }

  fnOk() {
    this.confirm.emit(true);
  }

  fnCancel() {
    this.cancel.emit(true);
  }
}
