import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/map';
declare var $: any;

import { AppSettings } from "../appSettings";
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable()
export class HeaderService {

  private _currentExcelVersion = new BehaviorSubject<string>("");

  constructor(private _http: HttpClient) { }

  // getHeader() {
  //   let token=sessionStorage.getItem("Token");
  //   const headers= new HttpHeaders()
  //   console.log(token)
  //   headers.set('Content-Type', 'application/json');
  //   headers.set('Accept', 'application/json');
  //   headers.set('Authorization', 'bearer ' + token);
  //   console.log(headers)
  //   return headers;
  // }

  getHeader() {
    let token=localStorage.getItem("Token");
    // if(token != '' && token != undefined){
    //   token= JSON.parse(token)
    // }
    const headers:any = {
      'Authorization': 'bearer '+token ,
      'Content-Type':'application/json'
    }
    return headers
  }

  currentExcelVer():Observable<any[]>{
    let getExcelVersion: string = AppSettings._getExcelVersion;
    let options:any = this.getHeader();
    return this._http.get<any[]>(getExcelVersion, {headers:options});
  }

  setCurrentExcelVersion(currentExcelVersion: string) {
    this._currentExcelVersion.next(currentExcelVersion);
  }

  getCurrentExcelVersion() {
    return this._currentExcelVersion as Observable<string>;
  }

}
