import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/map';
import { HeaderService } from '../_services/header.service';
import { SharedService } from './shared-service.service';
import { isNullOrUndefined } from 'util';
declare var $: any;

@Injectable()
export class CreateSettingService {
    constructor(public _http: HttpClient,
        private headerService: HeaderService,
        private sharedService: SharedService) {
    }

    getGlobalSettings(callback:any, id:any, type:any, copyFrom:any) {
        let that = this;
        copyFrom = isNullOrUndefined(copyFrom) ? 'none' : copyFrom;
        let settingDetailsUrl = AppSettings._settingDetailsUrl + id + '/' + type + '/' + copyFrom;
        console.log('url:', settingDetailsUrl);
        console.log('data passed with the url:', '');
        let options = this.headerService.getHeader();
        return this._http.get(settingDetailsUrl, {headers:options})
            .subscribe({next:(res: any)=>{
                callback(res[0]);
            }, error:(err:any)=>{
                console.log(err)
            }
        })
    }
}

