import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { HeaderService } from './header.service';
import { saveAs } from 'file-saver';
import { AuthenticationService } from './authentication.service';
import * as _ from 'underscore';
import { AppSettings } from '../appSettings';
import { DataChangeService } from './data-change.service';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs';
declare var $: any;

@Injectable()
export class SharedService {

  public online: boolean=false;
  public dataLabelColor = "";//"9ad7d1";//"e2d0d0";

  public vcustomer: any;
  public report: any;
  public data:any = { arrayOne: [] };
  public editreport = 0;
  public editscenario = 0;
  public addnewscenario = 0;
  public businessNameTitle: any;
  analysisOutputData: any;
  analysisMappedData: any;
  analysisReportId: any;
  analysisScenarioIds: any;
  analysisColumns: any;
  pptChartData: any;
  analysisRows: any = [];
  analysisMappedPartitionGenericPTARows: any;
  analysisMappedPartitionGenericPTAValueRows: any;
  analysisMappedInseminationSumRows: any;
  analysisMappedInseminationCowsRows: any;
  analysisMappedInseminationHeifersRows: any;
  analysisMappedPTADamBreedingReceiptRows: any;
  analysisMappedPTADamBreedingBeefRows: any;
  analysisMappedPTADamBreedingConventionalRows: any;
  analysisMappedPTADamBreedingSexedRows: any;
  analysisMappedPTADamBreedingDonorsRows: any;
  generateReportReportName: any;
  generateReportReportDesc: any;
  createReportInputData: any;
  excelMappingData: any;
  excelVersion: any;
  adminDashbordData: any;
  deleteConfirmationMessage = "You are about to delete the report along with all the scenarios (if any). Do you wish to continue?";
  reportAuthorizeMessage = "Only report owner is allowed to edit/delete/add new scenario operation for the report.";
  deleteSuccessMessage = "Report deleted successfully!";
  breadCrumbs: any = [];
  viewReportErrorMessageText = "Something went wrong when the report was created, please create the report again or notify the support team by clicking the support button."
  userActivity: any;
  constructor(private _http: HttpClient,
    private headerService: HeaderService,
    private _router: Router,
    private authService: AuthenticationService,
    private dataChangeService: DataChangeService) {
  }

  public setCustomer(customer:any) {
    this.vcustomer = customer;
  }

  public getCustomer() {
    return this.vcustomer;
  }

  public setReport(report:any) {
    localStorage.removeItem('report');
    localStorage.setItem('report', JSON.stringify(report));
    this.report = report;
  }

  public getReport() {
    return this.report;
  }

  public setData(vname:any, data:any) {
    this.data[vname] = data;
  }

  public getData(vname:any) {
    return this.data[vname];
  }

  public setArray(arrayname:any) {
    this.data.arrayOne.push(arrayname);
  }

  public getArray(arrayname:any) {
    return this.data.arrayOne;
  }

  formatDate(date:any) {
    var monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct",
      "Nov", "Dec"
    ];
    var day = date.getDate();
    var monthIndex = date.getMonth();
    return date.toString().split(' ')[0] + ', ' + monthNames[monthIndex] + ' ' + date.getDate();
  }

  checkZeroAndConvertToString(data:any) {

    for (var key in data) {
      if ((key.toLowerCase() != 'reportid' && key.toLowerCase() != 'reportname' && key.toLowerCase() != 'reportdesc')
        && (key.toLowerCase() != 'scenarioid' && key.toLowerCase() != 'scenarioname' && key != 'scenariodesc')) {

        if ((parseInt(data[key]) === 0) || (data[key].toString().indexOf('%') != -1) || (data[key].toString() == "NaN")) {
          data[key] = data[key].toString();
        }
        else {
          data[key] = data[key].toString() + "%"
        };

      }
    }
    return data;
  }

  downloadExcel(response:any) {
    let excelResponse: any = response;
    let excelResponseBody = JSON.parse(excelResponse);
    if (excelResponseBody.detailsUrl.length > 0) {
      let excelUrl = excelResponseBody.detailsUrl[0].documentUrl;
      this.downloadFile(excelUrl);
    }
  }

  downloadFile(url:any) {
    var a = document.createElement("a");
    a.href = url;
    a.click();
    a.remove();
  }

  saveExcelFile(res:any, fileName:any) {
    fileName = fileName + '_' + (new Date().toLocaleString()).replace(',', '').split(' ').join('_') + '.xlsx'
    saveAs(res, fileName);
  }

  savePdfFile(res:any, fileName:any) {
    fileName = fileName + '_' + (new Date().toLocaleString()).replace(',', '').split(' ').join('_') + '.pdf'
    saveAs(res, fileName);
  }

  saveFile(res:any, fileName:any, extention:any) {
    fileName = fileName + '_' + (new Date().toLocaleString()).replace(',', '').split(' ').join('_') + extention
    saveAs(res, fileName);
  }

  public getDefaultAdditionalInputData():Observable<any[]> {

    let url = AppSettings._defaultAdditionalInputUrl;

    console.log('url:', url);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    return this._http.get<any[]>(url, {headers:options});
  }

  defaultAdditionalInputSelections =
    [
      { id: 0, display: "No Selection", checked: true },
      { id: 1, display: "Traditional (HO)", checked: false },
      { id: 2, display: "Genomic NM$/CM$ (HO)", checked: false },
      { id: 3, display: "Genomic DWP$ (HO)", checked: false },
      { id: 4, display: "Traditional (Other)", checked: false },
      { id: 5, display: "Genomic NM$/CM$ (Other)", checked: false },
      { id: 6, display: "Genomic DWP$ (Other)", checked: false }
    ]
  defaultAdditionalInputSelectedValues = [];
  defaultAdditionalValues: any;
  initDefaultAdditionalInputData() {
    this.getDefaultAdditionalInputData().subscribe((response:any) => {
      let data: any = response;
      let selectedAdditionalValues = data.SelectedValues;
      this.defaultAdditionalValues = data.DefaultValues[0];
      this.defaultAdditionalInputSelectedValues = selectedAdditionalValues;
      this.dataChangeService.emitDefaultAdditionalInputEvent(true);
    });
  }

  markCheckedAdditionalInputSelection(inputCheckBoxId:any) {
    let defaultAdditionalInputSelections = this.defaultAdditionalInputSelections.map(x => Object.assign({}, x));
    defaultAdditionalInputSelections.forEach(function (ctr) { ctr.checked = false; });
    defaultAdditionalInputSelections.filter(item => item.id == inputCheckBoxId)[0].checked = true;
    return defaultAdditionalInputSelections;
  }

  getDefaultInputData() {

    let defaultInputData: any = {
      name: null,
      description: null,
      annualCowCullRateGoal: 35,
      status: '',
      heifersBeefFirstBreed: 0,
      heifersBeefSecondBreed: 0,
      heifersBeefThirdBreed: 0,
      heifersBeefFourthBreed: 0,

      heifersConventFirstBreed: 100,
      heifersConventSecondBreed: 100,
      heifersConventThirdBreed: 100,
      heifersConventFourthBreed: 100,

      heifersDonorsFirstBreed: 0,
      heifersDonorsSecondBreed: 0,
      heifersDonorsThirdBreed: 0,
      heifersDonorsFourthBreed: 0,

      heifersRecipientsFirstBreed: 0,
      heifersRecipientsSecondBreed: 0,
      heifersRecipientsThirdBreed: 0,
      heifersRecipientsFourthBreed: 0,

      heifersSexedFirstBreed: 0,
      heifersSexedSecondBreed: 0,
      heifersSexedThirdBreed: 0,
      heifersSexedFourthBreed: 0,

      parity1BeefFirstBreed: 0,
      parity1BeefSecondBreed: 0,
      parity1BeefThirdBreed: 0,
      parity1BeefFourthBreed: 0,

      parity1ConventFirstBreed: 100,
      parity1ConventSecondBreed: 100,
      parity1ConventThirdBreed: 100,
      parity1ConventFourthBreed: 100,

      parity1DonorsFirstBreed: 0,
      parity1DonorsSecondBreed: 0,
      parity1DonorsThirdBreed: 0,
      parity1DonorsFourthBreed: 0,

      parity1RecipientsFirstBreed: 0,
      parity1RecipientsSecondBreed: 0,
      parity1RecipientsThirdBreed: 0,
      parity1RecipientsFourthBreed: 0,

      parity1SexedFirstBreed: 0,
      parity1SexedSecondBreed: 0,
      parity1SexedThirdBreed: 0,
      parity1SexedFourthBreed: 0,

      parity2BeefFirstBreed: 0,
      parity2BeefSecondBreed: 0,
      parity2BeefThirdBreed: 0,
      parity2BeefFourthBreed: 0,

      parity2ConventFirstBreed: 100,
      parity2ConventSecondBreed: 100,
      parity2ConventThirdBreed: 100,
      parity2ConventFourthBreed: 100,

      parity2DonorsFirstBreed: 0,
      parity2DonorsSecondBreed: 0,
      parity2DonorsThirdBreed: 0,
      parity2DonorsFourthBreed: 0,

      parity2RecipientsFirstBreed: 0,
      parity2RecipientsSecondBreed: 0,
      parity2RecipientsThirdBreed: 0,
      parity2RecipientsFourthBreed: 0,

      parity2SexedFirstBreed: 0,
      parity2SexedSecondBreed: 0,
      parity2SexedThirdBreed: 0,
      parity2SexedFourthBreed: 0,

      //Additional Input
      geneticTestingCostCalf: 0,
      standardDeviationTTAIndex: 0,
      geneticTrendPTAselection: 0,
      testReliabilitydairycalvesParity0: 0,
      reliabilityBreedingDecisionsParity0: 0,
      reliabilityBreedingDecisionsParity1: 0,

      reliabilityBreedingDecisionsParity2: 0,
      reliabilityBreedingDecisionsParity3: 0,
      reliabilityBreedingDecisionsParity4: 0,
      sirePTAdonor: 0,
      sirePTAsexedSemen: 0,

      sirePTAconventionalSemen: 0,
      embryoPTA: 0,
      embryosPercentageFemales: 0,
      baseValueKeptDairyCalf: 0,
      valueSurplusDairyHeiferCalf: 0,

      costSurplusDairyHeiferCalf: 0,
      valueDairyBullCalf: 0,
      valueCrossbredCalf: 0,
      costPerBreedingWithConventionalSemen: 0,
      costPerBreedingWithSexedSemen: 0,
      costPerBreedingWithBeefSemen: 0,
      costPerEmbryoTransfer: 0
    }

    defaultInputData = this.setAdditionalInputSelection(defaultInputData, this.defaultAdditionalInputSelections[0]);
    defaultInputData = this.setDefaultAdditionalValues(defaultInputData);

    return defaultInputData;
  }

  setDefaultAdditionalValues(inputData:any) {

    if (this.defaultAdditionalValues != null) {

      inputData.sirePTAdonor = this.defaultAdditionalValues.sirePTAdonor;
      inputData.sirePTAsexedSemen = this.defaultAdditionalValues.sirePTAsexedSemen;
      inputData.sirePTAconventionalSemen = this.defaultAdditionalValues.sirePTAconventionalSemen;
      inputData.embryoPTA = this.defaultAdditionalValues.embryoPTA;
      inputData.embryosPercentageFemales = this.defaultAdditionalValues.embryosPercentageFemales;
      inputData.baseValueKeptDairyCalf = this.defaultAdditionalValues.baseValueKeptDairyCalf;
      inputData.valueSurplusDairyHeiferCalf = this.defaultAdditionalValues.valueSurplusDairyHeiferCalf;
      inputData.costSurplusDairyHeiferCalf = this.defaultAdditionalValues.costSurplusDairyHeiferCalf;
      inputData.valueDairyBullCalf = this.defaultAdditionalValues.valueDairyBullCalf;
      inputData.valueCrossbredCalf = this.defaultAdditionalValues.valueCrossbredCalf;
      inputData.costPerBreedingWithConventionalSemen = this.defaultAdditionalValues.costPerBreedingWithConventionalSemen;
      inputData.costPerBreedingWithSexedSemen = this.defaultAdditionalValues.costPerBreedingWithSexedSemen;
      inputData.costPerBreedingWithBeefSemen = this.defaultAdditionalValues.costPerBreedingWithBeefSemen;
      inputData.costPerEmbryoTransfer = this.defaultAdditionalValues.costPerEmbryoTransfer;

    }

    return inputData;
  }

  setAdditionalInputSelection(inputData:any, selection:any) {

    let selectedAdditionalInputDefaultValues: any = this.defaultAdditionalInputSelectedValues.filter((item:any) => item.Id == selection.id)[0];

    if (selectedAdditionalInputDefaultValues != null) {
      inputData.geneticTestingCostCalf = selectedAdditionalInputDefaultValues.geneticTestingCostCalf;
      inputData.standardDeviationTTAIndex = selectedAdditionalInputDefaultValues.standardDeviationTTAIndex;
      inputData.geneticTrendPTAselection = selectedAdditionalInputDefaultValues.geneticTrendPTAselection;
      inputData.testReliabilitydairycalvesParity0 = selectedAdditionalInputDefaultValues.testReliabilitydairycalvesParity0;
      inputData.reliabilityBreedingDecisionsParity0 = selectedAdditionalInputDefaultValues.reliabilityBreedingDecisionsParity0;
      inputData.reliabilityBreedingDecisionsParity1 = selectedAdditionalInputDefaultValues.reliabilityBreedingDecisionsParity1;
      inputData.reliabilityBreedingDecisionsParity2 = selectedAdditionalInputDefaultValues.reliabilityBreedingDecisionsParity2;
      inputData.reliabilityBreedingDecisionsParity3 = selectedAdditionalInputDefaultValues.reliabilityBreedingDecisionsParity3;
      inputData.reliabilityBreedingDecisionsParity4 = selectedAdditionalInputDefaultValues.reliabilityBreedingDecisionsParity4;
    }

    return inputData;
  }

  bindDataInput(dataInput:any, type:any) {
    let dInputData: any = {};

    dInputData = {

      annualCowCullRateGoal: dataInput.annualCowCullRateGoal,

      heifersBeefFirstBreed: dataInput.heifersBeefFirstBreed.toString().replace('%', ''),
      heifersBeefSecondBreed: dataInput.heifersBeefSecondBreed.toString().replace('%', ''),
      heifersBeefThirdBreed: dataInput.heifersBeefThirdBreed.toString().replace('%', ''),
      heifersBeefFourthBreed: dataInput.heifersBeefFourthBreed.toString().replace('%', ''),

      heifersConventFirstBreed: dataInput.heifersConventFirstBreed.toString().replace('%', ''),
      heifersConventSecondBreed: dataInput.heifersConventSecondBreed.toString().replace('%', ''),
      heifersConventThirdBreed: dataInput.heifersConventThirdBreed.toString().replace('%', ''),
      heifersConventFourthBreed: dataInput.heifersConventFourthBreed.toString().replace('%', ''),

      heifersDonorsFirstBreed: dataInput.heifersDonorsFirstBreed.toString().replace('%', ''),
      heifersDonorsSecondBreed: dataInput.heifersDonorsSecondBreed.toString().replace('%', ''),
      heifersDonorsThirdBreed: dataInput.heifersDonorsThirdBreed.toString().replace('%', ''),
      heifersDonorsFourthBreed: dataInput.heifersDonorsFourthBreed.toString().replace('%', ''),

      heifersRecipientsFirstBreed: dataInput.heifersRecipientsFirstBreed.toString().replace('%', ''),
      heifersRecipientsSecondBreed: dataInput.heifersRecipientsSecondBreed.toString().replace('%', ''),
      heifersRecipientsThirdBreed: dataInput.heifersRecipientsThirdBreed.toString().replace('%', ''),
      heifersRecipientsFourthBreed: dataInput.heifersRecipientsFourthBreed.toString().replace('%', ''),

      heifersSexedFirstBreed: dataInput.heifersSexedFirstBreed.toString().replace('%', ''),
      heifersSexedSecondBreed: dataInput.heifersSexedSecondBreed.toString().replace('%', ''),
      heifersSexedThirdBreed: dataInput.heifersSexedThirdBreed.toString().replace('%', ''),
      heifersSexedFourthBreed: dataInput.heifersSexedFourthBreed.toString().replace('%', ''),

      parity1BeefFirstBreed: dataInput.parity1BeefFirstBreed.toString().replace('%', ''),
      parity1BeefSecondBreed: dataInput.parity1BeefSecondBreed.toString().replace('%', ''),
      parity1BeefThirdBreed: dataInput.parity1BeefThirdBreed.toString().replace('%', ''),
      parity1BeefFourthBreed: dataInput.parity1BeefFourthBreed.toString().replace('%', ''),

      parity1ConventFirstBreed: dataInput.parity1ConventFirstBreed.toString().replace('%', ''),
      parity1ConventSecondBreed: dataInput.parity1ConventSecondBreed.toString().replace('%', ''),
      parity1ConventThirdBreed: dataInput.parity1ConventThirdBreed.toString().replace('%', ''),
      parity1ConventFourthBreed: dataInput.parity1ConventFourthBreed.toString().replace('%', ''),

      parity1DonorsFirstBreed: dataInput.parity1DonorsFirstBreed.toString().replace('%', ''),
      parity1DonorsSecondBreed: dataInput.parity1DonorsSecondBreed.toString().replace('%', ''),
      parity1DonorsThirdBreed: dataInput.parity1DonorsThirdBreed.toString().replace('%', ''),
      parity1DonorsFourthBreed: dataInput.parity1DonorsFourthBreed.toString().replace('%', ''),

      parity1RecipientsFirstBreed: dataInput.parity1RecipientsFirstBreed.toString().replace('%', ''),
      parity1RecipientsSecondBreed: dataInput.parity1RecipientsSecondBreed.toString().replace('%', ''),
      parity1RecipientsThirdBreed: dataInput.parity1RecipientsThirdBreed.toString().replace('%', ''),
      parity1RecipientsFourthBreed: dataInput.parity1RecipientsFourthBreed.toString().replace('%', ''),

      parity1SexedFirstBreed: dataInput.parity1SexedFirstBreed.toString().replace('%', ''),
      parity1SexedSecondBreed: dataInput.parity1SexedSecondBreed.toString().replace('%', ''),
      parity1SexedThirdBreed: dataInput.parity1SexedThirdBreed.toString().replace('%', ''),
      parity1SexedFourthBreed: dataInput.parity1SexedFourthBreed.toString().replace('%', ''),

      parity2BeefFirstBreed: dataInput.parity2BeefFirstBreed.toString().replace('%', ''),
      parity2BeefSecondBreed: dataInput.parity2BeefSecondBreed.toString().replace('%', ''),
      parity2BeefThirdBreed: dataInput.parity2BeefThirdBreed.toString().replace('%', ''),
      parity2BeefFourthBreed: dataInput.parity2BeefFourthBreed.toString().replace('%', ''),

      parity2ConventFirstBreed: dataInput.parity2ConventFirstBreed.toString().replace('%', ''),
      parity2ConventSecondBreed: dataInput.parity2ConventSecondBreed.toString().replace('%', ''),
      parity2ConventThirdBreed: dataInput.parity2ConventThirdBreed.toString().replace('%', ''),
      parity2ConventFourthBreed: dataInput.parity2ConventFourthBreed.toString().replace('%', ''),

      parity2DonorsFirstBreed: dataInput.parity2DonorsFirstBreed.toString().replace('%', ''),
      parity2DonorsSecondBreed: dataInput.parity2DonorsSecondBreed.toString().replace('%', ''),
      parity2DonorsThirdBreed: dataInput.parity2DonorsThirdBreed.toString().replace('%', ''),
      parity2DonorsFourthBreed: dataInput.parity2DonorsFourthBreed.toString().replace('%', ''),

      parity2RecipientsFirstBreed: dataInput.parity2RecipientsFirstBreed.toString().replace('%', ''),
      parity2RecipientsSecondBreed: dataInput.parity2RecipientsSecondBreed.toString().replace('%', ''),
      parity2RecipientsThirdBreed: dataInput.parity2RecipientsThirdBreed.toString().replace('%', ''),
      parity2RecipientsFourthBreed: dataInput.parity2RecipientsFourthBreed.toString().replace('%', ''),

      parity2SexedFirstBreed: dataInput.parity2SexedFirstBreed.toString().replace('%', ''),
      parity2SexedSecondBreed: dataInput.parity2SexedSecondBreed.toString().replace('%', ''),
      parity2SexedThirdBreed: dataInput.parity2SexedThirdBreed.toString().replace('%', ''),
      parity2SexedFourthBreed: dataInput.parity2SexedFourthBreed.toString().replace('%', ''),

      testReliabilitydairycalvesParity0: dataInput.testReliabilitydairycalvesParity0.toString().replace('%', ''),
      reliabilityBreedingDecisionsParity0: dataInput.reliabilityBreedingDecisionsParity0.toString().replace('%', ''),
      reliabilityBreedingDecisionsParity1: dataInput.reliabilityBreedingDecisionsParity1.toString().replace('%', ''),
      reliabilityBreedingDecisionsParity2: dataInput.reliabilityBreedingDecisionsParity2.toString().replace('%', ''),
      reliabilityBreedingDecisionsParity3: dataInput.reliabilityBreedingDecisionsParity3.toString().replace('%', ''),
      reliabilityBreedingDecisionsParity4: dataInput.reliabilityBreedingDecisionsParity4.toString().replace('%', ''),
      embryosPercentageFemales: dataInput.embryosPercentageFemales.toString().replace('%', ''),

      geneticTestingCostCalf: dataInput.geneticTestingCostCalf.toString().replace('%', ''),
      standardDeviationTTAIndex: dataInput.standardDeviationTTAIndex.toString().replace('%', ''),
      geneticTrendPTAselection: dataInput.geneticTrendPTAselection.toString().replace('%', ''),
      sirePTAdonor: dataInput.sirePTAdonor.toString().replace('%', ''),
      sirePTAsexedSemen: dataInput.sirePTAsexedSemen.toString().replace('%', ''),
      sirePTAconventionalSemen: dataInput.sirePTAconventionalSemen.toString().replace('%', ''),
      embryoPTA: dataInput.embryoPTA.toString().replace('%', ''),
      baseValueKeptDairyCalf: dataInput.baseValueKeptDairyCalf.toString().replace('%', ''),
      valueSurplusDairyHeiferCalf: dataInput.valueSurplusDairyHeiferCalf.toString().replace('%', ''),
      costSurplusDairyHeiferCalf: dataInput.costSurplusDairyHeiferCalf.toString().replace('%', ''),
      valueDairyBullCalf: dataInput.valueDairyBullCalf.toString().replace('%', ''),
      valueCrossbredCalf: dataInput.valueCrossbredCalf.toString().replace('%', ''),
      costPerBreedingWithConventionalSemen: dataInput.costPerBreedingWithConventionalSemen.toString().replace('%', ''),
      costPerBreedingWithSexedSemen: dataInput.costPerBreedingWithSexedSemen.toString().replace('%', ''),
      costPerBreedingWithBeefSemen: dataInput.costPerBreedingWithBeefSemen.toString().replace('%', ''),
      costPerEmbryoTransfer: dataInput.costPerEmbryoTransfer.toString().replace('%', '')
    }


    dInputData.ummUserId = dataInput.ummUserId;
    dInputData.mdmAddressId = dataInput.mdmAddressId;
    dInputData.createdDate = dataInput.createdDate;
    dInputData.updatedDate = dataInput.updatedDate;
    dInputData.InputCheckBoxId = dataInput.InputCheckBoxId;

    if (type == 'report') {
      dInputData.id = dataInput.reportId;
      dInputData.reportId = dataInput.reportId;
      dInputData.name = dataInput.reportName;
      dInputData.reportName = dataInput.reportName;
      dInputData.description = dataInput.reportDesc;
      dInputData.reportDesc = dataInput.reportDesc;
      dInputData.analyticsReportUrl = dataInput.analyticsReportUrl;
      dInputData.status = dataInput.reportStatus;
    } else if (type == 'scenario') {
      dInputData.id = dataInput.scenarioId;
      dInputData.scenarioId = dataInput.scenarioId;
      dInputData.name = dataInput.scenarioName;
      dInputData.scenarioName = dataInput.scenarioName;
      dInputData.description = dataInput.scenarioDesc;
      dInputData.scenarioDesc = dataInput.scenarioDesc;
      dInputData.status = dataInput.scenarioStatus;
    }

    return dInputData;
  }

  bindBodyData(data:any, body:any) {
    data["heifersRecipientsFirstBreed"] = body.heifersRecipientsFirstBreed || 0;
    data["heifersRecipientsSecondBreed"] = body.heifersRecipientsSecondBreed || 0;
    data["heifersRecipientsThirdBreed"] = body.heifersRecipientsThirdBreed || 0;
    data["heifersRecipientsFourthBreed"] = body.heifersRecipientsFourthBreed || 0;
    data["heifersBeefFirstBreed"] = body.heifersBeefFirstBreed || 0;
    data["heifersBeefSecondBreed"] = body.heifersBeefSecondBreed || 0;
    data["heifersBeefThirdBreed"] = body.heifersBeefThirdBreed || 0;
    data["heifersBeefFourthBreed"] = body.heifersBeefFourthBreed || 0;

    data["heifersConventFirstBreed"] = body.heifersConventFirstBreed || 0;
    data["heifersConventSecondBreed"] = body.heifersConventSecondBreed || 0;
    data["heifersConventThirdBreed"] = body.heifersConventThirdBreed || 0;
    data["heifersConventFourthBreed"] = body.heifersConventFourthBreed || 0;

    data["heifersSexedFirstBreed"] = body.heifersSexedFirstBreed || 0;
    data["heifersSexedSecondBreed"] = body.heifersSexedSecondBreed || 0;
    data["heifersSexedThirdBreed"] = body.heifersSexedThirdBreed || 0;
    data["heifersSexedFourthBreed"] = body.heifersSexedFourthBreed || 0;
    data["heifersDonorsFirstBreed"] = body.heifersDonorsFirstBreed || 0;
    data["heifersDonorsSecondBreed"] = body.heifersDonorsSecondBreed || 0;
    data["heifersDonorsThirdBreed"] = body.heifersDonorsThirdBreed || 0;
    data["heifersDonorsFourthBreed"] = body.heifersDonorsFourthBreed || 0;

    data["parity1RecipientsFirstBreed"] = body.parity1RecipientsFirstBreed || 0;
    data["parity1RecipientsSecondBreed"] = body.parity1RecipientsSecondBreed || 0;
    data["parity1RecipientsThirdBreed"] = body.parity1RecipientsThirdBreed || 0;
    data["parity1RecipientsFourthBreed"] = body.parity1RecipientsFourthBreed || 0;
    data["parity1BeefFirstBreed"] = body.parity1BeefFirstBreed || 0;
    data["parity1BeefSecondBreed"] = body.parity1BeefSecondBreed || 0;
    data["parity1BeefThirdBreed"] = body.parity1BeefThirdBreed || 0;
    data["parity1BeefFourthBreed"] = body.parity1BeefFourthBreed || 0;
    data["parity1ConventFirstBreed"] = body.parity1ConventFirstBreed || 0;
    data["parity1ConventSecondBreed"] = body.parity1ConventSecondBreed || 0;
    data["parity1ConventThirdBreed"] = body.parity1ConventThirdBreed || 0;
    data["parity1ConventFourthBreed"] = body.parity1ConventFourthBreed || 0;
    data["parity1SexedFirstBreed"] = body.parity1SexedFirstBreed || 0;
    data["parity1SexedSecondBreed"] = body.parity1SexedSecondBreed || 0;
    data["parity1SexedThirdBreed"] = body.parity1SexedThirdBreed || 0;
    data["parity1SexedFourthBreed"] = body.parity1SexedFourthBreed || 0;
    data["parity1DonorsFirstBreed"] = body.parity1DonorsFirstBreed || 0;
    data["parity1DonorsSecondBreed"] = body.parity1DonorsSecondBreed || 0;
    data["parity1DonorsThirdBreed"] = body.parity1DonorsThirdBreed || 0;
    data["parity1DonorsFourthBreed"] = body.parity1DonorsFourthBreed || 0;

    data["parity2RecipientsFirstBreed"] = body.parity2RecipientsFirstBreed || 0;
    data["parity2RecipientsSecondBreed"] = body.parity2RecipientsSecondBreed || 0;
    data["parity2RecipientsThirdBreed"] = body.parity2RecipientsThirdBreed || 0;
    data["parity2RecipientsFourthBreed"] = body.parity2RecipientsFourthBreed || 0;
    data["parity2BeefFirstBreed"] = body.parity2BeefFirstBreed || 0;
    data["parity2BeefSecondBreed"] = body.parity2BeefSecondBreed || 0;
    data["parity2BeefThirdBreed"] = body.parity2BeefThirdBreed || 0;
    data["parity2BeefFourthBreed"] = body.parity2BeefFourthBreed || 0;
    data["parity2ConventFirstBreed"] = body.parity2ConventFirstBreed || 0;
    data["parity2ConventSecondBreed"] = body.parity2ConventSecondBreed || 0;
    data["parity2ConventThirdBreed"] = body.parity2ConventThirdBreed || 0;
    data["parity2ConventFourthBreed"] = body.parity2ConventFourthBreed || 0;
    data["parity2SexedFirstBreed"] = body.parity2SexedFirstBreed || 0;
    data["parity2SexedSecondBreed"] = body.parity2SexedSecondBreed || 0;
    data["parity2SexedThirdBreed"] = body.parity2SexedThirdBreed || 0;
    data["parity2SexedFourthBreed"] = body.parity2SexedFourthBreed || 0;
    data["parity2DonorsFirstBreed"] = body.parity2DonorsFirstBreed || 0;
    data["parity2DonorsSecondBreed"] = body.parity2DonorsSecondBreed || 0;
    data["parity2DonorsThirdBreed"] = body.parity2DonorsThirdBreed || 0;
    data["parity2DonorsFourthBreed"] = body.parity2DonorsFourthBreed || 0;

    //Additional Input
    data["testReliabilitydairycalvesParity0"] = body.testReliabilitydairycalvesParity0 || 0;
    data["reliabilityBreedingDecisionsParity0"] = body.reliabilityBreedingDecisionsParity0 || 0;
    data["reliabilityBreedingDecisionsParity1"] = body.reliabilityBreedingDecisionsParity1 || 0;
    data["reliabilityBreedingDecisionsParity2"] = body.reliabilityBreedingDecisionsParity2 || 0;
    data["reliabilityBreedingDecisionsParity3"] = body.reliabilityBreedingDecisionsParity3 || 0;
    data["reliabilityBreedingDecisionsParity4"] = body.reliabilityBreedingDecisionsParity4 || 0;
    data["embryosPercentageFemales"] = body.embryosPercentageFemales || 0;

    return data;
  }

  bindDataNumberInputFields(data:any, body:any) {
    data["geneticTestingCostCalf"] = body.geneticTestingCostCalf || 0;
    data["standardDeviationTTAIndex"] = body.standardDeviationTTAIndex || 0;
    data["geneticTrendPTAselection"] = body.geneticTrendPTAselection || 0;
    data["sirePTAdonor"] = body.sirePTAdonor || 0;
    data["sirePTAsexedSemen"] = body.sirePTAsexedSemen || 0;
    data["sirePTAconventionalSemen"] = body.sirePTAconventionalSemen || 0;
    data["embryoPTA"] = body.embryoPTA || 0;
    data["baseValueKeptDairyCalf"] = body.baseValueKeptDairyCalf || 0;
    data["valueSurplusDairyHeiferCalf"] = body.valueSurplusDairyHeiferCalf || 0;
    data["costSurplusDairyHeiferCalf"] = body.costSurplusDairyHeiferCalf || 0;
    data["valueDairyBullCalf"] = body.valueDairyBullCalf || 0;
    data["valueCrossbredCalf"] = body.valueCrossbredCalf || 0;
    data["costPerBreedingWithConventionalSemen"] = body.costPerBreedingWithConventionalSemen || 0;
    data["costPerBreedingWithSexedSemen"] = body.costPerBreedingWithSexedSemen || 0;
    data["costPerBreedingWithBeefSemen"] = body.costPerBreedingWithBeefSemen || 0;
    data["costPerEmbryoTransfer"] = body.costPerEmbryoTransfer || 0;
    return data;
  }

  bindSettingsData(data:any, sData:any) {

    if (!isNullOrUndefined(sData)) {

      let settingsData:any = [];
      let numericSettingsData:any = [];

      //Genetic Information
      numericSettingsData["startLevelPTAindex"] = sData["startLevelPTAindex"]//N
      settingsData["phenotypicResponseGeneticsMultiplier"] = sData["phenotypicResponseGeneticsMultiplier"];//%

      //Money Based Information
      settingsData["discountingGeneflowTested"] = sData["discountingGeneflowTested"];//%
      settingsData["discountingGeneflowOffspringTested"] = sData["discountingGeneflowOffspringTested"];//%
      numericSettingsData["milkPrice"] = sData["milkPrice"];//N
      numericSettingsData["milkFeedPrice"] = sData["milkFeedPrice"];//N
      numericSettingsData["cowCullPrice"] = sData["cowCullPrice"];//N
      numericSettingsData["costToRaiseHeifer"] = sData["costToRaiseHeifer"];//N

      //Heifer Reproduction 
      numericSettingsData["heiferAgeFirstBreeding"] = sData["heiferAgeFirstBreeding"];//N
      numericSettingsData["heiferAgeLastbreeding"] = sData["heiferAgeLastbreeding"];//N
      settingsData["heifersEstrusDetectionIndex"] = sData["heifersEstrusDetectionIndex"];//%
      settingsData["heifersPconceptionBeefSemen"] = sData["heifersPconceptionBeefSemen"];//%
      settingsData["heifersPconceptionConventionalSemen"] = sData["heifersPconceptionConventionalSemen"];//%
      settingsData["heifersPconceptionSexedSemen"] = sData["heifersPconceptionSexedSemen"];//%
      settingsData["heifersPconceptionEmbryotransfer"] = sData["heifersPconceptionEmbryotransfer"];//%

      //Cow Reproduction
      numericSettingsData["cowDIMAtFirstBreeding"] = sData["cowDIMAtFirstBreeding"];//N
      numericSettingsData["cowDIMAtLastBreeding"] = sData["cowDIMAtLastBreeding"];//N
      settingsData["parity1EstrusDetectionIndex"] = sData["parity1EstrusDetectionIndex"];//%
      settingsData["parity1PconceptionBeefSemen"] = sData["parity1PconceptionBeefSemen"];//%
      settingsData["parity1PconceptionConventionalSemen"] = sData["parity1PconceptionConventionalSemen"];//%
      settingsData["parity1PconceptionSexedSemen"] = sData["parity1PconceptionSexedSemen"];//%
      settingsData["parity1PconceptionEmbryoTransfer"] = sData["parity1PconceptionEmbryoTransfer"];//%
      settingsData["parity2PlusEstrusDetectionIndex"] = sData["parity2PlusEstrusDetectionIndex"];//%
      settingsData["parity2PlusPconceptionBeefSemen"] = sData["parity2PlusPconceptionBeefSemen"];//%
      settingsData["parity2PlusPconceptionConventionalSemen"] = sData["parity2PlusPconceptionConventionalSemen"];//%
      settingsData["parity2PlusPconceptionSexedSemen"] = sData["parity2PlusPconceptionSexedSemen"];//%
      settingsData["parity2PlusPconceptionEmbryoTransfer"] = sData["parity2PlusPconceptionEmbryoTransfer"];//%

      //Other Information
      numericSettingsData["rollingHerdAverageMilk"] = sData["rollingHerdAverageMilk"];//N
      settingsData["relativeRepro"] = sData["relativeRepro"];//%
      settingsData["correlationCullingMilkYield"] = 0;
      numericSettingsData["milkingCowsGoal"] = sData["milkingCowsGoal"];//N
      settingsData["heiferLossPreBreeding"] = sData["heiferLossPreBreeding"];//%

      this.checkZeroAndConvertToString(settingsData);

      for (var key in settingsData) {
        if (!isNullOrUndefined(settingsData[key])) {
          data[key] = settingsData[key].toString();
        }

      }

      for (var key in numericSettingsData) {
        if (!isNullOrUndefined(numericSettingsData[key])) {
          data[key] = numericSettingsData[key].toString();
        }
      }

      data["settingId"] = sData["settingId"];
      data["ummUserIdSettings"] = sData["ummUserIdSettings"];
      data["flag"] = "1";
    }

    return data;
  }

  getNonGroupHeaders() {
    return ['% Calves', 'PTA', 'Avg (Yrs)', '△ PTA', '△ Value', 'STA/PTA', '△ PTA Or STA', '△ Value', 'sum', 'heifers', 'cows', 'recipient', 'beef', 'conventional', 'sexed', 'donors'];
  }

  singleLabelSelections = ["1", "2", "5", "6", "7", "8"];
  getOutputFieldgroups() {
    let that = this;
    console.log(this.analysisOutputData);
    let outputFieldGroups:any = [];
    if (this.analysisOutputData.ScenarioOutput.length > 0) {
      let data = this.analysisOutputData.ScenarioOutput[0].data;
      _.each(data, function (item: any) {

        let grpObject: any = {};
        grpObject.groupId = item.tabId;
        grpObject.groupName = item.tabName;

        grpObject.data = item.tabData.map(function (t:any) { return { key: item.tabId, display: t.primaryLabel }; })
        outputFieldGroups.push(grpObject);

      }
      );
    }
    return outputFieldGroups;
  }

  selectiveGroupCollectionData = [
    {
      tabId: 3,
      data: [
        { key: "3", display: "% Calves", cellMap: "W" },
        { key: "3", display: "PTA", cellMap: "X" }
      ]
    },
    {
      tabId: 4,
      data: [
        { key: "4", display: "Avg (Yrs)", cellMap: "W" },
        { key: "4", display: "PTA", cellMap: "X" }
      ]
    },
    {
      tabId: 9,
      data: [
        { key: "9", display: "△ PTA", cellMap: "W" },
        { key: "9", display: "△ Value", cellMap: "X" }
      ]
    },
    {
      tabId: 10,
      data: [
        { key: "10", display: "△ PTA Or STA", cellMap: "W" },
        { key: "10", display: "△ Value", cellMap: "X" }
      ]
    },
    {
      tabId: 11,
      data: [
        { key: "11", display: "sum", cellMap: "V" },
        { key: "11", display: "heifers", cellMap: "W" },
        { key: "11", display: "cows", cellMap: "X" }
      ]
    },
    {
      tabId: 12,
      data: [
        { key: "12", display: "donors", cellMap: "X" },
        { key: "12", display: "sexed", cellMap: "W" },
        { key: "12", display: "conventional", cellMap: "V" },
        { key: "12", display: "beef", cellMap: "U" },
        { key: "12", display: "recipient", cellMap: "T" }
      ]
    },
    {
      tabId: 13,
      data: [
        { key: "13", display: "△ PTA", cellMap: "W" },
        { key: "13", display: "△ Value", cellMap: "X" }
      ]
    },
    {
      tabId: 14,
      data: [
        { key: "14", display: "△ PTA Or STA", cellMap: "W" },
        { key: "14", display: "△ Value", cellMap: "X" }
      ]
    },
  ]
  // getSelectiveGroupName(grpObject:any, item:any) {
  //   grpObject.data = _.first(this.selectiveGroupCollectionData.filter((s:any) => s.tabId == item.tabId)).data;
  //   return grpObject;
  // }

  getSelectiveGroupName(grpObject: any, item: any) {
    const selectiveGroup = this.selectiveGroupCollectionData.find((s: any) => s.tabId === item.tabId);
  
    if (selectiveGroup) {
      grpObject.data = selectiveGroup.data;
    } else {
      // Handle the case where selectiveGroup is undefined
      // You might want to throw an error, provide a default value, or handle it in a way that makes sense for your application.
    }  
    return grpObject;
  }
  
  setBreadcrumbs(breadCrumb:any) {

    if (localStorage.getItem('breadcrumbs') != null){
      var bc:any=localStorage.getItem('breadcrumbs')
      this.breadCrumbs = JSON.parse(bc);
    }
      

    // Replacing CreateReport with View Report after successful report creation
    if (this.breadCrumbs.length > 0) {
      if (this.breadCrumbs[this.breadCrumbs.length - 1].url == "/createreport") {
        this.breadCrumbs = this.breadCrumbs.filter((item:any) => item.url != "/viewreport");
        this.breadCrumbs = this.breadCrumbs.map(function (item:any) { return item.url == "/createreport" ? { display: 'View Report', url: '/viewreport' } : item; });
      }
    }

    let breadCrumbIndex = this.breadCrumbs.indexOf(this.breadCrumbs.filter((b:any) => b.url == breadCrumb.url)[0]);
    if (breadCrumbIndex != -1) {
      this.breadCrumbs.splice(breadCrumbIndex + 1, this.breadCrumbs.length - breadCrumbIndex);
    } else {
      this.breadCrumbs.push(breadCrumb);
    }

    let dashboardBreadcrumb = { display: 'Dashboard', url: '/dashboard' };
    if (localStorage.getItem('isadmin') == '1') {
      dashboardBreadcrumb = { display: 'Admin Dashboard', url: '/admindashboard' };
    }

    if (this.breadCrumbs[0].url != dashboardBreadcrumb.url) {
      let dshboardIndex = this.breadCrumbs.indexOf(this.breadCrumbs.filter((b:any) => b.url == dashboardBreadcrumb.url)[0]);

      if (dshboardIndex != -1)
        this.breadCrumbs.splice(dshboardIndex, 1);
      this.breadCrumbs.splice(0, 0, dashboardBreadcrumb)
    }

    localStorage.setItem('breadcrumbs', JSON.stringify(this.breadCrumbs));
  }

  validateLoggedInToken() {
    let ssoAccount = this.authService.getLoggedInAccount();
    if (ssoAccount == null || "1" == localStorage.getItem("LoggedOut")) {
      this.authService.clearStorage();
      this._router.navigate(['/login']);
    }
  }


  validateLoggedInToken1() {
    if ("1" == localStorage.getItem("LoggedOut")) {
      this.authService.clearStorage();
      this._router.navigate(['/login']);
    }
    var Tkn = sessionStorage.getItem("Token");
    if (!Tkn) {
      this._router.navigate(['/login']);
    }
  }


  getExcelMappings(excelVersion:any):Observable<any[]> {
    let _excelMappingUrl = AppSettings._excelMappingUrl + "?excelVersion=" + excelVersion;
    console.log('url:', _excelMappingUrl);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    return this._http.get<any[]>(_excelMappingUrl, {headers:options});
  }

  initExcelMappingsData(excelVersion:any) {

    if (_.isUndefined(excelVersion))
      return

    if (_.isUndefined(this.excelMappingData) || this.excelVersion != excelVersion) {
      this.getExcelMappings(excelVersion).subscribe((response:any) => {
        console.log(response)
        let data: any = response;
        this.excelVersion = excelVersion;
        this.excelMappingData = data;
      });
    }
  }

  mapExcelOutputData(isReport:any, inputData:any, outPutData:any) {
    let output: any = [];
    if (this.excelMappingData) {
      output = this.initMapExcelOutputData(isReport, inputData, outPutData);
    }
    this.storeSelectionIndex(isReport, inputData, output)
    return output;
  }

  outputDataWithSelectionIndex:any = [];
  storeSelectionIndex(isReport:any, inputData:any, output:any) {
    let objSelectionIndexOutput:any = {};
    if (isReport) {

      if (this.outputDataWithSelectionIndex.filter((o:any) => o.ReportId == inputData.reportId).length > 0)
        return;

      objSelectionIndexOutput['ReportId'] = inputData.reportId;
      objSelectionIndexOutput['ReportName'] = inputData.reportName;
    } else {

      if (this.outputDataWithSelectionIndex.filter((o:any) => o.ScenarioId == inputData.scenarioId).length > 0)
        return;

      objSelectionIndexOutput['ScenarioId'] = inputData.scenarioId;
      objSelectionIndexOutput['ScenarioName'] = inputData.scenarioName;
    }

    let selectionIndexVal = 'NM$';
    if ([0, 1, 2, 4, 5].indexOf(parseInt(inputData.InputCheckBoxId)) != -1) {
      selectionIndexVal = 'NM$';
    } else if ([3, 6].indexOf(parseInt(inputData.InputCheckBoxId)) != -1) {
      selectionIndexVal = 'DWP$';
    }

    objSelectionIndexOutput['IsReport'] = isReport;
    objSelectionIndexOutput['SelectionIndex'] = selectionIndexVal;
    objSelectionIndexOutput['Output'] = output;
    this.outputDataWithSelectionIndex.push(objSelectionIndexOutput);
  }

  initMapExcelOutputData(isReport:any, inputData:any, outPutData:any) {
    let that = this;
    let output: any = [];
    let tabIds = _.uniq(_.map(that.excelMappingData, function (x: any) { return x.Tab_ID; }));
    let tabNames = _.uniq(_.map(that.excelMappingData, function (x: any) { return x.New_Tab_Name; }));

    let tabsData:any = [];
    tabIds.forEach(function (tabId) {
      let tabData = _.filter(that.excelMappingData, function (item: any) {
        return item.Tab_ID == tabId;
      });
      tabsData.push(tabData);
    })
    tabsData.forEach(function (tabData:any) {
      let index = tabsData.indexOf(tabData);
      let tabMappedOutputData:any = [];

      tabData.forEach(function (tabRow:any) {
        let isRowRemoval = that.isRowRemovalRule(tabRow.RowRules);
        if (!isRowRemoval) {
          outPutData.forEach(function (o:any) {
            if (tabRow.Row_ID == o.ExcelRow) {
              let value :any= {};
              tabRow.CellMapping.split(",").forEach(function (cellId:any) {
                if (isReport) {
                  value["ExcelCell_" + cellId] = that.applyRowRules(o["ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData);
                  value["ExcelCell_" + cellId + "_IsMinus"] = that.checkMinusVal(that.applyRowRules(o["ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData));
                } else {
                  value["Report_ExcelCell_" + cellId] = that.applyRowRules(o["Report_ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData);
                  value["Report_ExcelCell_" + cellId + "_IsMinus"] = that.checkMinusVal(that.applyRowRules(o["Report_ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData));

                  value["Scenario_ExcelCell_" + cellId] = that.applyRowRules(o["Scenario_ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData);
                  value["Scenario_ExcelCell_" + cellId + "_IsMinus"] = that.checkMinusVal(that.applyRowRules(o["Scenario_ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData));

                  value["Scenario_ExcelCell_" + cellId + "-" + "Report_ExcelCell_" + cellId] = that.planBMinusPlanA(that.applyRowRules(o["Scenario_ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData), that.applyRowRules(o["Report_ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData));
                  value["Scenario_ExcelCell_" + cellId + "-" + "Report_ExcelCell_" + cellId + "_IsMinus"] = that.checkMinusVal(that.planBMinusPlanA(that.applyRowRules(o["Scenario_ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData), that.applyRowRules(o["Report_ExcelCell_" + cellId], tabRow.RowRules, cellId, inputData)));
                }
              });
              tabMappedOutputData.push({ primaryLabel: tabRow.PrimaryLabel, secondaryLabel: tabRow.SecondaryLabel, value: value });
            }
          })
        }
      });
      output.push({ tabId: tabIds[index], tabName: tabNames[index], tabData: tabMappedOutputData })
    })

    return output;
  }

  applyRowRules(val:any, rowRules:any, cellId:any, inputData:any) {
    let that = this;
    let returnVal = val;

    if (!_.isNull(rowRules)) {
      let ruleList = rowRules.split(',');
      _.each(ruleList, function (rule: any) {
        if (rule.indexOf('DivideBy12') != -1) {
          if (rule.split(':').indexOf(cellId) != -1) {
            returnVal = (parseInt(that.replaceSpecialChar(val)) / (30.417)).toFixed(2); // Divide By 365/12
          }
        } else if (rule.indexOf('SubstractByG28') != -1) {
          if (rule.split(':').indexOf(cellId) != -1) {
            returnVal = parseInt(that.replaceSpecialChar(val)) - parseInt(inputData['startLevelPTAindex']); // Subtract By G28 i.e. startLevelPTAindex from Global Settings
            returnVal = '$' + returnVal;
          }
        }
        else if (rule.indexOf('RemoveGeneticChangeLabel') != -1) {
          if (rule.split(':').indexOf(cellId) != -1) {
            returnVal = '';
          }
        }
        else if (rule.indexOf('SetZero') != -1) {
          if (rule.split(':').indexOf(cellId) != -1) {
            returnVal = 0;
          }
        }
      })
    }

    return returnVal;
  }

  isRowRemovalRule(rowRules:any) {
    let retVal = false;
    if (!_.isNull(rowRules) && rowRules != 'NULL') {
      let ruleList = rowRules.split(',');
      _.each(ruleList, function (rule: any) {
        if (rule.indexOf('RowRemoval') != -1) {
          retVal = true;
        }
      })
    }
    return retVal;
  }

  replaceSpecialChar(value:any) {

    if (value) {

      let val = value.toString().trim();
      val = val.replace('%', '');
      val = val == '$-' ? '0' : val;
      val = val.replace('$-', '-');
      val = val == '-' ? '0' : val;
      //val = val.replace(',', '');
      val = val.split(',').join('');
      val = val.replace('(', '');
      val = val.replace(')', '');
      val = val.replace('$', '');
      val = val.replace('N/A', '0');

      return val;
    }

    return 0;
  }

  planBMinusPlanA(valueB:any, valueA:any) {
    let valB = this.replaceSpecialChar(valueB);
    let valA = this.replaceSpecialChar(valueA);
    let diff = (parseFloat(valB) - parseFloat(valA));
    return isNaN(diff) ? "" : this.refineDiffValue(diff.toFixed(2));
  }

  refineDiffValue(val:any) {
    let pointSplits = val.split('.');
    if (pointSplits[1] == "00") {
      return pointSplits[0];
    }
    return val;
  }

  checkMinusVal(val:any) {
    let currencySymbol = this.getCurrencySymbol();
    let value = parseFloat(this.replaceSpecialChar(val));
    //let retVal = ((value < 0) || (val == '$-') || (val == '-'));
    let retVal = ((value < 0) || (val == '$-') || (val == (currencySymbol + '-')) || (val == '-'));
    return retVal;
  }

  getAnalyticsPPTChartFields() {

    let pptChartFields = [

      { tabId: 1, tabName: "Herd Statistics", graph: 'graph1', fieldName: '# Dairy bull calves BORN ALIVE', fieldDisplayName: '# Dairy bull calves born alive' },
      { tabId: 1, tabName: "Herd Statistics", graph: 'graph1', fieldName: '# Crossbred beef calves BORN ALIVE', fieldDisplayName: '# Crossbred beef calves born alive' },
      { tabId: 1, tabName: "Herd Statistics", graph: 'graph1', fieldName: '# Dairy heifer calves BORN ALIVE', fieldDisplayName: '# Dairy heifer calves born alive' },

      { tabId: 1, tabName: "Herd Statistics", graph: 'graph2', fieldName: 'Revenue of dairy bulls sold', fieldDisplayName: 'Revenue of dairy bulls sold' },
      { tabId: 1, tabName: "Herd Statistics", graph: 'graph2', fieldName: 'Revenue of beef crossbreds sold', fieldDisplayName: 'Revenue of beef crossbreds sold' },
      { tabId: 1, tabName: "Herd Statistics", graph: 'graph2', fieldName: 'Revenue of young heifer calf genetic culls sold', fieldDisplayName: 'Revenue of young heifer calf sold' },
      { tabId: 1, tabName: "Herd Statistics", graph: 'graph2', fieldName: 'Total revenues (bulls and crossbred calves at birth, and young genomic culls)', fieldDisplayName: 'Total revenues' },

      { tabId: 5, tabName: "Genetics of Next Generation (and impact of changes)", graph: 'graph1', fieldName: 'Genetic Progress', fieldDisplayName: 'Genetic Progress' },

      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph1', fieldName: 'Total Profit (excluding fixed costs) per milking cow', fieldDisplayName: 'Total Profit (excluding fixed costs) per milking cow' },

      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph2', fieldName: 'Sold dairy hfr calf value, per milking cow', fieldDisplayName: 'Sold Heifer Calf Value' },
      //Following 3 item's values to be used to make one field
      //Genetic Progress Profit = Genetic revenue change due to all genetic strategies + Value of kept hfr calves being produced in next gen of calves born - Genomic test cost, per milk cow
      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph2', fieldName: 'Genetic revenue change due to all genetic strategies (calf selection and breeding)', fieldDisplayName: 'Genetic Progress Profit' },
      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph2', fieldName: 'Value of kept hfr calves being produced in next gen of calves born (assumes no genetic benefits but includes differing herd demog and brdg)', fieldDisplayName: 'Genetic Progress Profit' },
      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph2', fieldName: 'Genomic testing cost, per milk cow', fieldDisplayName: 'Genetic Progress Profit' },
      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph2', fieldName: 'Sold other offspring (dairy bull and beef crossbred) value, per milking cow', fieldDisplayName: 'Dairy Bull Calf + Crossbred Calf Profit' },
      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph2', fieldName: 'Operational profit of cows (milk+cull sales, minus non-genetic variable costs)', fieldDisplayName: 'Operational profit of cows (milk+cull sales, minus costs)' },

      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph3', fieldName: 'Change in genetic (breeding) value of granddtrs (discounted), due to of granddams being selected (removed bottom excess)', fieldDisplayName: 'Granddaughter genetic progress due to culling strategy' },
      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph3', fieldName: 'Change in genetic (breeding) value of dtrs (discounted), due to of dams being selected (removed bottom excess)', fieldDisplayName: 'Daughter genetic progress due to culling strategy' },
      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph3', fieldName: 'Change in genetic (breeding) value of granddtrs (discounted), due to breeding strategy on granddams', fieldDisplayName: 'Granddaughter genetic progress due to breeding strategy' },
      { tabId: 7, tabName: "Profit Partitioned: per Milking Cow/yr", graph: 'graph3', fieldName: 'Change in genetic (breeding) value of dtrs (discounted), due to breeding strategy on dams', fieldDisplayName: 'Daughter genetic progress due to breeding strategy' },

      { tabId: 9, tabName: "NM$ per calf – Genetic Progress due to selection", graph: 'graph1', fieldName: 'Genetic change in $ for production traits/calf', fieldDisplayName: 'Production Traits' },
      { tabId: 9, tabName: "NM$ per calf – Genetic Progress due to selection", graph: 'graph1', fieldName: 'Genetic change in $ for longevity, SCC, repro and calving traits/calf', fieldDisplayName: 'Longevity, SCC, Reproduction Traits' },
      { tabId: 9, tabName: "NM$ per calf – Genetic Progress due to selection", graph: 'graph1', fieldName: 'Genetic change in $ for size & conformation traits/calf', fieldDisplayName: 'Functional Type Traits' },
      //{ tabId: 9, tabName: "NM$ per calf – Genetic Progress due to selection", graph: 'graph1', fieldName: 'Genetic change in $ for cow health traits/calf', fieldDisplayName: 'Cow Wellness/Health Traits' },
      //Slide 9 - If NM$ scenario is selected x441 should be plotted as the Cow Wellness/Health Traits portion
      { tabId: 9, tabName: "NM$ per calf – Genetic Progress due to selection", graph: 'graph1', fieldName: 'HTH$', fieldDisplayName: 'Cow Wellness/Health Traits' },
      { tabId: 9, tabName: "NM$ per calf – Genetic Progress due to selection", graph: 'graph1', fieldName: 'Genetic change in $ for Z calf wellness traits/calf', fieldDisplayName: 'Calf Wellness Traits' },

      //PASS Tab Id - 10 data to play role in tab 9 graph.There is no individual graph for this tab.
      { tabId: 10, tabName: "DWP$ per calf – Genetic Progress due to selection", graph: '', fieldName: 'Genetic change in $ for production traits/calf', fieldDisplayName: 'Production Traits' },
      { tabId: 10, tabName: "DWP$ per calf – Genetic Progress due to selection", graph: '', fieldName: 'Genetic change in $ for longevity, SCC, repro, calving traits/calf', fieldDisplayName: 'Longevity, SCC, Reproduction Traits' },
      { tabId: 10, tabName: "DWP$ per calf – Genetic Progress due to selection", graph: '', fieldName: 'Genetic change in $ for functional type traits/calf', fieldDisplayName: 'Functional Type Traits' },
      { tabId: 10, tabName: "DWP$ per calf – Genetic Progress due to selection", graph: '', fieldName: 'Genetic change in $ for  cow wellness traits/calf', fieldDisplayName: 'Cow Wellness/Health Traits' },
      { tabId: 10, tabName: "DWP$ per calf – Genetic Progress due to selection", graph: '', fieldName: 'Genetic change in $ for calf wellness traits/calf', fieldDisplayName: 'Calf Wellness Traits' },

      { tabId: 11, tabName: "Number of Insemination, calvings and dairy heifer calves born alive", graph: 'graph1', fieldName: 'Cost $ sexed semen', fieldDisplayName: 'Sexed semen cost' },
      { tabId: 11, tabName: "Number of Insemination, calvings and dairy heifer calves born alive", graph: 'graph1', fieldName: 'Cost $ conventional semen', fieldDisplayName: 'Conventional semen cost' },
      { tabId: 11, tabName: "Number of Insemination, calvings and dairy heifer calves born alive", graph: 'graph1', fieldName: 'Cost $ beef semen', fieldDisplayName: 'Beef semen cost' },
      { tabId: 11, tabName: "Number of Insemination, calvings and dairy heifer calves born alive", graph: 'graph1', fieldName: 'Cost $ embryo transfers', fieldDisplayName: 'Embryo transfer cost' },

      { tabId: 13, tabName: "NM$ per calf – Total Genetic Progress", graph: 'graph1', fieldName: '∆ value (protein, fat, milk)/calf', fieldDisplayName: 'Production Traits' },
      { tabId: 13, tabName: "NM$ per calf – Total Genetic Progress", graph: 'graph1', fieldName: '∆ value (longevity, SCC, repro, calving)/calf', fieldDisplayName: 'Longevity, SCC, Reproduction Traits' },
      { tabId: 13, tabName: "NM$ per calf – Total Genetic Progress", graph: 'graph1', fieldName: '∆ value (size, conformation)/calf', fieldDisplayName: 'Functional Type Traits' },
      //{ tabId: 13, tabName: "NM$ per calf – Total Genetic Progress", graph: 'graph1', fieldName: '∆ value (cow wellness)/calf', fieldDisplayName: 'Cow Wellness/Health Traits' },
      //Slide 10 - If NM$ scenario is selected x371 should be plotted as the Cow Wellness/Health Traits portion
      { tabId: 13, tabName: "NM$ per calf – Total Genetic Progress", graph: 'graph1', fieldName: 'HTH$', fieldDisplayName: 'Cow Wellness/Health Traits' },
      { tabId: 13, tabName: "NM$ per calf – Total Genetic Progress", graph: 'graph1', fieldName: 'Genetic change in $ for Z calf wellness traits/calf', fieldDisplayName: 'Calf Wellness Traits' },

      //PASS Tab Id - 14 data to play role in tab 13 graph.There is no individual graph for this tab.
      { tabId: 14, tabName: "DWP$ per calf – Total Genetic Progress", graph: '', fieldName: 'Genetic change in $ for production traits/calf', fieldDisplayName: 'Production Traits' },
      { tabId: 14, tabName: "DWP$ per calf – Total Genetic Progress", graph: '', fieldName: 'Genetic change in $ for longevity, SCC, repro, calving traits/calf', fieldDisplayName: 'Longevity, SCC, Reproduction Traits' },
      { tabId: 14, tabName: "DWP$ per calf – Total Genetic Progress", graph: '', fieldName: 'Genetic change in $ for functional type traits/calf', fieldDisplayName: 'Functional Type Traits' },
      { tabId: 14, tabName: "DWP$ per calf – Total Genetic Progress", graph: '', fieldName: 'Genetic change in $ for  cow wellness traits/calf', fieldDisplayName: 'Cow Wellness/Health Traits' },
      { tabId: 14, tabName: "DWP$ per calf – Total Genetic Progress", graph: '', fieldName: 'Genetic change in $ for calf wellness traits/calf', fieldDisplayName: 'Calf Wellness Traits' },
    ];

    return pptChartFields;
  }

  getAnalyticsPPTChartsWithDetails() {
    let currencySymbol = this.getCurrencySymbol();
    console.log('currencySymbol',currencySymbol)


    let pptChartsWithDetails = [
      {
        tabId: 11,
        graphIndex: 0,
        slideTitle: "Breeding Cost of Each Strategy",
        graphTitle: "",
        slideTitleFontSize: 40,
        chartParams: {
          barGrouping: "stacked",
          // chartTypeOptions: {
          //   stacked: true
          // },    
          showLegend: true,
          legendPos: "b",
          showValue: true,
          //valAxisTitle: "Semen Cost ($)",
          valAxisTitle: "Semen Cost (" + currencySymbol + ")",
          title: 'Total Semen Cost Per Year',
          showTitle: true,
          titleFontSize: 14,
          titleFontFace: 'Arial',
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          dataLabelColor: this.dataLabelColor,
          legendFontSize: 12,
          // dataLabelPosition: "outEnd",
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          //displayBlanksAs: "$0",
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          displayBlanksAs: currencySymbol + "0",
          legendFontFace: 'Arial',
          catAxisLabelFontFace: 'Arial',

          chartColors: ['330072', '998542', 'b05e27', '5162ad', 'e7a623'],
          showValAxisTitle: true,
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
          placeholder: 'body'
        }
      },
      {
        tabId: 1,
        graphIndex: 0,
        slideTitle: "How Many Heifers Are Needed?",
        graphTitle: "",
        slideTitleFontSize: 40,
        chartParams: {
          showLegend: true,
          legendPos: "b",
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          titleFontFace: 'Arial',
          legendFontSize: 12,
          title: 'Calves Born by Strategy',
          showTitle: true,
          showValue: true,
          dataLabelPosition: "outEnd",
          showValAxisTitle: true,
          valAxisTitle: "Number of Calves",
          placeholder: 'body',
          legendFontFace: 'Arial',
          chartColors: ['330072', '998542', 'b05e27', '5162ad', 'e7a623'],
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
      {
        tabId: 1,
        graphIndex: 1,
        slideTitle: "Which Strategy Makes the Most Calf Income?",
        graphTitle: "",
        slideTitleFontSize: 40,
        chartParams: {
          showLegend: true,
          legendPos: "b",
          showValue: true,
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          dataLabelPosition: "outEnd",
          titleFontFace: 'Arial',
          showValAxisTitle: true,
          //valAxisTitle: "Revenue ($)",
          //displayBlanksAs: "$0",
          valAxisTitle: "Revenue (" + currencySymbol + ")",
          displayBlanksAs: currencySymbol + "0",
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          legendFontSize: 12,
          title: "Revenues: Calves Sold",
          showTitle: true,
          legendFontFace: 'Arial',
          chartColors: ['330072', '998542', 'b05e27', '5162ad', 'e7a623'],
          placeholder: 'body',
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
      {
        tabId: 5,
        graphIndex: 0,
        slideTitle: "Which Strategy Makes the Most Genetic Progress?",
        graphTitle: "",
        slideTitleFontSize: 37,
        chartParams: {
          showLegend: false,
          legendPos: "t",
          showValue: true,
          valAxisTitle: "Genetic Progress ($)",
          //valAxisTitle: "Genetic Progress (" + currencySymbol + ")",
          valueBarColors: true,
          dataLabelPosition: "outEnd",
          showValAxisTitle: true,
          dataLabelFormatCode: '$#,###0',
          //dataLabelFormatCode: currencySymbol + '#,###0',
          titleFontFace: 'Arial',
          valAxisLabelFormatCode: '$#,###0',
          displayBlanksAs: "$0",
          //valAxisLabelFormatCode: currencySymbol + '#,###0',
          //displayBlanksAs: currencySymbol + "0",
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          title: "Change in GPTA Progress Per Calf",
          showTitle: true,
          chartColors: [
            "330072"
          ],
          invertedColors: [
            "330072"
          ],
          placeholder: 'body',
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
      {
        tabId: 5,
        graphIndex: 0,
        slideTitle: "Genetic Progress Per Calf",
        graphTitle: "",
        graphSidebar: {
          MainTitle: "This value is a combination of:",
          body: [
            { text: 'Using top end semen', options: { bullet: { code: '2015' } } },
            { text: 'Identifying top end animals to receive sexed semen', options: { bullet: { code: '2015' } } },
            { text: 'Identify bottom end animals to received beef semen', options: { bullet: { code: '2015' } } }
          ]

        },
        slideTitleFontSize: 40,
        chartParams: {
          showLegend: false,
          legendPos: "t",
          showValue: true,
          valAxisTitle: "Genetic Progress ($)",
          //valAxisTitle: "Genetic Progress (" + currencySymbol + ")",
          valueBarColors: true,
          showValAxisTitle: true,
          dataLabelPosition: "outEnd",
          dataLabelFormatCode: '$#,###0',
          valAxisLabelFormatCode: '$#,###0',
          displayBlanksAs: "$0",
          //dataLabelFormatCode: currencySymbol + '#,###0',
          //valAxisLabelFormatCode: currencySymbol + '#,###0',
          //displayBlanksAs: currencySymbol + "0",
          title: "Change in GPTA Progress Per Calf",
          showTitle: true,
          titleFontFace: 'Arial',
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          chartColors: [
            "330072"
          ],
          invertedColors: [
            "330072"
          ],
          placeholder: 'body',
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
      {
        tabId: 13,
        graphIndex: 0,
        slideTitle: "Total Genetic Program Genetic Progress",
        graphTitle: "",
        slideTitleFontSize: 40,
        graphSidebar: {
          MainTitle: "This includes:",
          body: [
            { text: 'Sire quality', options: { bullet: { code: '2015' } } },
            { text: 'Heifer culling', options: { bullet: { code: '2015' } } },
            { text: 'Semen type', options: { bullet: { code: '2015' } } },
            { text: 'Impact of genomic technology', options: { bullet: { code: '2015' } } },
            { text: 'Impact of selection index used', options: { bullet: { code: '2015' } } }
          ]
        },
        chartParams: {
          barDir: "bar",
          barGrouping: "stacked",
          showLegend: true,
          legendPos: "b",
          showValue: true,
          //valAxisTitle: "Genetic Progress ($)",
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          //displayBlanksAs: "$0",
          valAxisTitle: "Genetic Progress (" + currencySymbol + ")",
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          displayBlanksAs: currencySymbol + "0",
          title: "Genetic Progress: How much does each trait contribute?",
          showTitle: true,
          showValAxisTitle: true,
          titleFontSize: 14,
          dataLabelFontSize: 12,
          titleFontFace: 'Arial',
          dataLabelFontBold: true,
          dataLabelColor: this.dataLabelColor,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          chartColors: ['330072', '998542', 'b05e27', '5162ad', 'e7a623'],
          placeholder: 'body',
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
          dataLabelPosition: "l",

        }
      },
      {
        tabId: 9,
        graphIndex: 0,
        slideTitle: "Impact of Selection Decisions on Genetic Progress",
        graphTitle: "",
        slideTitleFontSize: 31,
        graphSidebar: {
          MainTitle: "This includes:",
          body: [
            { text: 'Heifer culling', options: { bullet: { code: '2015' } } },/////
            { text: 'Semen type', options: { bullet: { code: '2015' } } },
            { text: 'If animals in the same age group are bred differently', options: { bullet: true, indentLevel: 1 } },

            { text: 'Impact of genomic technology', options: { bullet: { code: '2015' } } },
            { text: 'Impact of selection index used', options: { bullet: { code: '2015' } } }
          ]

        },
        chartParams: {
          barDir: "bar",
          barGrouping: "stacked",
          showLegend: true,
          legendPos: "b",
          showValue: true,
          //valAxisTitle: "Genetics Progress Profit($)",
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          //displayBlanksAs: "$0",
          valAxisTitle: "Genetics Progress Profit(" + currencySymbol + ")",
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          displayBlanksAs: currencySymbol + "0",
          chartColors: ['330072', '998542', 'b05e27', '5162ad', 'e7a623'],
          showValAxisTitle: true,
          titleFontFace: 'Arial',
          title: "Genetic Progress: How much does each trait contribute?",
          showTitle: true,
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          dataLabelColor: this.dataLabelColor,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          placeholder: 'body',
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
      {
        tabId: 7,
        graphIndex: 0,
        slideTitle: "What is the Best Genetic Strategy to Maximize Profitability?",
        graphTitle: "",
        slideTitleFontSize: 32,
        graphSidebar: {
          MainTitle: "Using CLARIFIDE Plus Beef/Sexed strategy will result in $28 more total profit per milk cow per year",
          body: [
            { text: 'Genomic test cost already accounted for in Total Profit', options: { bullet: { code: '2015' } } },
          ]

        },
        chartParams: {
          showValue: true,
          //valAxisTitle: "Profit ($)",
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          //displayBlanksAs: "$0",
          valAxisTitle: "Profit (" + currencySymbol + ")",
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          displayBlanksAs: currencySymbol + "0",
          dataLabelPosition: "outEnd",
          chartColors: ['330072'],
          showValAxisTitle: true,
          titleFontSize: 14,
          dataLabelFontSize: 12,
          titleFontFace: 'Arial',
          dataLabelFontBold: true,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          placeholder: 'body',
          title: "Total Profit Per Milking Cow/Year",
          showTitle: true,
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
      {
        tabId: 7,
        graphIndex: 0,
        slideTitle: "What is Included in Total Profit Calculation?",
        graphTitle: "",
        slideTitleFontSize: 40,
        graphSidebar: [{
          MainTitle: "Expenses:",
          body: [
            { text: 'Genomic testing', options: { bullet: { code: '2015' } } },
            { text: 'Breeding', options: { bullet: { code: '2015' } } },
            { text: 'Heifer raising', options: { bullet: { code: '2015' } } },
            { text: 'Feed', options: { bullet: { code: '2015' } } },
            { text: 'Other variable costs', options: { bullet: { code: '2015' } } },
            { text: 'Fixed costs', options: { bullet: { code: '2015' } } }
          ]
        }, {
          MainTitle: "Incomes:",
          body: [
            { text: 'Milk sales', options: { bullet: { code: '2015' } } },
            { text: 'Cull cow sales', options: { bullet: { code: '2015' } } },
            { text: 'Calf sales', options: { bullet: { code: '2015' } } },
            { text: 'Impact of genetic progress on future calves', options: { bullet: { code: '2015' } } }
          ]
        }],
        chartParams: {

          showValue: true,
          //valAxisTitle: "Profit ($)",
          valAxisTitle: "Profit (" + currencySymbol + ")",
          dataLabelPosition: "outEnd",
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          //displayBlanksAs: "$0",
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          displayBlanksAs: currencySymbol + "0",
          chartColors: ['330072'],
          showValAxisTitle: true,
          titleFontFace: 'Arial',
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          placeholder: 'body',
          title: "Total Profit Per Milking Cow/Year",
          showTitle: true,
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
      {
        tabId: 7,
        graphIndex: 0,
        slideTitle: "What is Included in Total Profit Calculation? ",
        graphTitle: "",
        slideTitleFontSize: 40,
        graphSidebar: {
          MainTitle: "This value is a combination of:",
          body: [
            { text: 'Value of actual herd performance', options: { bullet: { code: '2015' } } },
            { text: 'Indirect impact of genetic strategy (selling calves, mature milk)', options: { bullet: { code: '2015' } } },
            { text: 'Direct impact of genetic strategy ($ value of genetic progress) ', options: { bullet: { code: '2015' } } },

          ]

        },
        chartParams: {
          showValue: true,
          //valAxisTitle: "Profit ($)",
          valAxisTitle: "Profit (" + currencySymbol + ")",
          dataLabelPosition: "outEnd",
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          //displayBlanksAs: "$0",
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          displayBlanksAs: currencySymbol + "0",
          chartColors: ['330072'],
          showValAxisTitle: true,
          titleFontFace: 'Arial',
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          placeholder: 'body',
          title: "Total Profit Per Milking Cow/Year",
          showTitle: true,
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },

      {
        tabId: 7,
        graphIndex: 1,
        slideTitle: "What Goes into Total Profit Per Cow?",
        graphTitle: "",
        slideTitleFontSize: 40,
        graphSidebar: {
          MainTitle: 'Operational profit = (milk income + cull income)-(variable + fixed expenses)',
          body: [

            { text: 'Calf expense and sale income', options: { bullet: true } },
            { text: 'Dollar value of genetic progress', options: { bullet: true } },
            { text: 'Genomic testing expenses', options: { bullet: true } }
          ]

        },
        chartParams: {
          barGrouping: "stacked",
          // chartTypeOptions: {
          //   stacked: true
          // },
          showLegend: true,
          legendPos: "b",
          showValue: true,
          //valAxisTitle: "Profit ($)",
          valAxisTitle: "Profit (" + currencySymbol + ")",
          // dataLabelPosition: "outEnd",
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          //displayBlanksAs: "$0",
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          displayBlanksAs: currencySymbol + "0",
          titleFontFace: 'Arial',
          chartColors: ['330072', '998542', 'b05e27', '5162ad', 'e7a623'],
          showValAxisTitle: true,
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          dataLabelColor: this.dataLabelColor,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          placeholder: 'body',
          title: "Total Profit Per Milking Cow/Year",
          showTitle: true,
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
      {
        tabId: 7,
        graphIndex: 2,
        slideTitle: "Will Today’s Breeding Decisions Impact Future Generations?",
        graphTitle: "",
        slideTitleFontSize: 31,
        graphSidebar: {
          fullview: true,
          MainTitle: 'Operational profit = (milk income + cull income)-(variable + fixed expenses)',
        },
        chartParams: {
          x: 0.6, y: 2.2, w: 12, h: 4.50,
          barGrouping: "stacked",
          // chartTypeOptions: {
          //   stacked: true
          // },
          showLegend: true,
          legendPos: "b",
          showValue: true,
          //valAxisTitle: "Genetic Progress Profit ($)",
          valAxisTitle: "Genetic Progress Profit (" + currencySymbol + ")",
          // dataLabelPosition: "outEnd",
          //dataLabelFormatCode: '$#,###0',
          //valAxisLabelFormatCode: '$#,###0',
          //displayBlanksAs: "$0",
          dataLabelFormatCode: currencySymbol + '#,###0',
          valAxisLabelFormatCode: currencySymbol + '#,###0',
          displayBlanksAs: currencySymbol + "0",
          chartColors: ['330072', '998542', 'b05e27', '5162ad', 'e7a623'],
          showValAxisTitle: true,
          titleFontFace: 'Arial',
          titleFontSize: 14,
          dataLabelFontSize: 12,
          dataLabelFontBold: true,
          dataLabelColor: this.dataLabelColor,
          legendFontSize: 12,
          legendFontFace: 'Arial',
          placeholder: 'body',
          title: "Impact of Genetic Progress on Multiple Generations",
          showTitle: true,
          catAxisLabelFontFace: 'Arial',
          valAxisTitleFontFace: 'Arial',
          valAxisTitleFontSize: 12,
        }
      },
    ];

    return pptChartsWithDetails;
  }

  selectionBasedIndexOutputChange(output:any, isReport:any, inputCheckBoxId:any) {
    let tabIdsDataNotToPopulate:any = [];
    if ([0, 1, 2, 4, 5].indexOf(parseInt(inputCheckBoxId)) != -1) {
      tabIdsDataNotToPopulate = [10, 14]; // Replace with N/A
    } else if ([3, 6].indexOf(parseInt(inputCheckBoxId)) != -1) {
      tabIdsDataNotToPopulate = [9, 13]; // Replace with N/A
    }

    tabIdsDataNotToPopulate.forEach(function (tabId:any) {

      if (isReport) {
        output.filter((o:any) => o.tabId == tabId)[0].tabData = output.filter((o:any) => o.tabId == tabId)[0].tabData
          .map(function (k:any) {

            k.value['ExcelCell_W'] = 'NA';
            k.value['ExcelCell_X'] = 'NA';

            k.value['ExcelCell_W_IsMinus'] = false;
            k.value['ExcelCell_X_IsMinus'] = false;

            return k;
          })
      } else {
        output.filter((o:any) => o.tabId == tabId)[0].tabData = output.filter((o:any) => o.tabId == tabId)[0].tabData
          .map(function (k:any) {
            k.value['Report_ExcelCell_W'] = 'NA';
            k.value['Scenario_ExcelCell_W'] = 'NA';
            k.value['Scenario_ExcelCell_W-Report_ExcelCell_W'] = 'NA';

            k.value['Report_ExcelCell_W_IsMinus'] = false;
            k.value['Scenario_ExcelCell_W_IsMinus'] = false;
            k.value['Scenario_ExcelCell_W-Report_ExcelCell_W_IsMinus'] = false;

            k.value['Report_ExcelCell_X'] = 'NA';
            k.value['Scenario_ExcelCell_X'] = 'NA';
            k.value['Scenario_ExcelCell_X-Report_ExcelCell_X'] = 'NA';

            k.value['Report_ExcelCell_X_IsMinus'] = false;
            k.value['Scenario_ExcelCell_X_IsMinus'] = false;
            k.value['Scenario_ExcelCell_X-Report_ExcelCell_X_IsMinus'] = false;

            return k;
          })
      }
    });

    return output;

  }

  getOutputTabs(output: any) {
    let outputTabs = output.map(function (k:any) { return { tabId: k.tabId, tabName: k.tabName }; });
    return outputTabs;
  }

  getOutputTabData(output:any, tabId:any) {
    let tabData = output.filter((k:any) => k.tabId == tabId)[0];
    return tabData;
  }

  getOutFieldsForCurrencyConversion() {
    let outputFields =
      [
        {
          tabId: 1,
          tabName: "Herd Statistics",
          labels: [
            "Value of  other calves (bulls and crossbreds) sold/head",
            "Value of dairy heifer calf sold / head",
            "Cost to raise a heifer (no calf value, next gen value or breeding costs)",
            "Revenue of dairy bulls sold",
            "Revenue of beef crossbreds sold",
            "Revenue of young heifer calf genetic culls sold",
            "Total revenues (bulls and crossbred calves at birth, and young genomic culls)"
          ]
        },
        {
          tabId: 6,
          tabName: "Profit Partitioned: per Dairy Female Calf born alive/yr",
          labels: [
            "Profit ($) per dairy female born alive (assumes all females tested; doesn`t include fixed costs)",
            "Change in genetic revenues (due to calf and breeding selection), per hfr born",
            "Net profit of genomic testing (change in genetic revenue minus test cost), per hfr born"
          ]
        },
        {
          tabId: 7,
          tabName: "Profit Partitioned: per Milking Cow/yr",
          labels: [
            "Total Profit (excluding fixed costs) per milking cow",
            "Milk revenue per milking cow (excluding genetic improvement)",
            "Sales revenue from culling cows and older heifers",
            "Change in genetic breeding value, due to calf selection (removing bottom excess)",
            "Value of kept hfr calves being produced in next gen of calves born (assumes no genetic benefits but includes differing herd demog and brdg)",
            "Change in genetic (breeding) value of dtrs (discounted), due to of dams being selected (removed bottom excess)",
            "Change in genetic (breeding) value of granddtrs (discounted), due to of granddams being selected (removed bottom excess)",
            "Change in genetic (breeding) value of dtrs (discounted), due to breeding strategy on dams",
            "Change in genetic (breeding) value of granddtrs (discounted), due to breeding strategy on granddams",
            "Sold dairy hfr calf value, per milking cow",
            "Sold other offspring (dairy bull and beef crossbred) value, per milking cow",
            "Cost of dystocia from bulls and crossbreds impacting dam milk and health",
            "Insemination and embryo costs",
            "Cost to raise heifers to calving (does not include brdg costs, cull sales or calf value), per milking cow",
            "Genomic testing cost, per milk cow",
            "Feed Cost for cows",
            "Other variable operational costs",
            "Total revenues (operational and genetic gains)",
            "Total variable costs (operational and genetic testing)",
            "Operational profit of cows (milk+cull sales, minus non-genetic variable costs)",
            "Profit from next generation value of hfrs (& genetic gain), bulls & crossbreds",
            "Realized value of future dairy hfr calves (base value + genetic imrpovement next gen)",
            "Genetic revenue change due to all genetic strategies (calf selection and breeding)",
            "Net value genomic testing (genetic revenue change minus testing cost), per milking cow"
          ]
        },
        {
          tabId: 8,
          tabName: "Partitions:  Dairy calf heifer value and Heifer raising cost",
          labels: [
            "Change due to parents’ avg genetic value ~3 yrs later (discounted and accounts for survival) - no genetic selection included",
            "Genetic gain due to calf selection of dam impacting dtrs",
            "Genetic gain due to calf selection of dam impacting grand-dtrs",
            "Genetic gain due to selective breeding, impacting dtrs",
            "Genetic gain due to selective breeding, impacting grand-dtrs",
            "Cost of obtaining dairy  heifer calf (considers losses of calves until calving)",
            "Dairy heifer breeding costs",
            "Dairy heifer other variable costs (feed, labor) and dystocia cost)",
            "Dairy heifer cull sales (inventory culling, not pregnant)"
          ]
        },
        {
          tabId: 9,
          tabName: "NM$ per calf – Genetic Progress due to selection",
          labels: [
            "Milk (lbs)",
            "Fat (lbs)",
            "Protein (lbs)",
            "SCS (log)",
            "PL (mos.)",
            "LIV (%)",
            "Body size (composite)",
            "Udder (composite)",
            "Feet/legs (composite)",
            "DPR (%)",
            "HCR (%)",
            "CCR (%)",
            "CA$",
            "HTH$",
            "NM$",
            "Genetic change in $ for production traits/calf",
            "Genetic change in $ for longevity, SCC, repro and calving traits/calf",
            "Genetic change in $ for size & conformation traits/calf",
            "Genetic change in $ for cow health traits/calf",
            "Genetic change in $ for Z calf wellness traits/calf"

          ]
        },
        {
          tabId: 10,
          tabName: "DWP$ per calf – Genetic Progress due to selection",
          labels: [
            "Milk",
            "Fat",
            "Protein",
            "SCS",
            "PL",
            "LIV",
            "Body size",
            "Udder",
            "Feet/legs",
            "DPR",
            "HCR",
            "CCR",
            "CA$",
            "Z_Mastitis",
            "Z_Lameness",
            "Z_Metritis",
            "Z_RP",
            "Z_DA",
            "Z_Ketosis",
            "Z_RESP",
            "Z_CYST",
            "Z_ABRT",
            "Z_TWIN",
            "Z_Calf_LIV",
            "Z_Calf_Resp",
            "Z_Calf_Scours",
            "DWP$",
            "Genetic change in $ for production traits/calf",
            "Genetic change in $ for longevity, SCC, repro, calving traits/calf",
            "Genetic change in $ for functional type traits/calf",
            "Genetic change in $ for  cow wellness traits/calf",
            "Genetic change in $ for calf wellness traits/calf"

          ]
        },
        {
          tabId: 13,
          tabName: "NM$ per calf – Total Genetic Progress",
          labels: [
            "Milk",
            "Fat",
            "Protein",
            "PL",
            "SCS",
            "BWC",
            "Udder composite",
            "Feet/leg composite",
            "DPR",
            "CA$",
            "HCR",
            "CCR",
            "LIV",
            "HTH$",
            "NM$",
            "∆ value (protein, fat, milk)/calf",
            "∆ value (longevity, SCC, repro, calving)/calf",
            "∆ value (size, conformation)/calf",
            "Genetic change in $ for Z calf wellness traits/calf"
          ]
        },
        {
          tabId: 14,
          tabName: "DWP$ per calf – Total Genetic Progress",
          labels: [
            "Milk",
            "Fat",
            "Protein",
            "SCS",
            "PL",
            "LIV",
            "Body size",
            "Udder",
            "Feet/legs",
            "DPR",
            "HCR",
            "CCR",
            "CA$",
            "Z_Mastitis",
            "Z_Lameness",
            "Z_Metritis",
            "Z_RP",
            "Z_DA",
            "Z_Ketosis",
            "Z_RESP",
            "Z_CYST",
            "Z_ABRT",
            "Z_TWIN",
            "Z_Calf_LIV",
            "Z_Calf_Resp",
            "Z_Calf_Scours",
            "DWP$",
            "Genetic change in $ for production traits/calf",
            "Genetic change in $ for longevity, SCC, repro, calving traits/calf",
            "Genetic change in $ for functional type traits/calf",
            "Genetic change in $ for  cow wellness traits/calf",
            "Genetic change in $ for calf wellness traits/calf"
          ]
        },
        {
          tabId: 11,
          tabName: "Number of Insemination, calvings and dairy heifer calves born alive",
          labels: [
            "Cost $ sexed semen",
            "Cost $ conventional semen",
            "Cost $ beef semen",
            "Cost $ embryo transfers",
            "Total semen/embryo costs $  per year"
          ]
        }
      ];

    return outputFields;
  }

  convertReportOutputByCurrency(output:any, currencySymbol:any, currencyValue:any) {
    let that = this;
    let outCurrConversionFields = this.getOutFieldsForCurrencyConversion();
    outCurrConversionFields.forEach(function (o) {
      o.labels.forEach(function (lbl) {
        let lblText = '';
        let xlblValue: any = '';
        let vlblValue: any = '';
        let wlblValue: any = '';

        let objField = output.filter((ot:any) => ot.tabId == o.tabId)[0].tabData.find((t:any) => t.primaryLabel == lbl);

        if (objField) {

          lblText = objField.primaryLabel;
          xlblValue = objField.value['ExcelCell_X'];
          vlblValue = objField.value['ExcelCell_V'];
          wlblValue = objField.value['ExcelCell_W'];

          if (['CA$', 'HTH$', 'NM$', 'DWP$'].indexOf(lblText) == -1) {
            lblText = lblText.toString().replace('$', currencySymbol).trim();
          }

          that.changeReportCurrencyValue(output, o.tabId, lbl, xlblValue, 'X', currencySymbol, currencyValue);

          if ([9, 10, 13, 14].indexOf(o.tabId) != -1) {
            //that.changeReportCurrencyValue(output, o.tabId, lbl, wlblValue, 'W', currencySymbol, currencyValue);
          }

          if ([11].indexOf(o.tabId) != -1) {
            that.changeReportCurrencyValue(output, o.tabId, lbl, vlblValue, 'V', currencySymbol, currencyValue);
            that.changeReportCurrencyValue(output, o.tabId, lbl, wlblValue, 'W', currencySymbol, currencyValue);
          }

          output.filter((ot:any) => ot.tabId == o.tabId)[0].tabData.find((t:any) => t.primaryLabel == lbl).primaryLabel = lblText;
        }
      })
    });

    return output;
  }

  convertScenarioOutputByCurrency(scenarios:any, currencySymbol:any, currencyValue:any) {

    let that = this;

    let outCurrConversionFields = this.getOutFieldsForCurrencyConversion();

    scenarios.forEach((scenario:any) => {

      if (scenario['IsConversion'] != true) {

        let scenarioOutput = scenario.ScenarioOutput || scenario.scenarioData;

        outCurrConversionFields.forEach(function (o) {
          o.labels.forEach(function (lbl) {
            let lblText = '';

            let xReportLblValue: any = '';
            let xScenarioLblValue: any = '';
            let xDiffLblValue: any = '';

            let vReportLblValue: any = '';
            let vScenarioLblValue: any = '';
            let vDiffLblValue: any = '';

            let wReportLblValue: any = '';
            let wScenarioLblValue: any = '';
            let wDiffLblValue: any = '';

            let filteredSscenarioOutput = scenarioOutput.filter((ot:any) => ot.tabId == o.tabId);
            let objField = filteredSscenarioOutput.length > 0 ? scenarioOutput.filter((ot:any) => ot.tabId == o.tabId)[0].tabData.find((t:any) => t.primaryLabel == lbl) : null;

            if (objField) {
              lblText = objField.primaryLabel;

              xReportLblValue = objField.value['Report_ExcelCell_X'];
              xScenarioLblValue = objField.value['Scenario_ExcelCell_X'];
              xDiffLblValue = objField.value['Scenario_ExcelCell_X-Report_ExcelCell_X'];

              vReportLblValue = objField.value['Report_ExcelCell_V'];
              vScenarioLblValue = objField.value['Scenario_ExcelCell_V'];
              vDiffLblValue = objField.value['Scenario_ExcelCell_V-Report_ExcelCell_V'];

              wReportLblValue = objField.value['Report_ExcelCell_W'];
              wScenarioLblValue = objField.value['Scenario_ExcelCell_W'];
              wDiffLblValue = objField.value['Scenario_ExcelCell_W-Report_ExcelCell_W'];

              if (['CA$', 'HTH$', 'NM$', 'DWP$'].indexOf(lblText) == -1) {
                lblText = lblText.replace('$', currencySymbol).trim();
              }

              that.changeScenarioCurrencyValue(scenarioOutput, o.tabId, lbl, xReportLblValue, xScenarioLblValue, xDiffLblValue, 'X', currencySymbol, currencyValue);

              if ([9, 10, 13, 14].indexOf(o.tabId) != -1) {
                //that.changeScenarioCurrencyValue(scenarioOutput, o.tabId, lbl, wReportLblValue, wScenarioLblValue, wDiffLblValue, 'W', currencySymbol, currencyValue);
              }

              if ([11].indexOf(o.tabId) != -1) {
                that.changeScenarioCurrencyValue(scenarioOutput, o.tabId, lbl, vReportLblValue, vScenarioLblValue, vDiffLblValue, 'V', currencySymbol, currencyValue);
                that.changeScenarioCurrencyValue(scenarioOutput, o.tabId, lbl, wReportLblValue, wScenarioLblValue, wDiffLblValue, 'W', currencySymbol, currencyValue);
              }

              scenarioOutput.filter((ot:any) => ot.tabId == o.tabId)[0].tabData.find((t:any) => t.primaryLabel == lbl).primaryLabel = lblText;

            }

          })
        });

        if (scenario.ScenarioOutput) {
          scenario.ScenarioOutput = scenarioOutput;
        } else if (scenario.scenarioData) {
          scenario.scenarioData = scenarioOutput;
        }

        scenario['IsConversion'] = true;

      }

    });

    return scenarios;
  }

  changeOutputCurrencyValue(output:any, tabId:any, lbl:any, lblValue:any, lblColumnName:any, currencySymbol:any, currencyValue:any) {

    if (lblValue) {

      if (['NA', '$-', '-'].indexOf(lblValue) == -1) {
        lblValue = lblValue.toString().replace('$', '').trim();
        //lblValue = lblValue.toString().replace(',', '').trim();
        lblValue = lblValue.toString().split(',').join('');
        lblValue = (parseFloat(lblValue) / currencyValue).toFixed(2);
        lblValue = currencySymbol + lblValue;
        output.filter((ot:any) => ot.tabId == tabId)[0].tabData.find((t:any) => t.primaryLabel == lbl).value[lblColumnName] = lblValue;
      } else if (['$-'].indexOf(lblValue) != -1) {
        lblValue = lblValue.toString().replace('$', currencySymbol).trim();
        output.filter((ot:any) => ot.tabId == tabId)[0].tabData.find((t:any) => t.primaryLabel == lbl).value[lblColumnName] = lblValue;
      }
    }
  }

  changeReportCurrencyValue(reportOutput:any, tabId:any, lbl:any, reportLblValue:any, cellId:any, currencySymbol:any, currencyValue:any) {

    this.changeOutputCurrencyValue(reportOutput, tabId, lbl, reportLblValue, 'ExcelCell_' + cellId, currencySymbol, currencyValue);

  }

  changeScenarioCurrencyValue(scenarioOutput:any, tabId:any, lbl:any, reportLblValue:any, scenarioLblValue:any, diffLblValue:any, cellId:any, currencySymbol:any, currencyValue:any) {

    this.changeOutputCurrencyValue(scenarioOutput, tabId, lbl, reportLblValue, 'Report_ExcelCell_' + cellId, currencySymbol, currencyValue);
    this.changeOutputCurrencyValue(scenarioOutput, tabId, lbl, scenarioLblValue, 'Scenario_ExcelCell_' + cellId, currencySymbol, currencyValue);
    this.changeOutputCurrencyValue(scenarioOutput, tabId, lbl, diffLblValue, 'Scenario_ExcelCell_' + cellId + '-Report_ExcelCell_' + cellId, currencySymbol, currencyValue);

  }

  getCurrencySymbol() {

    let currencySymbol = '$';
    let selectedCurrencyMarket = localStorage.getItem('selectedCurrencyMarket');
    if (selectedCurrencyMarket && selectedCurrencyMarket != 'undefined') {
      let symbol = JSON.parse(selectedCurrencyMarket).Symbol;
      //currencySymbol = symbol.length > 1 ? symbol[1] : symbol;
      if (symbol.indexOf(currencySymbol) == -1) {
        currencySymbol = symbol;
      }
    }

    return currencySymbol;
  }

  inputConversionUSD(inputData:any) {
    let rmid:any=localStorage.getItem('reportMarketId')
    let marketId = parseFloat(rmid);
    let cv:any=localStorage.getItem('reportCurrencyValue')
    let currencyValue = parseFloat(cv);

    if (marketId.toString() != "2") {

      //Additional Input 1
      inputData['geneticTestingCostCalf'] = parseFloat(inputData['geneticTestingCostCalf']) * currencyValue;

      //Additional Input 2
      inputData['baseValueKeptDairyCalf'] = parseFloat(inputData['baseValueKeptDairyCalf']) * currencyValue;
      inputData['baseValueKeptDairyCalf'] = parseFloat(inputData['baseValueKeptDairyCalf']) * currencyValue;
      inputData['valueDairyBullCalf'] = parseFloat(inputData['valueDairyBullCalf']) * currencyValue;
      inputData['valueCrossbredCalf'] = parseFloat(inputData['valueCrossbredCalf']) * currencyValue;
      inputData['costSurplusDairyHeiferCalf'] = parseFloat(inputData['costSurplusDairyHeiferCalf']) * currencyValue;
      inputData['costPerBreedingWithConventionalSemen'] = parseFloat(inputData['costPerBreedingWithConventionalSemen']) * currencyValue;
      inputData['costPerBreedingWithSexedSemen'] = parseFloat(inputData['costPerBreedingWithSexedSemen']) * currencyValue;
      inputData['costPerBreedingWithBeefSemen'] = parseFloat(inputData['costPerBreedingWithBeefSemen']) * currencyValue;
      inputData['costPerEmbryoTransfer'] = parseFloat(inputData['costPerEmbryoTransfer']) * currencyValue;

      //Global Settings
      inputData['milkPrice'] = parseFloat(inputData['milkPrice']) * currencyValue;
      inputData['milkFeedPrice'] = parseFloat(inputData['milkFeedPrice']) * currencyValue;
      inputData['cowCullPrice'] = parseFloat(inputData['cowCullPrice']) * currencyValue;
      inputData['costToRaiseHeifer'] = parseFloat(inputData['costToRaiseHeifer']) * currencyValue;
      inputData['rollingHerdAverageMilk'] = parseFloat(inputData['rollingHerdAverageMilk']) * currencyValue;

    }

    return inputData
  }


}
