import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lab-tech-report',
  templateUrl: './lab-tech-report.component.html',
  styleUrls: ['./lab-tech-report.component.css']
})
export class LabTechReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
