import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/map'
import { HeaderService } from './header.service';
import { Router } from '@angular/router';
// import { MsalService } from '@azure/msal-angular';
import toHttpParams from '../_helpers/toHttpParams';
import { AppSettings } from '../appSettings';
declare var $: any;
import { map } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';


@Injectable()
export class AuthenticationService {
    keys =
        [
            "report",
            "pagecomingfrom",
            "lastReportID",
            "issavedraft",
            "isadmin",
            "fname",
            "email",
            "customer",
            "businessNameTitle",
            "breadcrumbs",
            "adminFname",
            "UmmUserId",
            "SelectedUserName",
            "SelectedUserEmail",
            "SelectedUmmUserId",
            "businessName",
            "ssoName",
            "ssoUserName",
            "UserId",
            "MarketId",
            "fname",
            "lname"
        ]
    sessionIntervalId: any
    constructor(private http: HttpClient,
        private router: Router,
        private msalService: MsalService
    ) { }

    // login(url: string): any {
    //     return this.http.get(url)
    //         .map((response: Response) => {
    //             // login successful if there's a jwt token in the response
    //             let user = response.json();
    //             return user;
    //         }).toPromise();
    // }

    login(url: string): Promise<any> {
        return this.http.get(url)
            .toPromise()
            .then((response: any) => response)
            .catch(this.handleError); // Add error handling if necessary
    }

    private handleError(error: any): Promise<any> {
        // Add your error handling logic here if needed
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    getSSOHeader(token: string) {
        const headers = new HttpHeaders()
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        headers.set('Authorization', 'bearer ' + token);
        return headers;
    }

    getSSORequestOptions(token: string) {
        return { headers: this.getSSOHeader(token) };
    }

    authorizeSSOLogin(email: string, uid: any, token: string): any {
        let authorizationUrl = AppSettings._authorizationApi + "?email=" + email + "&uid=" + uid;
        let postModel = {
            email: email,
            uid: uid
        };
        let options = this.getSSORequestOptions(token);
        return this.http.get(authorizationUrl, options);
    }

    logoutSSO() {
        this.clearStorage();
        localStorage.setItem("LoggedOut", "1");
        this.msalService.logout();
    }

    getLoggedInAccount() {
        let ssoAccount = this.msalService.instance.getActiveAccount();
        // console.log('ssoAccount', ssoAccount)
        // let ssoAccount = this.msalService.getAccount();
        return ssoAccount;
    }

    clearStorage() {
        sessionStorage.clear();
        sessionStorage.removeItem("Token");
        localStorage.clear()
        this.keys.forEach(function (key) {
            localStorage.removeItem(key);
        });
    }

    public isLoggedIn(): boolean {
        let ssoAccount = this.getLoggedInAccount();
        if (ssoAccount == null || "1" == localStorage.getItem("LoggedOut")) {
            return false;
        } else {
            return true;
        }
    }
}