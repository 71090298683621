import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/map';

import { HeaderService } from '../_services/header.service';
import { Observable } from 'rxjs/internal/Observable';
declare var $: any;

@Injectable()
export class AdminSearchService {

  public ClToken = sessionStorage.getItem("Token");
  constructor(private _http: HttpClient,
    public headerService: HeaderService) { }

  searchAdmins(sCustomerId:any): Observable<any[]> {
    let _url = AppSettings._adminSearchUrl + '?userId=' + sCustomerId;

    console.log('url:', _url);
    console.log('data passed with the url:', '');
    let options:any = this.headerService.getHeader();
    return this._http.get<any[]>(_url, {headers:options});
  }
}
