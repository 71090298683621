import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SharedService } from "../_services/shared-service.service";
import * as _ from "lodash";
declare var require: any;
// declare const Buffer;


import PptxGenJS from 'pptxgenjs';
import { AppConstants } from "../appConstants";

@Component({
  selector: "app-analysis-report-ppt",
  templateUrl: "./analysis-report-ppt.component.html",
  styleUrls: ["./analysis-report-ppt.component.css"]
})
export class AnalysisReportPptComponent implements OnInit {
  @Input() reportId: any;
  @Output() setLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
  public selectedData: any[] = [];
  public filterData: any[] = [];
  public pptChartsWithDetails: any = [];
  public profitPartPerCowFooterDescList: any = [];
  currencySymbol = '';
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    let that = this;
    this.currencySymbol = this.sharedService.getCurrencySymbol();
    this.selectedData = this.sharedService.analysisRows.map((x: any) => x.fieldName.trim());
    this.filterData = this.sharedService.pptChartData.graphsData.filter((data: any) => {
      return this.selectedData.indexOf(data.fieldName.trim()) >= 0;
    });
    this.pptChartsWithDetails = this.sharedService.getAnalyticsPPTChartsWithDetails();
    /** Get highest value */
    this.profitPartPerCowFooterDescList = this.filterData.filter(k => k.tabId == 7 && k.graph == 'graph1' && k.isDiffVal == true).reduce((prev, cur) => (parseInt(prev.value) > parseInt(cur.value)) ? [prev] : [cur], []).
      map(function (m: any) {
        let value = m.value;
        value = value.replace(that.currencySymbol, '');
        value = that.currencySymbol + value;
        return "Using " + m.scenario + " will result in  " + value + " more total profit per milk cow per year"
      });
  }

  generatePpt() {
    let that = this;
    let reportName = this.sharedService.analysisOutputData.ReportName;
    let fileName = "analyticsReport" + "_" + this.reportId;
    let pptx: any = new PptxGenJS();
    pptx.revision = '15';

    // STEP 3: Set layout
    pptx.layout = 'LAYOUT_WIDE';
    var logoSml = AppConstants._logoSml;

    pptx.defineSlideMaster({
      title: 'MASTER_SLIDE',
      bkgd: 'FFFFFF',
      margin: [0.5, 0.25, 1.0, 0.25],
      slideNumber: { x: 0.6, y: 6.90, color: 'ff6400', fontFace: 'Arial', fontSize: 20 },
      objects: [
        { 'image': { x: 11.45, y: 6.90, w: 1.3, h: 0.5, data: logoSml } },
        {
          'placeholder':
          {
            options: { name: 'title', type: 'title' },
            data: ''
          }
        },
        {
          'placeholder':
          {
            options: { name: 'body', type: 'body', x: 0.6, y: 1, w: 12, h: 5.61, fill: 'F1F1F1', color: 'F1F1F1' },
            text: '(supports custom placeholder text!)'
          }
        }
      ]
    });

    pptx.defineSlideMaster({
      title: 'NewMASTER_SLIDE',
      bkgd: 'FFFFFF',
      margin: [0.5, 0.25, 1.0, 0.25],
      slideNumber: { x: 0.6, y: 6.90, color: 'ff6400', fontFace: 'Arial', fontSize: 20 },
      objects: [
        { 'image': { x: 11.45, y: 6.90, w: 1.3, h: 0.5, data: logoSml } },
        // {
        //   'placeholder':
        //   {
        //     options: { name: 'title', type: 'title' },
        //     data: ''
        //   }
        // },
        // {
        //   'placeholder':
        //   {
        //     options: { name: 'body', type: 'body', x: 0.6, y: 1, w: 12, h: 5.61, fill: 'F1F1F1', color: 'F1F1F1' },
        //     text: '(supports custom placeholder text!)'
        //   }
        // }
      ]
    });

    pptx.defineSlideMaster({
      title: 'MASTER_SLIDE_TWO',
      bkgd: 'FFFFFF',
      margin: [0.5, 0.25, 1.0, 0.25],
      slideNumber: { x: 0.6, y: 6.90, color: 'ff6400', fontFace: 'Arial', fontSize: 20 },
      objects: [
        { 'image': { x: 11.45, y: 6.90, w: 1.3, h: 0.5, data: logoSml } },
        {
          'placeholder':
          {
            options: { name: 'title', type: 'title' },
            data: ''
          }
        },
        {
          'placeholder':
          {
            options: { name: 'body', type: 'body', x: 5, y: 1.5, w: 8, h: 5, fill: 'F1F1F1', color: 'F1F1F1' },
            text: '(supports custom placeholder text!)'
          }
        }
      ]
    });

    pptx.defineSlideMaster({
      title: 'MASTER_SLIDE_THREE',
      bkgd: 'FFFFFF',
      margin: [0.5, 0.25, 1.0, 0.25],
      slideNumber: { x: 0.6, y: 6.90, color: 'ff6400', fontFace: 'Arial', fontSize: 20 },
      objects: [
        { 'image': { x: 11.45, y: 6.90, w: 1.3, h: 0.5, data: logoSml } },
        {
          'placeholder':
          {
            options: { name: 'title', type: 'title' },
            data: ''
          }
        },
        {
          'placeholder':
          {
            options: { name: 'body', type: 'body', x: 0.6, y: 0.9, w: 12, h: 5, fill: 'F1F1F1', color: 'F1F1F1' },
            text: '(supports custom placeholder text!)'
          }
        }
      ]
    });

    pptx.defineSlideMaster({
      title: 'MASTER_SLIDE_TWO_HEADER',
      bkgd: 'FFFFFF',
      margin: [0.5, 0.25, 1.0, 0.25],
      slideNumber: { x: 0.6, y: 6.90, color: 'ff6400', fontFace: 'Arial', fontSize: 20 },
      objects: [
        { 'image': { x: 11.45, y: 6.90, w: 1.3, h: 0.5, data: logoSml } },
        {
          'placeholder':
          {
            options: { name: 'title', type: 'title' },
            data: ''
          }
        },
        {
          'placeholder':
          {
            options: { name: 'body', type: 'body', x: 4, y: 1.5, w: 8, h: 5.5, fill: 'F1F1F1', color: 'F1F1F1' },
            text: '(supports custom placeholder text!)'
          }
        }
      ]
    });

    this.setLoader.emit(true);

    this.firstlide(pptx, AppConstants.first_ppt_banner);
    this.secondSlide(pptx);
    this.thirdSlide(pptx);
    this.pptChartsWithDetails.forEach(function (graph: any, index: any) {
      if (that.getChartData(that.filterData, graph.tabId, graph.graphIndex)) {
        if ([4, 5, 6, 8, 9].indexOf(index) >= 0) {
          that.genrateBarChartSidebar(
            pptx,
            that.getChartData(that.filterData, graph.tabId, graph.graphIndex),
            graph.slideTitle, graph.graphTitle, graph.slideTitleFontSize,
            graph.chartParams,
            graph.graphSidebar,
            index == 8 ? true : false
          );

        } else if ([7].indexOf(index) >= 0) {
          that.genrateBarChartFooter(
            pptx,
            that.getChartData(that.filterData, graph.tabId, graph.graphIndex),
            graph.slideTitle, graph.graphTitle, graph.slideTitleFontSize,
            graph.chartParams,
            graph.graphSidebar
          );
        } else if ([10].indexOf(index) >= 0) {
          console.log(that.filterData)
          console.log(graph.tabId,graph.graphIndex)
          console.log( graph.slideTitle, graph.graphTitle, graph.slideTitleFontSize,graph.chartParams,graph.graphSidebar)
          that.genrateBarChartSidebarWithText(
            pptx,
            that.getChartData(that.filterData, graph.tabId, graph.graphIndex),
            graph.slideTitle, graph.graphTitle, graph.slideTitleFontSize,
            graph.chartParams,
            graph.graphSidebar
          )
        } else {
          console.log(index)
          that.genrateBarChart(
            pptx,
            that.getChartData(that.filterData, graph.tabId, graph.graphIndex),
            graph.slideTitle, graph.graphTitle, graph.slideTitleFontSize,
            graph.chartParams,
            index == 11 ? true : false
          );
          console.log(graph.tabId, graph.graphIndex)
        }

      } else {
        that.emptySlide(pptx, graph.slideTitle, graph.graphTitle, graph.slideTitleFontSize);
      }
    });

    this.bannerImageSlide(pptx, AppConstants._pptExitBackgroundImage);

    pptx.writeFile(
      {fileName:fileName}
    ).then((data: any) => {
      this.setLoader.emit(false);
    });
  }

  genrateBarChart(pptx: any, dataChart: any, slideTitle: any, graphTitle: any, slideTitleFontSize: any, chartOptions: any, header: any) {
    const slide = pptx.addSlide('MASTER_SLIDE');
    slide.addText(slideTitle, { placeholder: 'title', x: 0.7, y: 0.2, w: 12, fontSize: slideTitleFontSize, fontFace: 'Arial', fontWeight: '900', color: 'ff6400' });
    if (header) {
      slide.addText('Increase in her daughter’s genetic value + Increase in her granddaughter’s genetic value', { x: 1.2, y: 0.8, w: 12, fontWeight: 'bold', fontFace: 'Arial' });
    }
    slide.addChart(pptx.charts.BAR, dataChart, chartOptions);
  }


  genrateBarChartFooter(pptx: any, dataChart: any, slideTitle: any, graphTitle: any, slideTitleFontSize: any, chartOptions: any, footData: any) {
    const slide = pptx.addSlide('MASTER_SLIDE_THREE')
    slide.addText(slideTitle, { placeholder: 'title', x: 0.7, y: 0.2, w: 12, fontSize: slideTitleFontSize, fontFace: 'Arial', fontWeight: '900', color: 'ff6400' });
    slide.addChart(pptx.charts.BAR, dataChart, chartOptions);
    slide.addText(this.profitPartPerCowFooterDescList[0],
      { x: 0.7, y: 3.1, w: 12, h: 6.1, margin: [0.5, 10, 1.0, 0.25], fontWeight: 'bold', bullet: true, fontFace: 'Arial' }
    );
    slide.addText(footData.body,
      { x: 0.9, y: 3.6, w: 12, h: 6.1, margin: [0.5, 10, 1.0, 0.25], fontWeight: 'bold', bullet: true, fontFace: 'Arial' }
    );
  }

  genrateBarChartSidebar(pptx: any, dataChart: any, slideTitle: any, graphTitle: any, slideTitleFontSize: any, chartOptions: any, sidebar: any, multiple = false) {
    const slide = pptx.addSlide('MASTER_SLIDE_TWO');
    slide.addText(slideTitle, { placeholder: 'title', x: 0.7, y: 0.2, w: 12, fontSize: slideTitleFontSize, fontFace: 'Arial', fontWeight: '900', color: 'ff6400' });
    if (multiple) {
      slide.addText(sidebar[0].MainTitle, { x: 0.6, y: 1.9, w: '38%', h: 0.3, margin: 0, fontSize: 22.4, bullet: true, fontWeight: 'bold', fontFace: 'Arial' });
      slide.addText(sidebar[0].body,
        { x: 0.8, y: 2.1, w: '30%', h: 2.3, fontFace: 'Arial', }
      );
      slide.addText(sidebar[1].MainTitle, { x: 0.6, y: 4.4, w: '38%', h: 0.3, margin: 0, fontSize: 22.4, bullet: true, fontWeight: 'bold', fontFace: 'Arial' });
      slide.addText(sidebar[1].body,
        { x: 0.8, y: 4.5, w: '30%', h: 2, margin: [0.5, 10, 1.0, 0.25], fontWeight: 'bold', fontFace: 'Arial' }
      );
    } else {
      if (sidebar.MainTitle) {
        slide.addText(sidebar.MainTitle, { x: 0.4, y: 2, w: '38%', h: 0.3, margin: 0, fontSize: 22.4, bullet: true, fontWeight: 'bold', fontFace: 'Arial' });
        slide.addText(sidebar.body,
          { x: 0.6, y: 2.5, w: '30%', h: 2.2, fontWeight: 'bold', fontFace: 'Arial' }
        );

      } else {
        slide.addText(sidebar.body,
          { x: 0.8, y: 2, w: '30%', h: 3.5, margin: [0.5, 10, 1.0, 0.25], fontWeight: 'bold', fontFace: 'Arial' }
        );
      }
    }
    slide.addChart(pptx.charts.BAR, dataChart, chartOptions);

  }
  genrateBarChartSidebarWithText(pptx: any, dataChart: any, slideTitle: any, graphTitle: any, slideTitleFontSize: any, chartOptions: any, sidebar: any) {
    const slide = pptx.addSlide('MASTER_SLIDE_TWO_HEADER');
    slide.addText(slideTitle, { placeholder: 'title', x: 0.7, y: 0.2, w: 12, fontSize: slideTitleFontSize, fontFace: 'Arial', fontWeight: '900', color: 'ff6400' });

    slide.addText(sidebar.MainTitle, { x: 0.4, y: 1.2, w: '100%', fontFace: 'Arial', bullet: true });
    slide.addText(sidebar.body,
      { x: 0.5, y: 1.5, w: '30%', h: 3, fontWeight: 'bold', fontFace: 'Arial' }
    );
    slide.addChart(pptx.charts.BAR, dataChart,chartOptions);

  }

  getChartData(alldata: any, tab: any, index: any) {
    const data = alldata.filter((x: any) => x.tabId == tab);
    const formatData: any = this.structureData(data);
    if (typeof formatData[index] === 'undefined') {
      return false;
    } else {
      return formatData[index]['structureData'];
    }
  }

  bannerImageSlide(pptx: any, image_url: any) {
    const slide = pptx.addSlide();
    slide.addImage({ path: image_url, x: 0, y: 0, w: 13.4, h: 7.5, sizing: { type: 'cover' } });

  }


  firstlide(pptx: any, image_url: any) {
    const slide = pptx.addSlide();
    slide.addImage({ path: image_url, x: 0, y: 0, w: 13.4, h: 7.5, sizing: { type: 'cover' } });
    slide.addText('CLARIFIDE',
      { x: 6, y: 2.5, w: '50%', h: 3, fontWeight: '900', fontFace: 'Arial', fontSize: 60, color: 'F1F1F1', }
    );
    slide.addText('Profit Planner',
      { x: 6, y: 3.5, w: '100%', h: 3, fontWeight: '900', fontFace: 'Arial', fontSize: 60, color: 'F1F1F1' }
    );
  }

  secondSlide(pptx: any) {
    const slide = pptx.addSlide('NewMASTER_SLIDE');
    slide.addText('CLARIFIDE Profit Planner', { placeholder: 'title', x: 0.5, y: 0.5, w: 12,h:3, fontSize: 45, fontFace: 'Arial', fontWeight: '900', color: 'ff6400' });
    slide.addText('A Zoetis dairy consulting tool that allows the user to identify genomic testing, breeding and selection strategies to maximize profit',
      { x: 0.5, y: 1.9, w: 12, h:3, fontWeight: '900', fontFace: 'Arial', fontSize: 25, bullet: true }
    );
    slide.addText('Powered by University of Florida software/model (Dr. Albert DeVries)',
      { x: 0.5, y: 3.2, w: 12,h:3, fontWeight: '900', fontFace: 'Arial', fontSize: 25, bullet: true }
    );
    slide.addText(' ',
      { placeholder: 'body' }
    );
  }

  thirdSlide(pptx: any) {
    const slide = pptx.addSlide('NewMASTER_SLIDE');
    slide.addText('Questions the CLARIFIDE Profit Planner is Trying to Answer', { placeholder: 'title', x: 0.5, y: 0.1, w: 12,h:3, fontSize: 45, fontFace: 'Arial', fontWeight: '900', color: 'ff6400' });
    slide.addText('How many heifers are needed?',
      { x: 0.5, y: 2, w: 12,h:3, fontWeight: '900', fontFace: 'Arial', fontSize: 25, bullet: true }
    );
    slide.addText('Which strategy makes the most genetic progress?',
      { x: 0.5, y: 3.2, w: 12,h:3, fontWeight: '900', fontFace: 'Arial', fontSize: 25, bullet: true }
    );

    slide.addText('What strategy maximizes profitability?',
      { x: 0.5, y: 4.5, w: 12,h:3, fontWeight: '900', fontFace: 'Arial', fontSize: 25, bullet: true }
    );
    slide.addText(' ',
      { placeholder: 'body' }
    );
  }


  exitSlide(pptx: any) {
    const slide = pptx.addSlide();
    slide.addImage({ data: AppConstants._pptExitBackgroundImage, x: 0, y: 0, w: 13.4, h: 7.5, sizing: { type: 'cover' } });
  }

  emptySlide(pptx: any, slideTitle: any, graphTitle: any, slideTitleFontSize: any,) {
    const slide = pptx.addSlide('MASTER_SLIDE');
    slide.addText(slideTitle, { placeholder: 'title', x: 0.7, y: 0.2, w: 12, fontSize: slideTitleFontSize, fontFace: 'Arial', fontWeight: '900', color: 'ff6400' });
    slide.addText('.', { placeholder: 'body', x: 0.6, y: 1.5, w: 12, h: 4.25, fill: 'F1F1F1', color: 'F1F1F1', border: { pt: '1', color: '00EE00' } });
    slide.addText([
      { text: 'No Chart Available', options: { fontSize: 35, color: 'ff6400' } },
    ],
      { x: 0.5, y: 0.75, w: 8.5, h: 2.5, valign: 'middle', align: 'center', placeholder: 'body', }
    );
  }

  removeSpecialChars(str: any) {
    if (!str) {
      return 0;
    }
    let tempData = str.replace(/[$]/g, ''); /** Replace $ */
    /** check number in string if not replace - */
    if (!/\d/.test(tempData)) {
      tempData = tempData.replace(/[-]/g, '');
    }
    tempData = tempData.replace(/,/g, ''); /** Replace , */
    tempData = tempData.replace(/\s+/g, '0');
    tempData = tempData.replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
    if (!tempData.length) {
      tempData = 0;
    } else {
      tempData = tempData.replace(this.currencySymbol, '');
      tempData = [''].indexOf(tempData) == -1 ? parseFloat(tempData) : 0;
      //tempData= this.currencySymbol+tempData;
    }
    return tempData;
  }

  structureData(data: any) {
    const extractTabData = _.chain(data)
      .groupBy('graph')
      .map((value, key) => ({ graph: key, data: value }))
      .value()
      .map((d: any) => {
        let groupByGraphData = _.chain(d.data)
          .groupBy('fieldName')
          .map((value, key) => ({ fieldName: key, data: value }))
          .value()
          .map((indata: any) => {
            indata['name'] = _.uniq(indata.data.map((v: any) => {
              return v.fieldDisplayName;
            }
            ))[0];

            indata['values'] = indata.data.filter((x: any) => x.isDiffVal == false).map((v: any) =>
              v.value ? this.removeSpecialChars(v.value.toString()) : 0
            );
            indata['labels'] = indata.data.filter((x: any) => x.isDiffVal == false).map((v: any) =>
              v.isReportVal == false ? v.scenario : v.report
            );
            delete indata.data;
            return indata;
          });

        let d_data = groupByGraphData.filter((d: any) => (d.fieldName == "Genetic revenue change due to all genetic strategies (calf selection and breeding)") || (d.fieldName == "Value of kept hfr calves being produced in next gen of calves born (assumes no genetic benefits but includes differing herd demog and brdg)") || (d.fieldName == "Genomic testing cost, per milk cow"));
        if (d_data.length > 1) {
          let newData: any = {};
          let refObjectData = d_data[0];
          let filterArray = d_data.map(x => x['values']);

          newData['labels'] = refObjectData['labels'];
          newData['name'] = 'Genetic Progress Profit';
          newData['values'] = this.calculatedSum(filterArray[0], filterArray[1], filterArray[2]);
          /** remove field name */
          groupByGraphData = groupByGraphData.filter((d: any) => (d.fieldName !== "Genetic revenue change due to all genetic strategies (calf selection and breeding)")).filter((e: any) => (e.fieldName !== "Value of kept hfr calves being produced in next gen of calves born (assumes no genetic benefits but includes differing herd demog and brdg)")).filter((f: any) => (f.fieldName !== "Genomic testing cost, per milk cow"))
          groupByGraphData.push(newData);
        }
        d['structureData'] = groupByGraphData;
        return d;
      });
    return extractTabData;
  }

  calculatedSum(array1: any, array2: any, array3: any) {
    let result = [];
    let ctr = 0;
    let x = 0;

    if (array1.length === 0)
      return "array1 is empty";
    if (array2.length === 0)
      return "array2 is empty";

    if (array3.length === 0) {
      return "array3 is empty"
    }

    while (ctr < array1.length && ctr < array2.length && ctr < array3.length) {
      result.push(array1[ctr] + array2[ctr] - array3[ctr]);
      ctr++;
    }
    return result;
  }

}
