import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from '../_services/customer.service';
import { DataChangeService } from '../_services/data-change.service';
import { MarketSetupService } from '../_services/market-setup.service';
import { SharedService } from '../_services/shared-service.service';
declare var $: any;
@Component({
  selector: 'app-manage-customers',
  templateUrl: './manage-customers.component.html',
  styleUrls: ['./manage-customers.component.css']
})
export class ManageCustomersComponent implements OnInit {

  customer: any = {};
  customers :any= [];
  allCustomers :any= [];
  stateList :any= [];
  markets:any = [];
  activeMarkets:any = [];
  showLoader = false;
  pagedItems: any;
  validationMessage = "";
  selectedMarketId = null;
  editCust = false;
  uStates = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
  isAdmin = localStorage.getItem("isadmin");
  userId = localStorage.getItem("UserId");
  mId:any=localStorage.getItem("MarketId");
  userMarketIds = this.mId.split(',');
  matchingCustomers:any = [];
  searchBusinessName='';
  countryWithCurrencyList:any=[];
  constructor(private sharedService: SharedService,
    private marketSetupService: MarketSetupService,
    private customerService: CustomerService,
    private dataChangeService: DataChangeService) { }

  ngOnInit() {
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Manage Customers', url: '/manage-customers' });
    this.resetCustomer();
    this.getAllMarkets();
  }

  getAllMarkets() {
    let that=this;
    this.showLoader = true;
    this.marketSetupService.getAllMarkets().subscribe(res => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.markets = responseData.map((r:any) => r);

      if (this.isAdmin != '1') {
        this.markets=this.markets.filter((m:any) => that.userMarketIds.indexOf(m.Id.toString()) != -1);
      }

      this.activeMarkets=this.markets.filter((m:any)=>m.IsActive);

      this.loadCustomers();
    });
  }

  getMarketNameById(marketId:any) {
    let m: any = this.markets.filter((m:any) => m.Id == marketId);
    let marketName = "";
    if (m.length > 0) {
      marketName = m[0].Country;
    }
    return marketName;
  }

  loadCustomers() {
    let that = this;
    this.showLoader = true;
    this.customerService.getAllCustomers().subscribe(res => {
      debugger
      let response: any = res;
      let responseData = response.ResponseData;
      this.customers = responseData.map((r:any) => r);
      this.customers = this.customers.sort((a:any, b:any) => (a.custId > b.custId ? -1 : 1));
      if (this.isAdmin != '1') {
        this.customers = this.customers.filter((c:any) => that.userMarketIds.indexOf(c.MarketId.toString()) != -1);
      }
      this.customers = this.customers.filter(
        (customer:any, i:any, arr:any) => arr.findIndex((c:any) => (c.businessName === customer.businessName) && (c.MarketId === customer.MarketId)) === i
      );

      this.customers = this.customers.map(function (c:any) {
        c.marketName = that.getMarketNameById(c.MarketId);
        return c;
      });
      this.allCustomers = this.customers;
      this.pagedItems = this.customers.slice(0, 10);
      this.showLoader = false;
    });
    this.resetCustomer();
  }

  resetCustomer() {
    this.editCust = false;
    this.customer =
    {
      Id: 0,
      BusinessName: "",
      Address: "",
      MarketId: 0,
      StateProvince: "",
      City: "",
      PostalCode: "",
      Complex: "",
      CreatedBy: this.userId,
      UserId:this.userId
    }

  }

  validateMandatoryFields() {

    if (this.customer.BusinessName.trim() == "" || this.customer.MarketId === 0) {
      return false;
    }

    return true;
  }

  validateCustomer() {
    let custAlreadyExist = this.customers.filter((c:any) => c.businessName?.trim() == this.customer.BusinessName.trim()
      && c.MarketId == this.customer.MarketId).length > 0;
    if (custAlreadyExist) {

      if (!this.editCust) {
        this.validationMessage = "The customer name is already exist in the selected market.";
        $("#custValidationModal").modal("show");
      }

      return this.editCust;
    }

    return true;
  }

  saveCustomer() {
    if(!this.customer || !this.customer.BusinessName || !this.customer.MarketId) {
      return;
    }
    let isValidCustomer = this.validateCustomer();
    if (!isValidCustomer) {
      return;
    }
    this.showLoader = true;
    this.customerService.saveCustomer(this.customer).subscribe({next:(res:any) => {
      this.loadCustomers();
  }
});
  }

  editCustomer(customer:any) {
    this.editCust = true;
    this.customer = {
      Id: customer.custId,
      BusinessName: customer.businessName,
      Address: customer.addressLine1,
      MarketId: customer.MarketId,
      StateProvince: customer.stateProvince,
      City: customer.city,
      PostalCode: customer.postalCode,
      Complex: customer.Complex,
      CreatedBy: customer.createdBy,
      UserId: customer.userId
    };
  }

  pagedItemsChange(items:any) {
    this.pagedItems = items;
  }

  filterCustomers() {
    this.customers = this.selectedMarketId != null ? this.allCustomers.filter((u:any) => u.MarketId == this.selectedMarketId) : this.allCustomers;
    this.customers = this.searchBusinessName && this.searchBusinessName != '' ? this.customers.filter((x:any) => x.businessName?.toLowerCase().startsWith(this.searchBusinessName.toLowerCase().trim())) : this.customers;
    this.dataChangeService.emitPagerReloadEvent(this.customers);
  }

  validateInput(e:any) {

    let keyCode = e.keyCode || e.charCode;
    if ((keyCode >= 48 && keyCode <= 57)) {
      return true;
    }
    return false;
  }

  onKeyUp(val: any) {
    var curval = val.target.value;
    if (curval.length > 3 || curval.trimStart().indexOf(' ') > -1) {
      this.matchingCustomers = this.allCustomers.filter((x:any) => x.businessName?.toLowerCase().startsWith(curval.toLowerCase().trim()));
    } else {
      this.matchingCustomers = null;
    }
  }

  filterMarket(market: any) {
    return market.IsActive;
  }

}
