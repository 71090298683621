import { Component, OnInit } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { AdminSearchService } from '../_services/admin-search.service';
import { Router} from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-admin-search',
  templateUrl: './admin-search.component.html',
  styleUrls: ['./admin-search.component.css']
})
export class AdminSearchComponent implements OnInit {
  public isadmin = localStorage.getItem('isadmin');
  UmmUserId = localStorage.getItem("UmmUserId");
  ClToken = sessionStorage.getItem("Token");
  AdminUmmUserId = localStorage.getItem("AdminUmmUserId");
  public currentTime = new Date();
  public data:any= {};
  public users:any= {};
  public searchUserModel = {
    name: '',
    email: '',
    city: '',
    state: '',
    zipcode: ''
  }
  showLoader = false;
  constructor(public AdminSearchService: AdminSearchService,
    public sharedService: SharedService,
    public router: Router) { }

  ngOnInit() {
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Admin Search', url: '/adminsearch' });
    this.searchAdminusers();
  }
  searchAdminusers(): void {
    let sCustomerId = localStorage.getItem('UmmUserId');
    this.showLoader = true;
    this.AdminSearchService.searchAdmins(sCustomerId).subscribe({
      next:(response:any) =>{
        this.data = response.userResult;
        this.showLoader = false;
        // console.log('searchAdminusers', this.data);
        // console.log(sCustomerId);
        // JSON.parse(localStorage.getItem('currentUser'))
      },error:(err:any)=>{
        console.log(err)
      }, complete:()=>{
        console.log('completed')
      }
    });
  }

  selectuserClickfunction(user:any) {
    localStorage.setItem("SelectedUserName", user.Name);
    localStorage.setItem("SelectedUmmUserId", user.UmmUserId);
    localStorage.setItem("SelectedUserEmail", user.emailId);
    localStorage.setItem("fname", user.Name);
    localStorage.setItem('SelectedUserMarketIds',user.MarketIds);
    this.router.navigate(['/dashboard']);
  }

}
