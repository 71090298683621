import { Component, OnInit } from '@angular/core';
import { HistoricalReportService } from '../_services/historical-report.service';
import { AppSettings } from '../appSettings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from '../_services/shared-service.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { DatepickerOptions } from 'ng2-datepicker';
import { CreateReportService } from '../_services/create-report.service';
import * as _ from 'underscore';
import { SupportRequestFor } from '../_enums/support-request-for';
import { SupportService } from '../_services/support.service';
declare var $: any;

@Component({
  selector: 'app-historical-reports',
  templateUrl: './historical-reports.component.html',
  styleUrls: ['./historical-reports.component.css']
})
export class HistoricalReportsComponent implements OnInit {

  // datePickerOptions: any = {
  //   displayFormat: 'MM/DD/YYYY',
  //   maxDate: new Date(Date.now()),
  // };

  public currentTime = new Date();

  getAllCust: any = [];
  recentReportLists: any = [];

  ClToken = sessionStorage.getItem("Token");
  public isadmin = localStorage.getItem('isadmin');
  fname = localStorage.getItem('fname');
  public date: any;
  public sreportName: any;
  public sbusinessName: any;
  public sgeneratedby: any;
  public sfromDate: any;
  public stoDate: any;// = { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } };
  isAdmin = localStorage.getItem('isadmin') == '1';
  showLoader = false;
  ummUserId: any;
  pageIndex = 1;
  pageSize = 15;
  totalCount = 0;
  viewReportErrorMessage: string = this.sharedService.viewReportErrorMessageText;
  notifyReport: any;
  constructor(public HistoricalReportService: HistoricalReportService,
    public http: HttpClient,
    public sharedService: SharedService,
    public router: Router,
    public createReportService: CreateReportService,
    private supportService: SupportService) {
    this.date = this.sharedService.getData('date');
  }

  ngOnInit() {
    this.getSearchHistoricialReport();
  }

  getSearchHistoricialReport() {
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Historical Report', url: '/historicalreports' });
    this.ummUserId = localStorage.getItem("UmmUserId");
    if (localStorage.getItem('isadmin') == '1') {
      this.ummUserId = localStorage.getItem('SelectedUmmUserId');
    }
    this.loadHistoricalReports();
  }

  loadHistoricalReports() {
    this.showLoader = true;
    this.HistoricalReportService.getHistoricialReport(this.pageIndex, this.pageSize, this.ummUserId)
      .subscribe((res: any) => {
        let response = res;
        this.totalCount = response.totalCount;
        let reports = response.report;
        if (!this.recentReportLists) {
          this.recentReportLists = reports;
        } else {
          this.recentReportLists = _.union(this.recentReportLists, reports);
        }
        this.showLoader = false;
        console.log(this.recentReportLists)
      });
  }

  viewReport(report: any) {
    this.notifyReport = report;
    // if (report.IsReportCreated == 0) {
    //   $('#viewReportHistoricalErrorMessage').modal({ backdrop: 'static', keyboard: false });
    //   $('#viewReportHistoricalErrorMessage').modal('show');
    // } else {
    localStorage.setItem('pagecomingfrom', 'Historical Reports');
    this.sharedService.setReport(report);
    this.router.navigate(['./viewreport']);
    //}
  }
  recentReport: any;
  showReport(recentReport: any) {
    this.recentReport = recentReport
    console.log(recentReport)
  }
  cloneReportConfirm(reportId: any, customerId: any, reportExcelVersion: any) {
    this.createReportService.cloneReportConfirm(reportId, customerId, reportExcelVersion);
  }

  onScrollUp() { 
    console.log('scrolled up')
  }

  onScrollDown() {
    console.log('scrolled down!!');
    if (this.recentReportLists.length < this.totalCount) {
      this.pageIndex = this.pageIndex + 1;
      this.loadHistoricalReports();
    }
  }

  onViewReportError() {
  }

  notifySupport() {
    this.supportService.notifySupport(this.notifyReport.reportId, '', SupportRequestFor[SupportRequestFor.ViewReport], null, function (obj: any) {
    })
  }
}
