import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { AppSettings } from '../appSettings';
import { HeaderService } from './header.service';
import toHttpParams from '../_helpers/toHttpParams';
import { Observable } from 'rxjs';
declare var $: any;

@Injectable()
export class UserService {

  constructor(public _http: HttpClient, private headerService: HeaderService) { }

  public getAllUsers(postModel:any) {

    let getAllUsersUrl: string = AppSettings._getAllUsersUrl;

    console.log('url:', getAllUsersUrl);
    console.log('data passed with the url:', postModel);
    let options = this.headerService.getHeader();
    const params = toHttpParams(postModel);
    return this._http.get(getAllUsersUrl, { params, headers: options });
  }

  saveUser(user:any): Observable<any[]> {
    let saveUserUrl = AppSettings._saveUserUrl;
    let options = this.headerService.getHeader();

    console.log('url:', saveUserUrl);
    console.log('data passed with the url:', user);

    return this._http.post<any[]>(saveUserUrl, user, {headers:options});
  }

  reInviteUser(userId:any):Observable<any[]> {
    let reInviteUserUrl = AppSettings._reInviteUserUrl + "?UserId=" + userId;
    let options = this.headerService.getHeader();

    console.log('url:', reInviteUserUrl);
    console.log('data passed with the url:', '');

    return this._http.post<any[]>(reInviteUserUrl, {headers:options});
  }

}
