import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// import 'rxjs/add/operator/map';
import * as _ from 'underscore';
import { AppConstants } from '../appConstants';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class GoogleAnalyticsService {

  constructor(private router: Router) { }

  sendPageViewInGA() {
    this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    })).subscribe((x: any) => {
      (<any>window).gtag('config', AppConstants._ga_tracking_id, {
        'page_title': x.url,
        'page_path': x.url
      });
    });

    // Using Rx's built in `distinctUntilChanged ` feature to handle url change c/o @dloomb's answer
    // this.router.events.distinctUntilChanged((previous: any, current: any) => {
    //   // Subscribe to any `NavigationEnd` events where the url has changed
    //   if (current instanceof NavigationEnd) {
    //     return previous.url === current.url;
    //   }
    //   return true;
    // }).subscribe((x: any) => {
    //   (<any>window).gtag('config', AppConstants._ga_tracking_id, {
    //     'page_title': x.url,
    //     'page_path': x.url
    //   });
    // });
  }

  sendEvent = (eventCategory:any,eventLabel:any,eventAction:any) => {
    (<any>window).gtag('event', 'click', {
      'event_category':eventCategory,
      'event_label': eventLabel,
      'event_action': eventAction,
    });
  }

}
