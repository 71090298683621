import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../appSettings';
declare var $: any;
declare var gigya: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  web_url = AppSettings.web_url;
  Token: any;
  changePasswordWidgetLoadInProgress = true;
  loadIntervalId: any;
  constructor() { }

  ngOnInit() {
    let that = this;
    this.Token = this.GetParameterValues("Token");
    this.changePasswordGigyaWidgetLoadHandler();
    this.loadIntervalId = setInterval(function () {
      if ($('#change-password-gigya-widget-container form').length > 0) {
        $('#change-password-gigya-widget-container form').ready(function () {
          clearInterval(that.loadIntervalId);
          that.changePasswordWidgetLoadInProgress = false;
        });
      }
    }, 1000)
  }

  changePasswordGigyaWidgetLoadHandler() {

    gigya.accounts.showScreenSet({ screenSet: 'RegistrationLogin', containerID: 'change-password-gigya-widget-container', startScreen: 'gigya-reset-password-screen' });

  }


  GetParameterValues(param:any) {
    var url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < url.length; i++) {
      var urlparam = url[i].split('=');
      if (urlparam[0] == param) {
        return urlparam[1];
      }
    }
    return null
  }

  changePasswordCallback(event:any, callback:any) {

    if (event.eventName == "CANCEL") {
      window.location.href = '/#/login';
    }

    if (event.eventName == "SUCCESS") {
      window.location.href = '/#/login';
    }
  }

}
