// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointer{
	cursor: pointer;
}
#head_class{
	background: white ;
}`, "",{"version":3,"sources":["webpack://./src/app/header/header.component.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;AACA;CACC,kBAAkB;AACnB","sourcesContent":[".pointer{\r\n\tcursor: pointer;\r\n}\r\n#head_class{\r\n\tbackground: white ;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
