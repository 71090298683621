import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'marketFilter'
})
export class MarketFilterPipe implements PipeTransform {
  transform(items: any[], argMarketId:any): any[] {
    if (!items) return [];
    if (argMarketId==null) argMarketId="";
    
    return items.filter(it => {
      return it.MarketId !=null && 
             it.MarketId.toString().toLowerCase().includes(argMarketId.toString().toLowerCase())
    });
  }
}