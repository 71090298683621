import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { HeaderService } from '../_services/header.service';

@Injectable()

export class DeleteReportService {
    constructor(private _http: HttpClient,
        public headerService: HeaderService) {
    }

    deleteReport(repId:any, secId:any, type:any) {
        let _deleteReportServiceurl = AppSettings._deleteReportServiceurl + repId + '&scenarioId=' + secId + '&type=' + type;
        let that = this;

        console.log('url:', _deleteReportServiceurl);
        console.log('data passed with the url:', '');
        let options = this.headerService.getHeader();
        return this._http.delete(_deleteReportServiceurl, {headers:options});
    }

}