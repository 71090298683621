import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { HeaderService } from '../_services/header.service';

declare var $: any;
@Injectable()
export class HistoricalReportService {
  constructor(public _http: HttpClient,
    public headerService: HeaderService) { }

  public getHistoricialReport(pageIndex: any, pageSize: any, sUserId?: any) {
    let getHistoricalReportUrl: string = AppSettings._getHistoricalReportUrl + '?userId=' + sUserId + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize;

    console.log('url:', getHistoricalReportUrl);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    return this._http.get(getHistoricalReportUrl, {headers:options});
  }
}

