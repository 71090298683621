import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Directive, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[scroll-to]' })
export class ScrollToDirective implements OnInit {

  @Input('scroll-to') controlCount: number=0;

  constructor(private hostElement: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    console.log('scroll-to ngOnInit called');
  }

  ngAfterViewInit(){
    console.log('scroll-to ngAfterViewInit called');
    window.scroll(0, this.hostElement.nativeElement.offsetTop);
  }
}