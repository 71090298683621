import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { AppSettings } from '../appSettings';
declare var $: any;
import { HeaderService } from '../_services/header.service';

@Injectable()
export class SearchCustomerService {
  public customerLists = '';
  refEmployeeId = '';
  mdmAddressId = '';

  constructor(public _http: HttpClient,public headerService: HeaderService) { }
  public getSearchCustomer(sBusinessName: any, sCity: any, sState: any, sZipCode: any, sNarcId: any) {
    let email = localStorage.getItem("email");
    if (localStorage.getItem('isadmin') == '1') {
      email = localStorage.getItem('SelectedUserEmail');
    }
    let searchCustomerServiceurl: string = AppSettings._getAllGlobalCustomersUrl;

    console.log('url:', searchCustomerServiceurl);
    console.log('data passed with the url:', '');

    let postModel={
      customerName: sBusinessName,
      city: sCity,
      state: sState,
      zipCode: sZipCode,
      narcID: sNarcId
    }
    let options = this.headerService.getHeader();
    return this._http.post(searchCustomerServiceurl, postModel, {headers:options});
  }

}
