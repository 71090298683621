import { Component, OnInit } from '@angular/core';
import { ReportListService } from '../_services';

@Component({
  selector: 'app-poc',
  templateUrl: './poc.component.html',
  styleUrls: ['./poc.component.css']
})
export class PocComponent implements OnInit {

  constructor(private reportListService: ReportListService) { }
  showLoader: boolean = false;
  isPlaneA: boolean = true;
  planBResData:Array<any> = [];
  isPending:boolean=false;

  planBData = [
    {
      name: 'heifers, 1',
      col1: 2,
      col2: 0,
      col3: 0,
      col4: 1
    },
    {
      name: 'heifers, 2',
      col1: 0,
      col2: 0,
      col3: 3,
      col4: 0
    },
    {
      name: 'heifers, 3',
      col1: 0,
      col2: 0,
      col3: 5,
      col4: 0
    },
    {
      name: 'heifers, 4+',
      col1: 0,
      col2: 0,
      col3: 8,
      col4: 0
    },
    {
      name: 'parity 1, 1',
      col1: 0,
      col2: 9,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 1, 2',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 1, 3',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 1, 4+',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 2+, 1	',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 2+, 2',

      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 2+, 3',

      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 2+, 4',

      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    // {
    //   name:'2+, 5',

    //   col1:0,
    //   col2:0,
    //   col3:0,
    //   col4:0
    // },
  ];


  data = [
    {
      name: 'heifers, 1',
      col1: 2,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'heifers, 2',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'heifers, 3',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'heifers, 4+',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 1, 1',
      col1: 0,
      col2: 9,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 1, 2',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 1, 3',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 1, 4+',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 2+, 1	',
      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 2+, 2',

      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 2+, 3',

      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    {
      name: 'parity 2+, 4',

      col1: 0,
      col2: 0,
      col3: 0,
      col4: 0
    },
    // {
    //   name:'2+, 5',

    //   col1:0,
    //   col2:0,
    //   col3:0,
    //   col4:0
    // },
  ];
  resData = [];
  planeAsessionId:string=''
  ngOnInit(): void {
    let id=localStorage.getItem('tempSessionId');
    if(id){
      this.planeAsessionId=id;
    }
  }

  maxCheck(evnt:any){
console.log(evnt.target.value)

if(evnt.target.value>100){
  evnt.target.value=100;
}
if(evnt.target.value<0){
  evnt.target.value=0;
}

  }

  checkValue() {
    this.showLoader = true;
    this.resData = []
    let data = JSON.parse(JSON.stringify(this.data))
    let payload = data.map((item: any) => {
      delete item.name
      return item
    })
    this.reportListService.postPocPlanA(payload).subscribe((res: any) => {
      console.log(res);
      this.showLoader = false;;
      this.planeAsessionId=res;

      localStorage.setItem('tempSessionId',this.planeAsessionId);
      // this.resData = JSON.parse(res)
    }, (err: any) => {
      this.showLoader = false;;

    })
  }

  getPlaneAData(){
      this.reportListService.getPlaneAData(this.planeAsessionId).subscribe((res:any)=>{
        console.log(res);
        let data=JSON.parse(res);

        if(!data.length){
          this.isPending=true;
        }else{
          this.isPending=false;
          this.resData=data;
        }
      })
  }


  checkPlanBValue() {
    this.showLoader = true;
    this.planBResData = [];
    let data = JSON.parse(JSON.stringify(this.planBData))
    let payload = data.map((item: any) => {
      delete item.name
      return item
    })
    this.reportListService.postPocPlanB(payload).subscribe((res: any) => {
      console.log(res);
      this.showLoader = false;;

      this.planBResData = JSON.parse(res)
    }, (err: any) => {
      this.showLoader = false;;

    })
  }
  getPocData(){
    this.reportListService.getPocData().subscribe((res:any)=>{
      console.log(res);
      window.open(res,'_blank')

    })
  }
}
