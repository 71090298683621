import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportListService } from '../_services/index';
import { CreateReportService } from '../_services/index';
import { EditReportService } from '../_services/edit-report.service';
import { DeleteReportService } from '../_services/deleteReport.service';
import { SharedService } from '../_services/shared-service.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { CustomerDetailService } from '../_services/customer-detail.service';
import { DataChangeService } from '../_services/data-change.service'
import * as _ from 'underscore';
import { isNullOrUndefined } from 'util';
import { AppConstants } from '../appConstants';
import { MarketSetupService } from '../_services/market-setup.service';
declare var $: any;

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css'],
  providers: [DeleteReportService]
})

export class ReportListComponent implements OnInit {
  isnumb = false;
  isnumScenario = false;
  public isadmin = localStorage.getItem('isadmin');

  ClToken = sessionStorage.getItem("Token");
  UmmUserId = localStorage.getItem("UmmUserId");
  public vReportID: any = 7;
  public delay = 1500;
  @Input() payload: any = null;
  options: any;
  model: any;
  fileData: any;
  cloneflag: any;
  error:any={};
  reportObject:any= {};
  dropdownOpen = false;
  temp: any;
  data: any;
  reportName: any;
  reportDesc: any;
  customer: any;
  public button = {
    index: 1,
    button1: 'BACK',
    button2: 'NEXT',
    button3: 'SAVE'
  }
  public result: any;
  public reportOutputData: any;
  public reportInputData: any = this.sharedService.createReportInputData;
  businessNameTitle = this.sharedService.businessNameTitle || localStorage.getItem('businessNameTitle');
  public id: any;
  reportSubscription: any;
  reportChangeSubscription: any;
  addScenarioSubscription: any;
  scenarioAddedSubscription: any;
  cancelDataInputSubscription: any;
  public scenariointervalid: any;
  addedScenarioIntervalIds:any = [];
  public reportOutputStatus:any = [];
  public scenarioOutputStatus:any = [];
  addScenarioValue:any = [];
  scenarioInputData:any = [];
  inputDataTypeReport = 'report';
  inputDataTypeScenario = 'scenario';
  showReportInput = false;
  showReportOutput = false;
  deleteTye: any;
  deleteRepId: any;
  deleteSecId: any;
  deleteConfirmationMessage: any;
  checkedScenarioIds: any = [];
  scenarioActionMessage = "Request For Scenario Creation Sent Successfully.";
  showLoader = false;
  defaultAdditionalInputSelections: any = this.sharedService.defaultAdditionalInputSelections.map(x => Object.assign({}, x));
  countryWithCurrencyList:any = [];
  markets:any = [];
  reportMarketId:any = localStorage.getItem('reportMarketId'); //"2";
  reportMarketCurrency = localStorage.getItem('reportMarketCurrency');
  reportCurrencyValue = localStorage.getItem('reportCurrencyValue');
  reportOutputInUSD: any;
  scenarioDataInUSD: any;
  isReportConversion = false;
  currencySaveMessage = "Currency saved sccessfully";
  constructor(private router: Router,
    private reportListService: ReportListService,
    private createReportService: CreateReportService,
    private editReportService: EditReportService,
    public sharedService: SharedService,
    private http: HttpClient,
    private deleteReportService: DeleteReportService,
    private CustomerDetailService: CustomerDetailService,
    private dataChangeService: DataChangeService,
    private marketSetupService: MarketSetupService
  ) {
    this.fileData = '';
    this.error = {};
    this.reportObject = {};
    $('.modal-backdrop').removeClass("modal-backdrop");
    this.addScenarioSubscription = this.dataChangeService.getAddScenarioEmitter()
      .subscribe((item:any) => {
        this.scenarioActionMessage = item.status.toLowerCase() != 'draft' ? "Request For Scenario Creation Sent Successfully." : 'Scenario Saved as Draft';
        $('#scenarioMessageModal').modal({ backdrop: 'static', keyboard: false });
        $('#scenarioMessageModal').modal('show');
        this.addScenarioValue[item.index].showScenarioInput = false;
        this.addScenarioValue[item.index].showScenarioOutput = false;
        this.addScenarioValue[item.index].displayStatus = item.status.toLowerCase() != 'draft' ? 'In Progress' : 'Draft';
        this.addScenarioValue[item.index].showStatusBlock = true;
        this.addScenarioValue[item.index].disableScenarioToggleArrow = true;
       
        //this.scenarioInputData[item.index]=this.addScenarioValue[item.index]

      });

    this.scenarioAddedSubscription = this.dataChangeService.getScenarioAddedEmitter()
      .subscribe((item:any) => {
    
        if (item.scenarioInputData.status.toLowerCase() != 'draft') {

          let addedScenarioIntervalId = setInterval(() => {
            let existedScenarioTrack: any = this.scenarioOutputStatus.filter((s:any) => s.id === item.scenarioInputData.scenarioId);
            if (existedScenarioTrack != null) {
              this.scenarioOutputStatus = this.scenarioOutputStatus.filter((s:any) => s.id != item.scenarioInputData.scenarioId)
            }
            this.generateScenario(item.scenarioInputData, item.index);
          }, 45000);

          this.addedScenarioIntervalIds.push({ scenarioId: item.scenarioInputData.scenarioId, intervalId: addedScenarioIntervalId })

        } else {
          this.addScenarioValue[item.index].displayStatus = "Draft";
        }
      });

    this.reportSubscription = this.dataChangeService.getUpdateReportEmitter()
      .subscribe(item => {
        if (this.reportInputData.status.toLowerCase() != 'draft') {
          this.reportInputData.displayStatus = "In Progress";
        } else {
          this.reportInputData.displayStatus = "Draft";
        }
        this.showReportInput = false;
        this.reportInputData.showStatusBlock = true;
      });
      try{
        this.reportInputData['IsOldService']=false
      } catch{
        console.log('did not set IsOldService')
      }

    this.reportChangeSubscription = this.dataChangeService.getReportChangeEmitter()
      .subscribe((item:any) => {
        this.fileData.reportId = item.reportId;
        this.reportInputData.reportId = item.reportId;
        let existingReport = this.reportOutputStatus.filter((r:any) => r.id === this.fileData.reportId)
        if (existingReport.length > 0) {
          this.reportOutputStatus = this.reportOutputStatus.filter((r:any) => r.id != this.fileData.reportId);
        }
        this.reportInputData['startLevelPTAindex'] = item['startLevelPTAindex'];
        this.generateReport();
      });

    this.cancelDataInputSubscription = this.dataChangeService.getCancelDataInputEmitter()
      .subscribe((item:any) => {

        if (item.reportId != null && item.scenarioId == null && item.type == 'report') {

          this.showReportInput = false;
          this.reportInputData.showStatusBlock = true;
        }

        if (item.scenarioId != null && item.type == 'scenario') {
          let canceledScenario = this.addScenarioValue.filter((s:any) => s.scenarioId == item.scenarioId)[0];
          if (canceledScenario != null) {
            canceledScenario.showScenarioInput = false;
            canceledScenario.showStatusBlock = true;
          }
        } else if (item.reportId != null && item.scenarioId == null && item.type == 'scenario') {
          this.addScenarioValue.splice(item.index, 1);
          this.scenarioInputData = this.addScenarioValue;
        }

      });

    // Init Excel Mapping Data if not initiated till now
    let currentExcelVersion = AppConstants._current_excel_version;
    if (_.isUndefined(this.sharedService.excelMappingData) || this.sharedService.excelVersion != currentExcelVersion) {
      this.sharedService.initExcelMappingsData(currentExcelVersion);
    }
  }

  ngOnInit() {

    this.sharedService.validateLoggedInToken();
    let that = this;
    if (this.sharedService.editreport === 1) {
      this.data = this.editReportService.getLastReportData();
      this.sharedService.editreport = 0;
    }
    //if created report
    else {
      this.data = this.createReportService.getLastReportData();
    }
    let data = this.data;
    if (!data) {
      this.router.navigateByUrl('/createreport');
    } else {
      this.fileData = {
        name: data.ReportName || data.reportName,
        description: data.ReportDesc || data.reportDesc,
        reportId: data.reportId || data.ReportID
      }
      console.log(this.reportInputData)
      this.reportInputData.name = data.ReportName || data.reportName;
      this.reportInputData.description = data.ReportDesc || data.reportDesc;

      this.sharedService.analysisOutputData = {
        ReportId: this.fileData.reportId,
        ReportName: this.reportInputData.name,
        ReportDesc: this.reportInputData.description,
        ScenarioOutput: []
      };

    }

    console.log(this.fileData.reportId);

    if (this.reportInputData != null) {

      if (this.reportInputData.status.toLowerCase() != 'draft') {
        this.reportInputData.displayStatus = "In Progress";
      } else {
        this.reportInputData.displayStatus = "Draft";
      }

      if (this.reportInputData.status.toLowerCase() != 'draft') {
        this.generateReport();
      }

      this.defaultAdditionalInputSelections = this.sharedService.markCheckedAdditionalInputSelection(this.reportInputData.InputCheckBoxId);
    }

    this.sharedService.outputDataWithSelectionIndex = [];
    this.getMarkets();
  }

  getMarkets() {
    let that = this;
    this.showLoader = true;
    this.marketSetupService.getAllMarkets().subscribe(res => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.markets = responseData.map((r:any) => r);
      let mId:any=localStorage.getItem("MarketId");
      let userMarketIds = mId.split(',');
      let rMId:any=localStorage.getItem("reportMarketId");
      let reportMarketIds = rMId.split(',');
      if(reportMarketIds!=null){
        reportMarketIds.forEach((element:any) => {
          if(userMarketIds.indexOf(element) > -1){}
          else{            
              userMarketIds.push(element);
          }
        });
        
      }
      userMarketIds.concat(reportMarketIds);
      if (userMarketIds.indexOf('2') == -1) {
        userMarketIds.push('2');
      }
      this.markets = this.markets.filter((m:any) => userMarketIds.indexOf(m.Id.toString()) != -1);
      this.setLocalCurrencyMarket();
      this.showLoader = false;
    });
  }

  onKeyUpReportName(name:any) {
    this.isnumb = /^\d+$/.test(name);
    if (this.isnumb) {
      $('#reportName').css("border", "red solid 1px");
    }
    else {
      $('#reportName').css("border", "");

    }
  }
  onKeyUpScenarioName(name:any, index:any) {
    let isnumScenario = /^\d+$/.test(name);
    this.addScenarioValue[index].isnumScenario = isnumScenario;
    if (isnumScenario) {
      $('#scenarioName' + index).css("border", "red solid 1px");
    }
    else {
      $('#scenarioName' + index).css("border", "");
    }
  }


  reportInputSelectionChange(objInputSelection:any) {
    this.defaultAdditionalInputSelections = objInputSelection;
  }

  scenarioInputSelectionChange(objInputSelection:any, index:any) {
    this.addScenarioValue[index].defaultAdditionalInputSelections = objInputSelection;
    this.scenarioInputData[index].defaultAdditionalInputSelections = objInputSelection;
  }

  addMoreScenario(flag:any) {

    this.button.index = 1;
    if (flag.toLowerCase() == 'none') {
      let defaultInputData = this.sharedService.getDefaultInputData();
      defaultInputData.reportId = this.fileData.reportId;
      defaultInputData.showScenarioInput = true;
      this.addScenarioValue.push(defaultInputData);

      let j = this.addScenarioValue.length - 1;

      this.scenarioInputData[j] = this.addScenarioValue[j];
      this.addScenarioValue[j]["scenarioData"] = [];
      this.scenarioInputData[j].defaultAdditionalInputSelections = this.sharedService.defaultAdditionalInputSelections.map(x => Object.assign({}, x));
      this.scenarioInputData[j].copyFrom = "none";
    }
    else if (flag.toLowerCase() == 'plana') {
      let cloneScenario = JSON.parse(JSON.stringify(this.reportInputData));
      cloneScenario.showScenarioInput = true;
      cloneScenario.scenarioId = null;
      this.addScenarioValue.push(cloneScenario);
      let j = this.addScenarioValue.length - 1;

      this.addScenarioValue[j]["scenarioData"] = [];

      //clear previous scenario name and description
      this.addScenarioValue[j].scenarioName = '';
      this.addScenarioValue[j].scenarioDesc = '';
      this.scenarioInputData[j] = this.addScenarioValue[j];
      this.scenarioInputData[j].defaultAdditionalInputSelections = this.defaultAdditionalInputSelections.map((x:any) => Object.assign({}, x));
      this.scenarioInputData[j].copyFrom = "Report!" + this.reportInputData.reportId;
    }
    else {
      let cloneScenario = JSON.parse(JSON.stringify(this.addScenarioValue[flag]));
      let clonedScenarioId = cloneScenario.scenarioId;
      cloneScenario.showScenarioInput = true;
      cloneScenario.scenarioId = null;
      this.addScenarioValue.push(cloneScenario);
      let j = this.addScenarioValue.length - 1;

      this.addScenarioValue[j]["scenarioData"] = [];

      //clear previous scenario name and description
      this.addScenarioValue[j].scenarioName = '';
      this.addScenarioValue[j].scenarioDesc = '';
      this.scenarioInputData[j] = this.addScenarioValue[j];
      if (!isNullOrUndefined(this.addScenarioValue[j].defaultAdditionalInputSelections))
        this.scenarioInputData[j].defaultAdditionalInputSelections = this.addScenarioValue[j].defaultAdditionalInputSelections.map((x:any) => Object.assign({}, x));
      this.scenarioInputData[j].copyFrom = "Scenario!" + clonedScenarioId;
      this.scenarioInputData[j]['IsOldService']='false'
    }

    this.addScenarioValue[this.addScenarioValue.length - 1].name = '';
    this.addScenarioValue[this.addScenarioValue.length - 1].description = '';
    this.addScenarioValue[this.addScenarioValue.length - 1].showScenarioOutput = false;
    this.addScenarioValue[this.addScenarioValue.length - 1].showStatusBlock = false;
    this.scenarioInputData[this.addScenarioValue.length - 1].displayStatus = "";

    this.addScenarioValue[this.addScenarioValue.length - 1]['IsConversion'] = false;
  }

  cloneScenarioInpuData = [];
  addScenario() {
    this.cloneScenarioInpuData = this.scenarioInputData.map((s:any) => s);
    $("#addMoreScenarioModal").modal("show");
  }

  generateReport() {

    this.reportInputData.disableReportToggleArrow = true;

    this.isReportConversion = false;

    this.id = setInterval(() => {
      console.log("this.id = setInterval() called");
      console.log('reportId-', this.fileData.reportId);

      // Fix : In case of reportId is undefined 
      if (!this.fileData.reportId)
        return;

      if ((this.reportOutputStatus.find((r:any) => r.id === this.fileData.reportId) != null))
        return;

      let that = this;
      this.reportListService.generateOutput(this.fileData.reportId, 0).subscribe(response => {
        let reportOutput: any = response;
        if (!_.isEmpty(reportOutput)) {
          let outputBody = reportOutput;
          let reportResponseData = this.sharedService.mapExcelOutputData(true, this.reportInputData, outputBody);

          if (reportResponseData.length == 0) {
            console.log('Trying again');
          } else {
            this.reportOutputStatus.push({ id: this.fileData.reportId, dataStatus: "completed" });
            this.reportInputData.displayStatus = "Completed";
            console.log('Data inserted in sharepoint');
            console.log('Data', reportResponseData);
            clearInterval(this.id);
            console.log('Interval cleared');

            this.reportOutputData = reportResponseData;
            this.reportOutputData = that.sharedService.selectionBasedIndexOutputChange(this.reportOutputData, true, this.reportInputData.InputCheckBoxId);
            this.sharedService.analysisOutputData.ReportOutput = this.reportOutputData;
            // Display The Report after report binding
            this.showReportOutput = true;
            this.reportInputData.disableReportToggleArrow = false;


            // Change output view as per currency
            this.onCurrencyChange();
          }
        }
      });
    }, 45000);
  }

  generateScenario(scenarioInputData:any, index:any) {

    let scenarioId = scenarioInputData.scenarioId;

    this.addScenarioValue[index]['IsConversion'] = false;

    console.log("this.scenariointervalid = setInterval() called");
    if ((scenarioId == null) || (this.scenarioOutputStatus.find((s:any) => s.id === scenarioId) != null)) {
      return;
    }

    let that = this;
    this.reportListService.generateScenarioOutput(this.fileData.reportId, scenarioId).subscribe((response:any) => {

      let scenarioOutput: any = response;

      if (!_.isEmpty(scenarioOutput)) {
        let scenarioOutputBody = scenarioOutput;
        let scenarioResponseData = this.sharedService.mapExcelOutputData(false, scenarioInputData, scenarioOutputBody)

        if (scenarioResponseData.length == 0) {
          console.log('Trying again');
        }
        else {
          let existingInterval: any = this.addedScenarioIntervalIds.filter((i:any) => i.scenarioId == scenarioId)[0];
          if (existingInterval != null) {
            clearInterval(existingInterval.intervalId);
            this.addedScenarioIntervalIds=this.addedScenarioIntervalIds.filter((i:any) => i.scenarioId != scenarioId);
          }

          console.log('Data inserted in sharepoint');
          console.log('Scenario response data', scenarioResponseData);
          console.log('Interval cleared');

          this.scenarioOutputStatus.push({ id: scenarioId, dataStatus: "completed" });

          let scenariodata = scenarioResponseData;
          scenariodata = that.sharedService.selectionBasedIndexOutputChange(scenariodata, false, scenarioInputData.InputCheckBoxId);

          let scenarioVal = this.addScenarioValue.filter((s:any) => s.scenarioId == scenarioId)[0];

          if (scenarioVal != null) {
            scenarioVal["scenarioData"] = scenariodata;
          }


          let analysisScenarioOutput = {
            data: scenariodata,
            scenarioId: scenarioId,
            scenarioName: scenarioVal.scenarioName,
            scenarioDesc: scenarioVal.scenarioDesc,
            showScenarioOutput: false
          };

          if (this.sharedService.analysisOutputData.ScenarioOutput.filter((s:any) => s.scenarioId == scenarioId).length == 0) {
            this.sharedService.analysisOutputData.ScenarioOutput.push(analysisScenarioOutput);
          } else {
            this.sharedService.analysisOutputData.ScenarioOutput.filter((s:any) => s.scenarioId == scenarioId)[0] = analysisScenarioOutput;
          }

          this.onScenarioDataBindComplete(scenarioId);
        }
      }

    });
  }

  onScenarioDataBindComplete(scenarioId:any) {
    console.log('Scenario Data Bind Complete.');

    let scenarioVal = this.addScenarioValue.filter((s:any) => s.scenarioId == scenarioId)[0];
    if (scenarioVal != null) {
      scenarioVal.displayStatus = "Completed";
      scenarioVal.showScenarioOutput = true;
      scenarioVal.disableScenarioToggleArrow = false;
    }

    // Change output view as per currency
    this.onCurrencyChange();

    // Populate Generate Report Output Field Groups
    this.dataChangeService.emitLoadGenerateReportOutputFieldGroupdEvent(true);
  }

  confirmCreateScenario(index:any) {
    $("#cRModal" + index).modal("show");
  }

  editReport(reportId: any): void {
    this.showReportInput = true;
    this.sharedService.editreport = 1;
    this.sharedService.editscenario = 0;
    this.sharedService.setData('editreportID', reportId);
    localStorage.setItem('editReportMarketId', this.reportMarketId);
    this.reportInputData.showStatusBlock = false;
  }

  deleteReportScenario(repId:any, secId:any, type:any) {

    this.deleteTye = type;
    this.deleteRepId = repId;
    this.deleteSecId = secId;
    if (type == 'report') {
      this.deleteConfirmationMessage = this.sharedService.deleteConfirmationMessage;
    } else if (type == 'scenario') {
      this.deleteConfirmationMessage = "Are you sure, you want to delete the scenario ? ";
    }

    $('#confirmDelete').modal("show");
  }

  deleteConfirm() {
    let result = this.deleteReportService.deleteReport(this.deleteRepId, this.deleteSecId, this.deleteTye);
    result.subscribe({next:() => {
      if (this.deleteTye.toLowerCase() == 'report') {
        if (localStorage.getItem('isadmin') == '1') {
          this.router.navigate(['/admindashboard']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      } else if (this.deleteTye.toLowerCase() == 'scenario') {
        this.addScenarioValue = this.addScenarioValue.filter((s:any) => s.scenarioId != this.deleteSecId);
        this.scenarioInputData = this.addScenarioValue;
      }
  }
});
  }

  createReportPdf(): void {
    this.showLoader = true;
    this.CustomerDetailService.getReportPDFBlob(this.fileData.reportId).subscribe(
      (res) => {
        let fileName = this.fileData.reportId + '_' + this.reportInputData.name;
        this.sharedService.savePdfFile(res, fileName);
        this.showLoader = false;
      }
    );
  }

  createReportExcel(): void {
    this.showLoader = true;
    let outputString = JSON.stringify(this.reportOutputData);
    this.CustomerDetailService.createReportExcelBlob(outputString, this.fileData.reportId).subscribe(response => {
      this.showLoader = false;
      let fileName = this.fileData.reportId + '_' + this.reportInputData.name;
      this.sharedService.saveFile(response, fileName, AppConstants._excelExtention);
    });
  }

  createScenarioPdf(index:any): void {
    this.showLoader = true;
    let scenarioId = this.addScenarioValue[index].scenarioId;
    let reportId = this.fileData.reportId;
    this.CustomerDetailService.generateScenarioPDFBlob(reportId, scenarioId).subscribe(
      (res) => {
        let fileName = scenarioId + '_' + this.scenarioInputData[index].scenarioName;
        this.sharedService.saveFile(res, fileName, AppConstants._pdfExtention);
        this.showLoader = false;
      }
    );
  }

  createScenarioExcel(index:any): void {
    this.showLoader = true;
    let scenarioId = this.addScenarioValue[index].scenarioId;
    let reportId = this.fileData.reportId;
    let scenarioName = this.scenarioInputData[index].scenarioName;
    let outputString = JSON.stringify(this.addScenarioValue[index]["scenarioData"]);
    this.CustomerDetailService.createScenarioExcelBlob(outputString, reportId, scenarioId, scenarioName).subscribe(response => {
      this.showLoader = false;
      let fileName = scenarioId + '_' + scenarioName;
      this.sharedService.saveFile(response, fileName, AppConstants._excelExtention);
    });
  }

  editScenario(scenarioId: any, index:any): void {

    this.sharedService.editreport = 0;
    this.sharedService.editscenario = 1;
    this.addScenarioValue[index].showScenarioInput = true;
    this.scenarioInputData[index].reportId = this.fileData.reportId;
    this.scenarioInputData[index].scenarioId = scenarioId;
    this.sharedService.setData('editscenarioId', scenarioId);
    this.scenarioInputData[index].showStatusBlock = false;
  }

  validateGenerateReport() {

    // this.checkedScenarioIds = $('.report-list-scenario-check:checked')
    // .map(() => parseInt($(this).val()));
    // let sIds:any = [];
    this.checkedScenarioIds = Array.from(document.querySelectorAll('.report-list-scenario-check:checked'))
    .map((element:any) => {
      const value = parseInt(element.value, 10);
      // sIds.push(value);
      return value;
    });
    console.log(this.checkedScenarioIds)

    if ($('.report-list-scenario-check').filter(':checked').length > 0)
      return true;

    return false;
  }


  setLocalCurrencyMarket() {
    let market = this.markets.find((m:any) => m.Id == this.reportMarketId);
    this.reportMarketCurrency = market.Currency;
    localStorage.setItem('selectedCurrencyMarket', JSON.stringify(market));
    localStorage.setItem('reportMarketId', this.reportMarketId);
    localStorage.setItem('reportCurrencyValue', market.MarExcVal.toString());
    return market;
  }

  onCurrencyChange() {
    let market = this.setLocalCurrencyMarket();
    this.changeReportView(market.Symbol, market.MarExcVal);
  }

  changeReportView(currencySymbol:any, currencyValue:any) {

    if (this.reportOutputData.length > 0 && !this.isReportConversion) {
      this.reportOutputInUSD = JSON.parse(JSON.stringify(this.reportOutputData));
    }

    if (this.addScenarioValue.length > 0 && this.addScenarioValue.filter((as:any) => as['scenarioData'].length == 0).length == 0) {
      this.scenarioDataInUSD = JSON.parse(JSON.stringify(this.addScenarioValue));
    }

    if (this.reportMarketId != "2") {

      if (this.reportOutputInUSD && !this.isReportConversion) {
        let usdReportOutput = JSON.parse(JSON.stringify(this.reportOutputInUSD));
        this.reportOutputData = this.sharedService.convertReportOutputByCurrency(usdReportOutput, currencySymbol, currencyValue);
        this.isReportConversion = true;
      }

      if (this.scenarioDataInUSD && this.scenarioDataInUSD.length == this.addScenarioValue.length ) {
        let usdScenarioOutput = JSON.parse(JSON.stringify(this.scenarioDataInUSD))
        this.addScenarioValue = this.sharedService.convertScenarioOutputByCurrency(usdScenarioOutput, currencySymbol, currencyValue);
        this.scenarioInputData=  this.addScenarioValue;
      }
    }

    if (this.sharedService.analysisOutputData) {

      //Analysis Data
      this.sharedService.analysisOutputData.ReportOutput = JSON.parse(JSON.stringify(this.reportOutputData));

      if (this.sharedService.analysisOutputData.ScenarioOutput.length > 0 && this.addScenarioValue.length > 0) {
        for (let i = 0; i < this.sharedService.analysisOutputData.ScenarioOutput.length; i++) {
          this.sharedService.analysisOutputData.ScenarioOutput[i].data = JSON.parse(JSON.stringify(this.addScenarioValue[i].scenarioData));
        }
      }

      // Populate Generate Report Output Field Groups
      this.dataChangeService.emitLoadGenerateReportOutputFieldGroupdEvent(true);
    }
  }

  updateReportCurrency() {
    this.showLoader = true;
    let reportId = this.fileData.reportId;
    this.createReportService.updateReportCurrency(reportId, this.reportMarketId).subscribe(res => {
      this.showLoader = false;
      $("#saveCurrencyMsgModal").modal("show");
    });
  }

  ngOnDestroy() {

    clearInterval(this.id);

    for (let i = 0; i < this.addedScenarioIntervalIds.length; i++) {
      clearInterval(this.addedScenarioIntervalIds[i].intervalId);
    }

    if (this.reportSubscription != null) {
      this.reportSubscription.unsubscribe();
    }

    if (this.reportChangeSubscription != null) {
      this.reportChangeSubscription.unsubscribe();
    }

    if (this.addScenarioSubscription != null) {
      this.addScenarioSubscription.unsubscribe();
    }

    if (this.scenarioAddedSubscription != null) {
      this.scenarioAddedSubscription.unsubscribe();
    }

    if (this.cancelDataInputSubscription != null) {
      this.cancelDataInputSubscription.unsubscribe();
    }
  }
}
