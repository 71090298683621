import { CommonModule } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RecentCustomerService } from '../_services/recent-customer.service';
import { SharedService } from '../_services/shared-service.service';
import { PagerService } from '../_services/pager.service';
import { saveAs } from 'file-saver';
declare var $: any;
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  public currentTime = new Date();

  public data:any;
  public name:any;
  pagedItems: any;
  showLoader = false;
  constructor(private _RecentCustomerService: RecentCustomerService,
    public sharedService: SharedService,
    public router: Router,
    public pagerService: PagerService) {
  }

  ngOnInit() {

    if(localStorage.getItem('isadmin')!='1')
    {
      this.router.navigate(['/login']);
      return;
    }

    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({display:'Admin Dashboard',url:'/admindashboard'});
    let sCustomerId:any = localStorage.getItem('UmmUserId');
    let adminName:any=localStorage.getItem('adminFname');
    this.name = adminName;
    localStorage.setItem('fname',adminName);
    this.showLoader = true;
    this._RecentCustomerService.getRecentCustomer(sCustomerId).subscribe({
      next:(response:any)=>{
        this.data = response;
        //this.saveLogFile();
        this.data = this.data.report;
        this.data['result'] = '';
        this.pagedItems= this.data.slice(0, 10);
        this.showLoader = false;
        this.sharedService.adminDashbordData=this.data;
        console.log('AdminDashboardComponent', this.data);
      }, error:(err:any)=>{
        console.log(err)
      },complete:()=>{
        console.log('complted')
      }
    });
  }

  adminSearchClickfunction(): void {
    this.router.navigateByUrl('/adminsearch');
  }

  myreportClickfunction() {
    localStorage.setItem('pagecomingfrom', 'Search Customer');
    this.router.navigate(['./adminmyreports']);
  }

  saveLogFile(): void {
    saveAs(new Blob([this.data], { type: "text" }), 'data.log');
}

  pagedItemsChange(items:any) {
    this.pagedItems = items;
  }
}
