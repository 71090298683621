import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { CustomerDetailService } from '../_services/customer-detail.service';
import { DeleteReportService } from '../_services/deleteReport.service'
import { Router } from '@angular/router';
import { DataChangeService } from '../_services/data-change.service';
import { ReportListService } from '../_services/report-list.service';
import * as _ from 'underscore';
import { AppConstants } from '../appConstants';
import { HeaderService } from '../_services/header.service';

declare var $: any;

@Component({
  selector: 'app-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.css'],
})
export class ScenarioComponent implements OnInit {
  @Input() scenarioData: any = [];
  @Input() excelVersion: any;
  inputDataType: any = "scenario";
  deleteScenarioId: any;
  scenarioActionMessage = "Request For Scenario Creation Sent Successfully.";
  deleteConfirmationMessage = "Are you sure you want to delete the scenario ?";
  deleteSuccessMessage = "Scenario deleted successfully!";
  editDeleteScenarioAuthorizeMessage = "Only report owner is allowed to edit/delete the scenario.";
  updateScenarioSubscription: any;
  cancelDataInputSubscription: any;
  enteredScenarioIntervalIds :any= [];
  showLoader = false;

  excelVersionDyn: string = "";
  public r :any=localStorage.getItem('report')
  public report = this.sharedService.getReport() || JSON.parse(this.r);
  oldExcelVersionScenarioModalMessage = "The scenario is not allowed to edit due to old excel version.";
  reportOutputMissingModalMessage = "Report output is missing. Please generate report output first using edit report option.";
  constructor(public sharedService: SharedService,
    private CustomerDetailService: CustomerDetailService,
    private deleteReportService: DeleteReportService,
    private dataChangeService: DataChangeService,
    private reportListService: ReportListService,
    public router: Router,
    private headerService: HeaderService) {

      this.headerService.getCurrentExcelVersion().subscribe((version: string) => {
        this.excelVersionDyn = version;
      })

    this.updateScenarioSubscription = this.dataChangeService.getUpdateScenarioEmitter()
      .subscribe((item:any) => {
        console.log('getUpdateScenarioEmitter emmitted');
        this.scenarioActionMessage = item.status.toLowerCase() != 'draft' ? "Request For Scenario Creation Sent Successfully." : 'Scenario Saved as Draft';
        $('#scenarioMessageModal').modal({ backdrop: 'static', keyboard: false });
        $('#scenarioMessageModal').modal('show');

        let scenario = this.scenarioData.filter((s:any) => s.ScenarioInput.scenarioId == item.scenarioId)[0];
        scenario.status = item.status.toLowerCase() != 'draft' ? "In Progress" : 'Draft';
        scenario.showScenarioInput = false;
        scenario.showScenarioOutput = false;
        scenario.showStatusBlock = true;

        if (item.status.toLowerCase() != 'draft') {
          let scenarioIntervalId = setInterval(() => {
            this.generateScenario(item.reportId, item.scenarioId);
          }, 45000);

          this.enteredScenarioIntervalIds.push({ scenarioId: item.scenarioId, intervalId: scenarioIntervalId });
        }
      });

    this.cancelDataInputSubscription = this.dataChangeService.getCancelDataInputEmitter()
      .subscribe((item:any) => {
        if (item.scenarioId != null && item.type == 'scenario') {
          let cancelCenario = this.scenarioData.filter((s:any) => s.ScenarioInput.scenarioId == item.scenarioId)[0];
          cancelCenario.showScenarioInput = false;
          cancelCenario.showStatusBlock = true;
        } else if (item.reportId != null && item.scenarioId == null && item.type == 'scenario') {
          this.scenarioData.splice(item.index, 1);
        }
      });

    setTimeout(() => {

      console.log('Passed scenarioData in ScenarioComponent', this.scenarioData);

      for (let i = 0; i < this.scenarioData.length; i++) {
        this.scenarioData[i].ScenarioInput = this.sharedService.bindDataInput(this.scenarioData[i].ScenarioInput, 'scenario');
        this.scenarioData[i].ScenarioInput.name = this.scenarioData[i].ScenarioInput.scenarioName;
        this.scenarioData[i].ScenarioInput.description = this.scenarioData[i].ScenarioInput.scenarioDesc;
        this.scenarioData[i].showScenarioOutput = this.scenarioData[i].status.toLowerCase() != 'draft' ? true : false;
      }

      // Populate Generate Report Output Field Groups
      this.dataChangeService.emitLoadGenerateReportOutputFieldGroupdEvent(true);
    }, 10000);

    // Init Excel Mapping Data if not initiated till now
    if (_.isUndefined(this.sharedService.excelMappingData) || this.sharedService.excelVersion != this.excelVersion) {
      this.sharedService.initExcelMappingsData(this.excelVersion);
    }
  }

  ngOnInit() {
  }


  onKeyUpScenarioName(name:any, index:any) {
    let isnum = /^\d+$/.test(name);
    this.scenarioData[index].isnum = isnum;
    if (isnum) {
      $('#scenarioName' + index).css("border", "red solid 1px");
    }
    else {
      $('#scenarioName' + index).css("border", "");
    }
  }


  generateScenario(reportId:any, scenarioId:any) {
    let that = this;
    
    this.reportListService.generateScenarioOutput(reportId, scenarioId).subscribe((response) => {
      let scenarioOutput: any = response;

      if (!_.isEmpty(scenarioOutput)) {
        let scenarioOutputBody = scenarioOutput;
        let scenarioInputBody = this.scenarioData.filter((s:any) => s.ScenarioInput.scenarioId == scenarioId)[0];
        let scenarioResponseData = this.sharedService.mapExcelOutputData(false, scenarioInputBody['ScenarioInput'], scenarioOutputBody)

        console.log('Scenario response data', scenarioResponseData);
        if (scenarioResponseData.length == 0) {
          console.log('Trying again for latest changed scenario data..');
        }
        else {

          console.log('Scenario response data', scenarioResponseData);
          let existingInterval: any = this.enteredScenarioIntervalIds.filter((i:any) => i.scenarioId == scenarioId)[0];
          if (existingInterval != null) {
            clearInterval(existingInterval.intervalId);
            this.enteredScenarioIntervalIds = this.enteredScenarioIntervalIds.filter((i:any) => i.scenarioId != scenarioId)
          }

          let updatedScenario = this.scenarioData.filter((s:any) => s.ScenarioInput.scenarioId == scenarioId)[0];

          scenarioResponseData = that.sharedService.selectionBasedIndexOutputChange(scenarioResponseData, false, updatedScenario.ScenarioInput.InputCheckBoxId);

          if (updatedScenario != null) {
            updatedScenario.ScenarioOutput = scenarioResponseData;
            updatedScenario.status = "Completed";
            updatedScenario.showScenarioInput = false;
            updatedScenario.showScenarioOutput = true;
            updatedScenario['IsConversion'] = false;
          }

          let analysisScenarioOutput = {
            data: scenarioResponseData,
            scenarioId: scenarioId,
            scenarioName: updatedScenario.ScenarioInput.scenarioName,
            scenarioDesc: updatedScenario.ScenarioInput.scenarioDesc,
            showScenarioOutput: false
          };
          if (this.sharedService.analysisOutputData.ScenarioOutput.filter((s:any) => s.scenarioId == scenarioId).length == 0) {
            this.sharedService.analysisOutputData.ScenarioOutput.push(analysisScenarioOutput);
          } else {
            this.sharedService.analysisOutputData.ScenarioOutput.filter((s:any) => s.scenarioId == scenarioId)[0] = analysisScenarioOutput;
          }


          this.dataChangeService.emitGenerateScenarioEvent(this.scenarioData);
        }

      }
    });

  }

  createScenarioPdf(scenarioId:any, scenarioName:any): void {
    this.showLoader = true;
    let r :any=localStorage.getItem('report');
    let report = this.sharedService.getReport() || JSON.parse(r)
    let reportId = report.reportId;
    this.CustomerDetailService.generateScenarioPDFBlob(reportId, scenarioId).subscribe(
      (res:any) => {
        let fileName = scenarioId + '_' + scenarioName;
        this.sharedService.saveFile(res, fileName, AppConstants._pdfExtention);
        this.showLoader = false;
      }
    );
  }

  createScenarioExcel(scenarioId:any, scenarioName:any): void {
    debugger

    if (this.report.IsReportCreated == 0) {
      $('#reportOutputMissingModal').modal("show");
      return;
    }

    this.showLoader = true;
    let r:any=localStorage.getItem('report')
    let report = this.sharedService.getReport() || JSON.parse(r)
    let reportId = report.reportId;
    let scenarioOutput = this.scenarioData.filter((s:any) => s.ScenarioInput.scenarioId == scenarioId).map((s:any) => s.ScenarioOutput)[0];
    let outputString = JSON.stringify(scenarioOutput).replace(/-Report/gi, '_Minus_Report');
    this.CustomerDetailService.createScenarioExcelBlob(outputString, reportId, scenarioId, scenarioName).subscribe(response => {
      this.showLoader = false;
      let fileName = scenarioId + '_' + scenarioName;
      this.sharedService.saveFile(response, fileName, AppConstants._excelExtention);
    });
  }

  editScenario(scenarioInput: any, index:any): void {
    let r:any =localStorage.getItem('report');
    this.report = this.sharedService.getReport() || JSON.parse(r);
    if (this.report.IsReportCreated == 0) {
      $('#reportOutputMissingModal').modal("show");
      return;
    }

    let currentExcelVersion = this.excelVersionDyn;
    if (this.report.excelVersion != currentExcelVersion) {
      $("#oldExcelVersionScenarioModal").modal("show");
      return;
    }

    if (scenarioInput.ummUserId == localStorage.getItem('UmmUserId')) {
      //Refresh the data-input property binding
      this.scenarioData[index].ScenarioInput = this.sharedService.bindDataInput(scenarioInput, 'scenario');
      this.sharedService.editreport = 0;
      this.sharedService.editscenario = 1;
      this.scenarioData[index].showScenarioInput = true;
      this.scenarioData[index].showStatusBlock = false;
      this.sharedService.setData('editscenarioId', scenarioInput.scenarioId);
    } else {
      $("#authorizeScenarioEditDeleteModal").modal("show");
    }
  }

  deleteScenario(scenarioInput: any) {
    if (scenarioInput.ummUserId == localStorage.getItem('UmmUserId')) {
      this.deleteScenarioId = scenarioInput.scenarioId;
      $("#confirmScenarioDeleteModal").modal("show");
    } else {
      $("#authorizeScenarioEditDeleteModal").modal("show");
    }
  }

  deleteScenarioConfirm() {
    let type = 'scenario';
    let reportId = 0;
    let result = this.deleteReportService.deleteReport(reportId, this.deleteScenarioId, type);
    result.subscribe({next:() => {
      $('#deleteScenarioSuccessModal').modal("show");
    }
  });
  }

  clickDeleteSuccess() {
    this.scenarioData = this.scenarioData.filter((s:any) => s.ScenarioInput.scenarioId != this.deleteScenarioId);
    this.dataChangeService.emitScenarioDeleteEvent({ scenarioData: this.scenarioData });
  }

  scenarioInputSelectionChange(objInputSelection:any, index:any) {
    this.scenarioData[index].defaultAdditionalInputSelections = objInputSelection;
  }

  ngOnDestroy() {

    for (let i = 0; i < this.enteredScenarioIntervalIds.length; i++) {
      clearInterval(this.enteredScenarioIntervalIds[i].intervalId);
    }

    if (this.updateScenarioSubscription != null) {
      this.updateScenarioSubscription.unsubscribe();
    }

    if (this.cancelDataInputSubscription != null) {
      this.cancelDataInputSubscription.unsubscribe();
    }
  }
}
