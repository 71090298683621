import {EventEmitter} from '@angular/core';
export class DataChangeService {
  reportChange: EventEmitter<number> = new EventEmitter();
  reportOutputChange: EventEmitter<number> = new EventEmitter();
  updateReport: EventEmitter<number> = new EventEmitter();
  updateScenario: EventEmitter<number> = new EventEmitter();
  addScenario: EventEmitter<number> = new EventEmitter();
  scenarioAdded: EventEmitter<number> = new EventEmitter();
  generateScenario: EventEmitter<number> = new EventEmitter();
  businessNameTitleChange: EventEmitter<number> = new EventEmitter();
  scenarioDelete: EventEmitter<number> = new EventEmitter();
  cancelDataInput: EventEmitter<number> = new EventEmitter();
  newReportAdded: EventEmitter<number> = new EventEmitter();
  resetSelectCustomer: EventEmitter<boolean> = new EventEmitter();
  loadGenerateReportOutputFieldGroupdEvent: EventEmitter<boolean> = new EventEmitter();
  defaultAdditionalInputEvent: EventEmitter<boolean> = new EventEmitter();
  pagerReloadEvent: EventEmitter<boolean> = new EventEmitter();
  currencySymbolChangeEvent: EventEmitter<boolean> = new EventEmitter();
  
  constructor() {
  }

  emitReportChangeEvent(event:any) {
    this.reportChange.emit(event);
  }
  
  getReportChangeEmitter() {
    return this.reportChange;
  }

  emitReportOutputChangeEvent(event:any) {
    this.reportOutputChange.emit(event);
  }
  
  getReportOutputChangeEmitter() {
    return this.reportOutputChange;
  }

  emitUpdateReportEvent(event:any) {
    this.updateReport.emit(event);
  }

  getUpdateReportEmitter() {
    return this.updateReport;
  }

  emitUpdateScenarioEvent(event:any) {
    this.updateScenario.emit(event);
  }

  getUpdateScenarioEmitter() {
    return this.updateScenario;
  }

  emitAddScenarioEvent(event:any) {
    this.addScenario.emit(event);
  }

  getAddScenarioEmitter() {
    return this.addScenario;
  }

  emitScenarioAddedEvent(event:any) {
    this.scenarioAdded.emit(event);
  }
  getScenarioAddedEmitter() {
    return this.scenarioAdded;
  }

  emitGenerateScenarioEvent(event:any) {
    this.generateScenario.emit(event);
  }
  getGenerateScenarioEvent() {
    return this.generateScenario;
  }

  emitBusinessNameTitleChangeEvent(event:any) {
    this.businessNameTitleChange.emit(event);
  }

  getBusinessNameTitleChangeEmitter() {
    return this.businessNameTitleChange;
  }

  emitScenarioDeleteEvent(event:any) {
    this.scenarioDelete.emit(event);
  }

  getScenarioDeleteEmitter() {
    return this.scenarioDelete;
  }

  emitCancelDataInputEvent(event:any) {
    this.cancelDataInput.emit(event);
  }

  getCancelDataInputEmitter() {
    return this.cancelDataInput;
  }

  emitNewReportAddedEvent(event:any) {
    this.newReportAdded.emit(event);
  }

  getNewReportAddedEmitter() {
    return this.newReportAdded;
  }

  emitResetSelectCustomerEvent(event:any) {
    this.resetSelectCustomer.emit(event);
  }

  getResetSelectCustomerEmitter() {
    return this.resetSelectCustomer;
  }

  emitLoadGenerateReportOutputFieldGroupdEvent(event:any) {
    this.loadGenerateReportOutputFieldGroupdEvent.emit(event);
  }

  getLoadGenerateReportOutputFieldGroupdEvent() {
    return this.loadGenerateReportOutputFieldGroupdEvent;
  }

  emitDefaultAdditionalInputEvent(event:any) {
    this.defaultAdditionalInputEvent.emit(event);
  }

  getDefaultAdditionalInputEvent() {
    return this.defaultAdditionalInputEvent;
  }

  emitPagerReloadEvent(event:any) {
    this.pagerReloadEvent.emit(event);
  }

  getPagerReloadEvent() {
    return this.pagerReloadEvent;
  }

  emitCurrencySymbolChangeEvent(event:any) {
    this.currencySymbolChangeEvent.emit(event);
  }

  getCurrencySymbolChangeEvent() {
    return this.currencySymbolChangeEvent;
  }

}