import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchCustomerService } from '../_services/search-customer.service';
import { SharedService } from '../_services/shared-service.service';
import { CustomerDetailService } from '../_services/customer-detail.service';
// import 'rxjs/add/operator/map';
import { CustomerService } from '../_services/customer.service';
import { MarketSetupService } from '../_services/market-setup.service';

declare var $: any;
@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.css']
})
export class SearchCustomerComponent implements OnInit {
  public currentTime = new Date();
  public customerLists: any = [];
  public globalCustomerLists: any = [];
  public portalCustomerLists: any = [];
  public sNarcId: any = '';
  public sBusinessName: any = '';
  public sCity: any = '';
  public sState: any = '';
  public sZipCode: any = '';
  public sMarketCode: any = '';
  public vReports: any = [];
  public vReportslength: any;
  public vData: any = {};
  public customer: any;
  public date: any;
  ClToken = sessionStorage.getItem("Token");
  public isadmin = localStorage.getItem('isadmin');
  showLoader = false;
  userMarketIds = [];
  markets = [];
  userMarkets = [];
  constructor(public router: Router, http: HttpClient,
    public _searchCustomerService: SearchCustomerService,
    public sharedService: SharedService,
    public CustomerDetailService: CustomerDetailService,
    public customerService: CustomerService,
    public marketSetupService: MarketSetupService) {
    // for first load all customer
    this.date = this.sharedService.getData('date');
  }

  ngOnInit() {
    let mid: any = localStorage.getItem('MarketId')
    this.userMarketIds = mid.split(',');
    if (localStorage.getItem('isadmin') == '1') {
      let smid: any = localStorage.getItem('SelectedUserMarketIds')
      this.userMarketIds = smid.split(',');
    }
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Search Customer', url: '/searchcustomer' });
    this.getAllMarkets();
  }

  getAllMarkets() {
    let that: any = this;
    this.showLoader = true;
    this.marketSetupService.getAllMarkets().subscribe((res: any) => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.markets = responseData.map((r: any) => r);
      this.userMarkets = this.markets.filter((m: any) => that.userMarketIds.indexOf(m.Id.toString()) != -1);
      this.loadCustomers();
    });
  }

  loadCustomers() {
    this.customerLists = [];
    this.getGlobalCustomers();
    this.getPortalCustomers();
  }

  getGlobalCustomers() {
    this.showLoader = true;
    let that = this;
    this._searchCustomerService.getSearchCustomer(this.sBusinessName, this.sCity, this.sState, this.sZipCode, this.sNarcId).subscribe((response: any) => {
      this.globalCustomerLists = response.ResponseData.customers;
      this.globalCustomerLists = this.globalCustomerLists.filter((gc: any) => gc.customerName != null);
      let userMarketCodes = that.userMarkets.map((um: any) => um.CountryCode.toLowerCase());
      this.globalCustomerLists = this.globalCustomerLists.filter((gc: any) => userMarketCodes.indexOf(gc.markets[0].marketCode.toLowerCase()) != -1);
      this.customerLists = this.customerLists.concat(
        this.globalCustomerLists.map(function (gc: any) {
          // console.log(gc)
          return {
            customerId: null,
            globalCustomerId: gc.customerId,
            addressLine1: gc.street1,
            businessName: gc.customerName,
            city: gc.city,
            postalCode: gc.zipCode,
            stateProvince: gc.state,
            narcId: gc.narcId,
            marketCode: gc.markets[0].marketCode,
            mSource: "Global",
            marketId: null,
            marketSource: 0,
          }
        }));
      that.customerLists = that.customerLists.filter((c: any) => c.marketCode && (c.marketCode.toLowerCase().indexOf(that.sMarketCode.toLowerCase()) != -1));
      this.showLoader = false;
    });

  }

  getPortalCustomers() {
    this.showLoader = true;
    let that: any = this;
    this.customerService.getAllCustomers().subscribe(res => {
      let response: any = res;
      this.portalCustomerLists = response.ResponseData;
      this.portalCustomerLists = this.portalCustomerLists.filter((pc: any) => pc.businessName != null);
      this.portalCustomerLists = this.portalCustomerLists.filter((pc: any) => that.userMarketIds.indexOf(pc.MarketId.toString()) != -1);
      if (this.sBusinessName != '' && this.sBusinessName != undefined) {
        this.portalCustomerLists = this.portalCustomerLists.filter((pc: any) => (pc.businessName.toLowerCase().trim().indexOf(this.sBusinessName.toLowerCase().trim()) != -1))
      }
      if (this.sCity != '' && this.sCity != undefined) {
        this.portalCustomerLists = this.portalCustomerLists.filter((pc: any) => pc.city && (pc.city.toLowerCase().trim().indexOf(this.sCity.toLowerCase().trim()) != -1))
      }
      if (this.sState != '' && this.sState != undefined) {
        this.portalCustomerLists = this.portalCustomerLists.filter((pc: any) => pc.stateProvince && (pc.stateProvince.toLowerCase().trim().indexOf(this.sState.toLowerCase().trim()) != -1))
      }
      if (this.sZipCode != '' && this.sZipCode != undefined) {
        this.portalCustomerLists = this.portalCustomerLists.filter((pc: any) => pc.postalCode && (pc.postalCode.indexOf(this.sZipCode) != -1))
      }
      this.customerLists = this.customerLists.concat(
        this.portalCustomerLists.map(function (pc: any) {
          return {
            customerId: pc.custId,
            globalCustomerId: pc.NgpCustomerId,
            addressLine1: pc.addressLine1,
            businessName: pc.businessName,
            city: pc.city,
            postalCode: pc.postalCode,
            stateProvince: pc.stateProvince,
            narcId: pc.narcId,
            marketCode: that.markets.find((m: any) => m.Id == pc.MarketId).CountryCode,
            mSource: pc.MSource,
            marketId: pc.MarketId,
            marketSource: pc.MarketSource,
          }
        })
      );
      that.customerLists = that.customerLists.map(function (c: any) {
        if (c.marketCode) {
          c.marketCode = c.marketCode.toUpperCase();
        }
        return c;
      });

      that.customerLists = that.customerLists.filter((c: any) => c.marketCode && (c.marketCode.toLowerCase().indexOf(that.sMarketCode.toLowerCase()) != -1));
      let selectedIds = that.customerLists.map((c: any) => c.globalCustomerId);
      selectedIds = selectedIds.filter((c: any) => c != null)
      let counts: any = {};
      selectedIds.forEach((x: any) => {
        counts[x] = (counts[x] || 0) + 1;
      });

      that.customerLists = that.customerLists.filter((c: any) => {
        if (c.customerId == null && c.globalCustomerId != null) {
          if (counts[c.globalCustomerId] > 1) {
            return false
          }
          return true
        }
        return true
      }
      )

      console.log(that.customerLists)
      this.showLoader = false;
    });
  }

  getNumberOfReports(pcustomer: any) {
    if (pcustomer.vReportslength != null)
      return;

    this.customer = pcustomer || null;
    //this.CustomerDetailService.getCustomerReportCount(this.customer.mdmAddressId).subscribe(response => {
    if (this.customer.customerId) {
      this.CustomerDetailService.getCustomerReportCount(this.customer.customerId).subscribe((response: any) => {
        let data = response;
        console.log(pcustomer.businessName);
        localStorage.setItem("businessName", pcustomer.businessName);
        if (data != null) {
          pcustomer.vReportslength = data.totalreportCount;
        }
      });
    } else {
      pcustomer.vReportslength = 0;
    }

  }

  createreport(customer: any) {
    sessionStorage.setItem("mdmAddressId", customer.mdmAddressId);
    localStorage.setItem("businessName", customer.businessName);
    this.sharedService.businessNameTitle = customer.businessName;
    localStorage.setItem('businessNameTitle', customer.businessName);
    console.log(customer.mdmAddressId + " " + customer.businessName);

    this.sharedService.setCustomer(customer);
    localStorage.setItem('customer', JSON.stringify(customer));

    this.router.navigate(['./createreport']);

  }

  viewDetail(customer: any) {
    localStorage.setItem('customer', JSON.stringify(customer));
    this.sharedService.setCustomer(customer);

    this.sharedService.businessNameTitle = customer.businessName;
    localStorage.setItem('businessNameTitle', customer.businessName);
    localStorage.setItem('pagecomingfrom', 'Search Customer');
    this.router.navigate(['./customerdetails']);
  }

  onSearchFilter() {
    this.loadCustomers();
  }
}
