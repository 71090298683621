// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register {
    text-align: left;
    padding-left: 35px;
    padding-bottom: 20px;
    cursor: pointer;
}

.register a {
    color: #fe6923 !important;
    font-weight: bold !important;
    margin-left: 8px !important;
    font-size: 14px;
    cursor: pointer;
}

.newuser {
    color: #000;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,2BAA2B;IAC3B,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".register {\r\n    text-align: left;\r\n    padding-left: 35px;\r\n    padding-bottom: 20px;\r\n    cursor: pointer;\r\n}\r\n\r\n.register a {\r\n    color: #fe6923 !important;\r\n    font-weight: bold !important;\r\n    margin-left: 8px !important;\r\n    font-size: 14px;\r\n    cursor: pointer;\r\n}\r\n\r\n.newuser {\r\n    color: #000;\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
