import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../_services/shared-service.service';
import * as _ from 'underscore';
import { DataChangeService } from '../_services/data-change.service';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.css']
})
export class CreateReportComponent implements OnInit {

  public currentTime = new Date();

  inputData: any = this.sharedService.getDefaultInputData();
  inputDataType: any = "report";
  defaultAdditionalInputEventSubscription: any;
  defaultAdditionalInputSelections: any = this.sharedService.defaultAdditionalInputSelections.map(x => Object.assign({}, x));
  constructor(public sharedService: SharedService,
    private dataChangeService: DataChangeService) {

    this.defaultAdditionalInputEventSubscription = this.dataChangeService.getDefaultAdditionalInputEvent()
      .subscribe(item => {
        this.inputData = this.sharedService.getDefaultInputData();
      });


    // Init default additional input data if not initiated till now
    if (_.isUndefined(this.sharedService.defaultAdditionalValues) ||
      _.isUndefined(this.sharedService.defaultAdditionalInputSelectedValues)) {
      this.sharedService.initDefaultAdditionalInputData();
    }
  }

  ngOnInit() {
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Create Report', url: '/createreport' });
    this.sharedService.report = null;
    localStorage.setItem('report', '');
  }

  ngOnDestroy() {
    if (this.defaultAdditionalInputEventSubscription != null) {
      this.defaultAdditionalInputEventSubscription.unsubscribe();
    }
  }
}

