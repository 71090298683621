import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { HeaderService } from '../_services/header.service';
import { Observable } from 'rxjs/internal/Observable';

declare var $: any;
@Injectable()
export class RecentCustomerService {
  ClToken = sessionStorage.getItem("Token");
  UserEmail = localStorage.getItem("email");
  refEmployeeId = '';
  constructor(private _http: HttpClient,
    public headerService: HeaderService) {
  }

  getRecentCustomer(sCustomerId?: any): Observable<any[]> {
    let _recentCustomerServiceurl: string = AppSettings._recentCustomerServiceurl + '?userId=' + sCustomerId;
    console.log('url:', _recentCustomerServiceurl);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    console.log(options)
    return this._http.get<any[]>(_recentCustomerServiceurl, {headers:options});
  }
}

