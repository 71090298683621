import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserRoles } from '../_enums/user-role';
import { DataChangeService } from '../_services/data-change.service';
import { MarketSetupService } from '../_services/market-setup.service';
import { SharedService } from '../_services/shared-service.service';
import { UserService } from '../_services/user.service';
declare var $: any;

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
  countryWithCurrencyList:any = [];
  markets:any = [];
  activeMarkets:any = [];
  userRoles :any= [];
  invitationRoles =
    [
      { Id: 2, Name: "User" },
      { Id: 3, Name: "Admin" }
    ];
  user: any = {};
  users:any = [];
  allUsers:any = [];
  showLoader = false;
  pagedItems: any;
  selectedMarketId = null;
  validationMessage = "";
  btnText = "Invite";
  reinviteBtnText = "Re-Invite"
  dropdownList:any = [];
  dropdownSettings: IDropdownSettings = {};
  selectedMarkets:any = [];
  searchUserEmail = '';
  constructor(private sharedService: SharedService,
    private _router: Router,
    private userService: UserService,
    private marketSetupService: MarketSetupService,
    public dataChangeService: DataChangeService) { }

  ngOnInit() {
    let that = this;
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Manage Users', url: '/manage-users' });
    this.resetUser();
    this.getAllCountriesWithCurrency();
    Object.keys(UserRoles).filter((key:any) => !isNaN(Number(key))).forEach(function (key:any) {
      that.userRoles.push({ Id: key, Name: UserRoles[key] });
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Market',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      searchPlaceholderText: 'Search Market',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
  }

  getAllCountriesWithCurrency() {
    this.showLoader = true;
    this.marketSetupService.getAllCountriesWithCurrency().subscribe(res => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.countryWithCurrencyList = responseData;
      this.getAllMarkets();
    });
  }


  getAllMarkets() {
    let that = this;
    this.showLoader = true;
    this.marketSetupService.getAllMarkets().subscribe(res => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.markets = responseData.map((r:any) => r);
      this.markets = this.markets.map(function (m:any) {

        let objCc = that.countryWithCurrencyList.filter((cc:any) => cc.Id == m.MarId)[0];

        if (objCc) {
          m.Market = objCc.Country;
        }

        return m;
      })
      this.activeMarkets = this.markets.filter((m:any) => m.IsActive);
      this.getAllUsers();
    });
  }

  getMarketNameById(marketId:any) {
    let mkts: any = this.markets.filter((m:any) => marketId.split(',').indexOf(m.Id.toString()) != -1)
    let marketNames = "";
    if (mkts.length > 0) {
      marketNames = mkts.map((m:any) => m.Market).join(',');
    }
    return marketNames;
  }

  getRoleNameById(roleId:any) {

    if (roleId == 2 || roleId == 3) {
      return 'Invited';
    }

    let r: any = this.userRoles.filter((r:any) => r.Id == roleId);
    let roleName = "";
    if (r.length > 0) {
      roleName = r[0].Name;
    }
    return roleName;
  }

  getAllUsers() {
    let that = this;
    let postModel =
    {
      Page: 1,
    }
    this.showLoader = true;
    this.userService.getAllUsers(postModel).subscribe(res => {
      let response: any = res;
      let responseData =response.ResponseData;
      this.users = responseData.map((r:any) => r);
      this.users = this.users.sort((a:any, b:any) => (a.userId > b.userId ? -1 : 1))
      this.users = this.users.map(function (u:any) {
        u.marketName = that.getMarketNameById(u.MarketId);
        u.actionButtonText = (u.userRole == 2 || u.userRole == 3) ? 'Re-Invite' : 'Edit User'
        u.roleName = that.getRoleNameById(u.userRole);
        return u;
      });
      this.allUsers = this.users;
      this.pagedItems = this.users.slice(0, 10);
      this.showLoader = false;
      this.resetUser();
    });
  }

  resetUser() {
    this.btnText = "Invite";
    this.user =
    {
      Id: 0,
      MarketId: 0,
      FirstName: "",
      LastName: "",
      Email: "",
      UserRole: 0,
      IsActive: true,
      IsReInvite: false
    };
  }

  validateMandatoryFields() {

    if (
      this.selectedMarkets.length == 0
      || this.user.UserRole == 0
      || this.user.FirstName.trim() == ""
      || this.user.LastName.trim() == ""
      || this.user.Email.trim() == "") {
      return false;
    }

    return true;
  }

  validateUser() {
    
    let userAlreadyExist = this.users.filter((u:any) => u.emailId.trim() == this.user.Email.trim()).length > 0;

    if (userAlreadyExist) {
      this.validationMessage = "This user is already invited.Please re-invite from the users list.";
      $("#userValidationModal").modal("show");
      return false;
    }

    return true;
  }

  inviteUser() {
    if(!this.selectedMarkets || !this.user.UserRole || !this.user.FirstName || !this.user.LastName || !this.user.Email) {
      return;
    }
    if (!this.user.IsReInvite && this.btnText != 'Update') {
      let isValidUser = this.validateUser();
      if (!isValidUser) {
        return;
      }
    }
    this.showLoader = true;
    this.user.MarketId = this.selectedMarkets.map((s:any) => s.Id).join(',');
    this.userService.saveUser(this.user).subscribe({
      next:(res:any) =>{
      this.showLoader = false;
      this.getAllUsers();
    }});
  }

  reInviteOrEditUser(user:any) {
    let that = this;
    this.btnText = (user.userRole == 2 || user.userRole == 3) ? "Re-Invite" : "Update";
    let isReinvite = (user.userRole == 2 || user.userRole == 3) ? true : false;
    //user.IsReInvite = true;

    this.selectedMarkets = user.MarketId.split(',').map(function (mId:any) {
      let marketName = that.getMarketNameById(mId);
      return { Id: parseInt(mId), Market: marketName };
    });

    this.user =
    {
      Id: user.userId,
      MarketId: user.MarketId,
      FirstName: user.firstName,
      LastName: user.lastName,
      Email: user.emailId,
      UserRole: user.userRole,
      IsActive: true,
      IsReInvite: isReinvite
    };
  }

  onChangeStatus(status:any, user:any) {
    this.showLoader = true;

    let objUser =
    {
      "Id": user.userId,
      "MarketId": user.MarketId,
      "FirstName": user.firstName,
      "LastName": user.lastName,
      "Email": user.emailId,
      "UserRole": user.userRole,
      "IsActive": status
    };
    this.userService.saveUser(objUser).subscribe({
      next:(res:any)=>{
        this.getAllUsers();
      },error:(err:any)=>{
        console.log(err)
      }});
  }

  pagedItemsChange(items:any) {
    this.pagedItems = items;
  }

  filterUsers() {
    this.users = this.selectedMarketId != null ? this.allUsers.filter((u:any) => u.MarketId.split(',').indexOf(this.selectedMarketId)!= - 1) : this.allUsers;
    this.users = this.searchUserEmail != '' ? this.users.filter((x:any) => x.emailId.toLowerCase().startsWith(this.searchUserEmail.toLowerCase().trim())) : this.users;
    this.dataChangeService.emitPagerReloadEvent(this.users);
  }

  onFilterChange(evt:any){}
  onDropDownClose(evt:any){}
  onSelectAll(evt:any){}
  onDeSelect(evt:any){}
  onDeSelectAll(evt:any){}
  onItemSelect(evt:any){}
}
