import { Component, OnInit, Input } from '@angular/core';
import { CustomerListService } from '../_services/customer-list.service';
import { SharedService } from '../_services/shared-service.service';
import { DataChangeService } from '../_services/data-change.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.css']
})

export class SelectCustomerComponent implements OnInit {
  @Input() id: any;
  allCustomer: any = [];
  selectedcustomer: any;
  public intervalID:any;
  comparestring = "Selected Customer";
  isvalid: boolean = false;
  newReportSubscription: any;
  resetSelectCustomerSubscription: any;
  intervalId: any;

  customer: any;
  filteredCustomers: any = [];

  constructor(private customerListService: CustomerListService,
    private sharedService: SharedService,
    private dataChangeService: DataChangeService,
    private router: Router) {

    this.newReportSubscription = this.dataChangeService.getNewReportAddedEmitter()
      .subscribe(item => {
        console.log('customer reload after report creation..');
        this.loadCustomerList();
      });

    this.resetSelectCustomerSubscription = this.dataChangeService.getResetSelectCustomerEmitter()
      .subscribe((item:any) => {
        console.log(item)
        this.selectedcustomer = null;
      });
  }

  ngOnInit() {
console.log('SelectCustomerComponent')
    this.intervalId = setInterval(() => {
      var Tkn = sessionStorage.getItem("Token");

      let ummUserId = localStorage.getItem("UmmUserId");
      if (localStorage.getItem('isadmin') == '1') {
        ummUserId = localStorage.getItem('SelectedUmmUserId');
      }

      if (Tkn != null && ummUserId != null) {
        this.loadCustomerList();
      }
    }, 1000);
  }

  loadCustomerList() {
    clearInterval(this.intervalId);
    this.customerListService.getCustomerListByUserId().subscribe({
      next:(response:any) =>{
        if (response.result != "No record found") {
          let customers = response;
          this.allCustomer = this.filterCustomers(customers);
          this.selectedcustomer = null;
          this.customer = null;
        }
      }, error:(err:any)=>{
        console.log(err)
      }, complete:()=> {
        console.log('completed')
      },
    });
  }

  private filterCustomers(customers: any) {
    let filteredCustomers = customers.filter(this.checkForCustomerBusinessName);
    filteredCustomers = this.concatenateCityNdStateWithBusinessName(filteredCustomers);
    return filteredCustomers;
  }

  private checkForCustomerBusinessName(customer: any) {
    if(customer && customer.businessName != null && customer.businessName.trim().length != 0) {
      return true;
    }
    return false;
  }

  private concatenateCityNdStateWithBusinessName(customers: any) {
    customers.forEach((customer: any) => {
      let concatenatedOption = customer.businessName;
      if(customer.city && customer.city.length != 0) {
        concatenatedOption += ", " + customer.city;
      }

      if(customer.stateProvince && customer.stateProvince.length != 0) {
        concatenatedOption += ", " + customer.stateProvince;
      }

      if(customer.custId && customer.custId.length != 0) {
        concatenatedOption += ", " + customer.custId;
      }
      customer.optionToDisplay = concatenatedOption;
    });
    return customers;
  }

  validatecustomer() {
    if (this.customer === 'Select Customer') this.isvalid = false;
    else if (this.customer === '') this.isvalid = false;
    else this.isvalid = true;
  }

  createReportContinue() {
    this.setSelectedCustomer();
    console.log(this.selectedcustomer)
    this.sharedService.businessNameTitle = this.selectedcustomer.businessName;
    localStorage.setItem('businessNameTitle', this.selectedcustomer.businessName);

    localStorage.setItem('customer', JSON.stringify(this.selectedcustomer));
    this.sharedService.setCustomer(this.selectedcustomer);

    this.sharedService.report = null;
    localStorage.setItem('report', '');

    this.dataChangeService.emitBusinessNameTitleChangeEvent({ businessNameTitle: this.selectedcustomer.businessName });

    this.router.navigate(['./createreport']);

  }

  private setSelectedCustomer() {
    console.log(this.customer)
    const splits: any[] = this.customer.split(",");
    const customerId = splits[splits.length-1];
    console.log(this.allCustomer)
    console.log(this.selectedcustomer)
    this.allCustomer.forEach((cust: any) => {
      if(customerId == cust.custId) {
        this.selectedcustomer = cust;
      }
    })
  }

  ngOnDestroy() {
    if (this.newReportSubscription != null) {
      this.newReportSubscription.unsubscribe();
    }

    if (this.resetSelectCustomerSubscription != null) {
      this.resetSelectCustomerSubscription.unsubscribe();
    }
  }
}
