import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../_services/shared-service.service';
import { DeleteReportService } from '../_services/deleteReport.service';
import { AppSettings } from '../appSettings';
import { RecentCustomerService } from '../_services/recent-customer.service';
import { CreateReportService } from '../_services';
import { filter } from 'underscore';
import { from } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-admin-my-reports',
  templateUrl: './admin-my-reports.component.html',
  styleUrls: ['./admin-my-reports.component.css']
})
export class AdminMyReportsComponent implements OnInit {
  public currentTime = new Date();
  public isadmin = localStorage.getItem('isadmin');

  public vCustomerData: any;
  public vScenarioCount: any;
  public vreportCount: number = 0;
  public vData: any;
  public slength: any;
  public vReports: any;
  public vScenario: any;
  public vReportData: any;
  public pdfUrl: any;
  public excelUrl: any;
  public vCustomer: any;
  public date: any;
  public pagecomingfrom = localStorage.getItem('pagecomingfrom');

  public sreportName: any;
  public sbusinessName: any;
  public sgeneratedby: any;
  public sfromDate: any;
  p: any;
  public todayDate = new Date();
  public stoDate: any;// = { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } };
  reports: any;
  ummUserID: string = '';
  // datePickerOptions: any = {
  //   displayFormat: 'MM/DD/YYYY',
  //   maxDate: new Date(Date.now()),
  // };

  deleteReportId: any;
  deleteConfirmationMessage = this.sharedService.deleteConfirmationMessage;
  deleteSuccessMessage = this.sharedService.deleteSuccessMessage;
  showLoader = false;

  collectionSize: number = 0;
  page = 1;
  pageSize = 5;
  toDay:any=new Date();

  constructor(
    public router: Router,
    public sharedService: SharedService,
    private deleteReportService: DeleteReportService,
    private recentCustomerService: RecentCustomerService,
    private createReportService: CreateReportService) {
    this.date = this.sharedService.getData('date');
  }

  public advacedSearchActive = false;

  ngOnInit() {
    let month=(this.toDay.getMonth()+1)
    if(month < 10){
      month='0'+month
    }
    this.toDay=this.toDay.getFullYear()+'-'+ month+'-'+this.toDay.getDate()
    console.log(this.toDay)
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Admin My Reports', url: '/adminmyreports' });
    let ummUserId: any = localStorage.getItem('UmmUserId');
    this.ummUserID = ummUserId;
    this.showLoader = true;
    this.recentCustomerService.getRecentCustomer(ummUserId).subscribe({
      next: (response: any) => {
        let data: any = response;
        this.reports = data.report;
        this.vReportData = this.reports.filter((d: any) => d.ummUserId == ummUserId);
        this.showLoader = false;
      }, error: (err: any) => {
        console.log(err)
      }, complete: () => {
        console.log('completed')
      },
    });
  
  }

  BusinessNameChanged() {
    this.filter();
    console.log(this.sfromDate)
    console.log(this.stoDate)
  }
  sortReportByDate(reports: any) {
    // var startDate = new Date("2015-08-04");
    // var endDate = new Date("2015-08-12");
    var startDate = new Date(this.sfromDate);
    var endDate = new Date(this.stoDate);
    var resultData = reports.filter((r: any) => {
      var date = new Date(r.createdDate);
      return (date >= startDate && date <= endDate);
    });
    return resultData
  }

  filter() {
    let bsnessName = this.sbusinessName?.toLowerCase() || "";
    let reportName = this.sreportName?.toLowerCase() || "";
    console.log(bsnessName,reportName)
    let fromDate: any = null;
    let toDate: any = null;
    if (this.sfromDate == null || this.sfromDate == undefined) {
      let firstReportDate: any = this.reports.map((a: any) => a.createdDate).sort(
        function (date1: any, date2: any) {
          if (date1 > date2) return 1;
          if (date1 < date2) return -1;
          return 0;
        }
      )[0];
      //this.sfromDate = new Date(firstReportDate);
      fromDate = {
        isRange: false,
        singleDate: {
          date: {
            year: this.todayDate.getFullYear() - 10,
            month: this.todayDate.getMonth(),
            day: this.todayDate.getDate(),
          },
        },
      };
      fromDate = new Date(fromDate.singleDate.date.year - 10, fromDate.singleDate.date.month - 1, fromDate.singleDate.date.day);
    }
    else {
      fromDate = new Date(this.sfromDate)
      // fromDate = new Date(this.sfromDate.singleDate.date.year, this.sfromDate.singleDate.date.month - 1, this.sfromDate.singleDate.date.day);
    }

    if (this.stoDate == null) {

      toDate = {
        isRange: false,
        singleDate: {
          date: {
            year: this.todayDate.getFullYear(),
            month: this.todayDate.getMonth(),
            day: this.todayDate.getDate(),
          },
        },
      };

      toDate = new Date(toDate.singleDate.date.year, toDate.singleDate.date.month - 1, toDate.singleDate.date.day);
    } else {
      toDate = new Date(this.stoDate)
      // toDate = new Date(this.stoDate.singleDate.date.year, this.stoDate.singleDate.date.month - 1, this.stoDate.singleDate.date.day);
    };

    let generatedBy = "";

    this.vReportData = this.reports.filter((d: any) => d.ummUserId == this.ummUserID)
    
    if(bsnessName != '' && bsnessName != undefined){
      this.vReportData = this.vReportData.filter((d: any) => d.businessName.toLowerCase().includes(bsnessName.toLowerCase()))
    }
    if(reportName != '' && reportName != undefined){
      this.vReportData = this.vReportData.filter((d:any)=> d.reportName.toLowerCase().includes(reportName.toLowerCase()))
    }
    if(generatedBy != '' && generatedBy != undefined){
      this.vReportData=this.vReportData.filter((d:any)=> d.generatedBy.toLowerCase().includes(generatedBy.toLowerCase()))
    }
    if(fromDate && toDate){
      this.vReportData=this.vReportData.filter((d:any)=> d.createdDate != null && (new Date(d.createdDate) >= fromDate && new Date(d.createdDate) <= toDate));
    }
    console.log(this.vReportData)
  }

  downloadAnalyticsReport(analyticsReportUrl: any, reportId: any) {
    this.showLoader = true;
    this.createReportService.viewAnalyticsReportPdfBlob(AppSettings.base_url + "/ReportPdf/" + analyticsReportUrl).subscribe(
      (res: any) => {
        let fileName = 'analyticsReport' + '_' + reportId;
        this.sharedService.savePdfFile(res, fileName);
        this.showLoader = false;
      }
    );
  }

  viewReport(report: any) {
    this.sharedService.setReport(report);
    this.router.navigate(['./viewreport']);
  }

  deleteReport(report: any) {
    this.deleteReportId = report.reportId;
    $("#confirmReportDeleteModal").modal("show");
  }

  deleteReportConfirm() {
    let type = 'report';
    let scenarioId = 0;
    let result = this.deleteReportService.deleteReport(this.deleteReportId, scenarioId, type);
    result.subscribe({
      next: () => {
        $('#deleteReportSuccessModal').modal("show");
      }
    });
  }

  clickDeleteSuccess() {
    this.vReportData = this.vReportData.filter((d: any) => d.reportId != this.deleteReportId);
    if (localStorage.getItem('isadmin') == '1') {
      this.router.navigate(['/admindashboard']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  changePageSize(event: any) {
    this.pageSize = event.target.value;
  }

}
