import { Component, OnInit, Input, Directive } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { EditReportService } from '../_services/edit-report.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from "@angular/common";
import { CustomerDetailService } from '../_services/customer-detail.service';
import { DeleteReportService } from '../_services/deleteReport.service'
import { DataChangeService } from '../_services/data-change.service';
import { CreateReportService } from '../_services/create-report.service';
import { AppSettings } from '../appSettings';
import * as _ from 'underscore';
import { isNullOrUndefined } from 'util';
import { AppConstants } from '../appConstants';
import { MarketSetupService } from '../_services/market-setup.service';
import { HeaderService } from '../_services/header.service';
declare var $: any;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})

export class ReportComponent implements OnInit {
  isnum = false;
  @Input() reportData: any;
  @Input() scenarioData: any;
  @Input() businessNameTitle: any;
  inputDataType: any = "report";
  public reportInput: any;
  public reportOutput: any;
  public reportOutputInUSD: any;
  public scenarioDataInUSD: any;
  public isReportConversion = false;
  public reportStatus = "In Progress";
  public rr:any=localStorage.getItem('report');
  public report = this.sharedService.getReport() || JSON.parse(this.rr);
  public reportName: any;
  public reportDesc: any;
  isAdmin = localStorage.getItem('isadmin') == '1';
  UmmUserId = localStorage.getItem('UmmUserId');
  public date:any;
  public reportID: any;
  addScenarioValue:any = [];
  error:any = {};
  deleteReportId: any;
  deleteConfirmationMessage = this.sharedService.deleteConfirmationMessage;
  reportAuthorizeMessage = this.sharedService.reportAuthorizeMessage
  deleteSuccessMessage = this.sharedService.deleteSuccessMessage;
  updateReportSubscription: any;
  reportOutputChangeSubscription: any;
  scenarioDeleteSubscription: any;
  cancelDataInputSubscription: any;
  generateScenarioSubscription: any;
  //cloneflag: any;
  showLoader = false;
  defaultAdditionalInputSelections: any = this.sharedService.defaultAdditionalInputSelections.map(x => Object.assign({}, x));
  excelVersion: any;
  oldExcelVersionReportModalMessage = "The report is not allowed to edit due to old excel version.Please confirm if you want to clone the report for edit?";
  countryWithCurrencyList:any = [];
  reportMarketId = "2";
  reportMarketCurrency = "USD";
  reportCurrencyValue = 1;
  markets:any = [];
  allMarkets:any=[];
  currencySaveMessage = "Currency saved sccessfully";
  excelVersionDyn: string = "";
  constructor(public sharedService: SharedService,
    private CustomerDetailService: CustomerDetailService,
    private deleteReportService: DeleteReportService,
    private dataChangeService: DataChangeService,
    public router: Router,
    public createReportService: CreateReportService,
    public marketSetupService: MarketSetupService,
    private headerService: HeaderService) {

      this.headerService.getCurrentExcelVersion().subscribe((version: string) => {
        this.excelVersionDyn = version;
      })

    if (!isNullOrUndefined(this.report)) {
      this.reportName = this.report.reportName;
      this.reportDesc = this.report.reportDesc;
      this.excelVersion = this.report.excelVersion;
    }

    this.updateReportSubscription = this.dataChangeService.getUpdateReportEmitter()
      .subscribe((item:any) => {
        if (item.status.toLowerCase() != 'draft') {
          this.reportData.status = "In Progress";
        } else {
          this.reportData.status = "Draft";
        }

        this.reportData.showReportInput = false;
        this.reportData.showReportOutput = false;
        this.reportData.showStatusBlock = true;

        this.isReportConversion = false;
      });

    this.scenarioDeleteSubscription = this.dataChangeService.getScenarioDeleteEmitter()
      .subscribe((item:any) => {
        this.scenarioData = item.scenarioData;
      });

    this.reportOutputChangeSubscription = this.dataChangeService.getReportOutputChangeEmitter()
      .subscribe((item:any) => {
        this.reportMarketId = item.reportInput.MarketId.toString();
        localStorage.setItem('viewReportMarketId', this.reportMarketId);
        localStorage.setItem('reportMarketId', this.reportMarketId);
        this.reportCurrencyValue = item.reportInput.MarExcVal;
        if(!this.isReportConversion){
          this.reportOutput = item.reportOutput;
        }
        this.onCurrencyChange();
      });

    this.generateScenarioSubscription = this.dataChangeService.getGenerateScenarioEvent()
      .subscribe(item => {
        this.onCurrencyChange();
      });

    this.cancelDataInputSubscription = this.dataChangeService.getCancelDataInputEmitter()
      .subscribe((item:any) => {
        if (item.reportId != null && item.scenarioId == null && item.type == 'report') {
          this.reportData.showReportInput = false;
          this.reportData.showStatusBlock = true;
        }
      });

    setTimeout(() => {
      console.log('Passed reportData in ReportComponent', this.reportData);
      console.log('Passed scenarioData in ReportComponent', this.scenarioData);
      if (this.reportData.ReportInput != null && this.reportData.ReportOutput != null) {
        this.showLoader = false;
        this.reportData.ReportInput = this.sharedService.bindDataInput(this.reportData.ReportInput, 'report');
        if(!this.isReportConversion){
          this.reportOutput = this.reportData.ReportOutput;
        }
        this.onCurrencyChange();
        this.defaultAdditionalInputSelections = this.sharedService.markCheckedAdditionalInputSelection(this.reportData.ReportInput.InputCheckBoxId);
      }
    }, 10000);

    // Init default additional input data if not initiated till now
    if (_.isUndefined(this.sharedService.defaultAdditionalValues) ||
      _.isUndefined(this.sharedService.defaultAdditionalInputSelectedValues)) {
      this.sharedService.initDefaultAdditionalInputData();
    }
  }

  ngOnInit() {
    this.getMarkets();
  }

  getMarkets() {
    let that = this;
    this.showLoader = true;
    this.marketSetupService.getAllMarkets().subscribe((res:any) => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.allMarkets=responseData.map((r:any) => r);
      this.markets = responseData.map((r:any) => r);
      let mid:any=localStorage.getItem("MarketId");
      let userMarketIds = mid.split(',');
      if (userMarketIds.indexOf('2') == -1)
        userMarketIds.push('2');
      this.markets = this.markets.filter((m:any) => userMarketIds.indexOf(m.Id.toString()) != -1);
      this.showLoader = false;
    });
  }

  onKeyUpReportName(name:any) {
    this.isnum = /^\d+$/.test(name);
    if (this.isnum) {
      $('#reportName').css("border", "red solid 1px");
    }
    else {
      $('#reportName').css("border", "");
    }
  }

  createReportPdf(reportId:any): void {
    this.showLoader = true;
    this.CustomerDetailService.getReportPDFBlob(reportId).subscribe(
      (res) => {
        let fileName = reportId + '_' + this.reportData.ReportInput.name;
        this.sharedService.saveFile(res, fileName, AppConstants._pdfExtention);
        this.showLoader = false;
      }
    );
  }

  createReportExcel(reportId:any): void {
    this.showLoader = true;
    //let outputString = JSON.stringify(this.reportData.ReportOutput);
    let outputString = JSON.stringify(this.reportOutput);
    this.CustomerDetailService.createReportExcelBlob(outputString, reportId).subscribe(response => {
      this.showLoader = false;
      let fileName = reportId + '_' + this.reportData.ReportInput.name;
      this.sharedService.saveFile(response, fileName, AppConstants._excelExtention);
    });
  }

  editReport(reportInput: any): void {
    let currentExcelVersion = this.excelVersionDyn;
    if (this.report.excelVersion != currentExcelVersion) {
      $("#oldExcelVersionReportModal").modal("show");
      return;
    }

    if (reportInput.ummUserId == localStorage.getItem('UmmUserId')) {
      //Refresh the data-input property binding
      this.reportData.ReportInput = this.sharedService.bindDataInput(reportInput, 'report');
      this.sharedService.editreport = 1;
      this.sharedService.editscenario = 0;
      this.reportData.showReportInput = true;
      this.reportData.showStatusBlock = false;
      this.sharedService.setData('editreportID', reportInput.reportId);
      localStorage.setItem('editReportMarketId', this.reportMarketId);
    } else {
      $('#authorizeEditDeleteReportModal').modal("show");
    }
  }

  deleteReport(reportInput: any) {
    if (reportInput.ummUserId == localStorage.getItem('UmmUserId')) {
      this.deleteReportId = reportInput.reportId;
      $("#confirmReportDeleteModal").modal("show");
    } else {
      $('#authorizeEditDeleteReportModal').modal("show");
    }
  }

  deleteReportConfirm() {
    let type = 'report';
    let scenarioId = 0;
    let result = this.deleteReportService.deleteReport(this.deleteReportId, scenarioId, type);
    result.subscribe({next:() => {
      $('#deleteReportSuccessModal').modal("show");
    }
  });
  }

  clickDeleteSuccess() {
    if (localStorage.getItem('isadmin') == '1') {
      this.router.navigate(['/admindashboard']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  reportInputSelectionChange(objInputSelection:any) {
    this.defaultAdditionalInputSelections = objInputSelection;
  }

  addMoreScenario(flag:any) {
    let newScenario: any = {};
    newScenario.ScenarioOutput = [];
    newScenario.showScenarioInput = true;
    newScenario.showScenarioOutput = false;
    newScenario.showStatusBlock = false;
    newScenario.status = '';
    newScenario.focus = true;
    if (flag == 'none') {
      newScenario.ScenarioInput = this.sharedService.getDefaultInputData();
      newScenario.defaultAdditionalInputSelections = this.sharedService.defaultAdditionalInputSelections.map(x => Object.assign({}, x));
      newScenario.copyFrom = "none";
    } else if (flag.toLowerCase() == 'plana') {
      let cloneScenario = JSON.parse(JSON.stringify(this.reportData));
      newScenario.ScenarioInput = cloneScenario.ReportInput;
      if (!isNullOrUndefined(this.defaultAdditionalInputSelections))
        newScenario.defaultAdditionalInputSelections = this.defaultAdditionalInputSelections.map((x:any) => Object.assign({}, x));
      newScenario.copyFrom = "Report!" + this.reportData.ReportInput.reportId;
    } else {
      let cloneScenario = JSON.parse(JSON.stringify(this.scenarioData[flag]));
      newScenario.ScenarioInput = cloneScenario.ScenarioInput;
      if (!isNullOrUndefined(cloneScenario.defaultAdditionalInputSelections))
        newScenario.defaultAdditionalInputSelections = cloneScenario.defaultAdditionalInputSelections.map((x:any) => Object.assign({}, x));
      newScenario.copyFrom = "Scenario!" + cloneScenario.ScenarioInput.scenarioId;
    }

    newScenario.ScenarioInput.reportId = this.reportData.ReportInput.reportId;
    newScenario.ScenarioInput.scenarioId = null;
    newScenario.ScenarioInput.name = '';
    newScenario.ScenarioInput.description = '';
    this.scenarioData.push(newScenario);
  }

  scenarioInputData: any;
  addNewScenario() {
    if (this.reportData.ReportInput.ummUserId == localStorage.getItem('UmmUserId')) {
      if (this.reportData.ReportOutput[0].tabData.length == 0) {
        alert("Generate Report Output First!");
        return;
      }

      let currentExcelVersion = this.excelVersionDyn;
      if (this.report.excelVersion != currentExcelVersion) {
        $("#oldExcelVersionReportModal").modal("show");
        return;
      }


      this.scenarioInputData = this.scenarioData.map((s:any) => s.ScenarioInput);
      $("#addMoreScenarioModal").modal("show");
    } else {
      $('#authorizeEditDeleteReportModal').modal("show");
    }
  }

  checkedScenarioIds: any;
  validateGenerateReport() {

    let sIds:any = [];

    // this.checkedScenarioIds = $('.view-report-scenario-check').filter(':checked').map(function () {
    //   sIds.push(parseInt($(this).val()));
    //   return parseInt($(this).val());
    // });
    // Assuming you have an array of elements with class 'view-report-scenario-check'
    this.checkedScenarioIds = Array.from(document.querySelectorAll('.view-report-scenario-check:checked'))
    .map((element:any) => {
      const value = parseInt(element.value, 10);
      sIds.push(value);
      return value;
    });


    let selectedScenariosHavingOutput = this.scenarioData.filter((k:any) => sIds.indexOf(k.ScenarioInput.scenarioId) != -1 && k.ScenarioOutput[0].tabData.length > 0);

    if ($('.view-report-scenario-check').filter(':checked').length > 0 && selectedScenariosHavingOutput.length > 0)
      return true;

    return false;
  }

  downloadAnalyticsReport(analyticsReportUrl:any, reportId:any) {
    this.showLoader = true;
    this.createReportService.viewAnalyticsReportPdfBlob(AppSettings.base_url + "/ReportPdf/" + analyticsReportUrl).subscribe(
      (res) => {
        let fileName = 'analyticsReport' + '_' + reportId;
        this.sharedService.savePdfFile(res, fileName);
        this.showLoader = false;
      }
    );
  }

  cloneReportConfirm() {
    let reportId = this.report.reportId;
    let customerId = this.report.CustomerId;
    let reportExcelVesrion = this.report.excelVersion;
    this.createReportService.cloneReportConfirm(reportId, customerId, reportExcelVesrion);
  }

  cloneReportToUpgradeVersion() {
    let reportId = this.report.reportId;
    let customerId = this.report.CustomerId;
    this.createReportService.cloneReportToUpgradeVersion(reportId, customerId).subscribe(
    {
      next:(c:any)=>{
        if (localStorage.getItem('isadmin') == '1') {
          this.router.navigate(['./adminmyreports']);
        } else {
          this.router.navigate(['./dashboard']);
        }
      },error:(err:any)=>{
        console.log(err)
      }
    });
  }

  onCurrencyChange() {
    let market = this.allMarkets.find((m:any) => m.Id.toString() == this.reportMarketId.toString());
    this.reportMarketCurrency=market.Currency;
    localStorage.setItem('selectedCurrencyMarket', JSON.stringify(market));
    let currencySymbol = market.Symbol;
    let currencyValue = market.MarExcVal;
    let viewReportMarketId = localStorage.getItem('viewReportMarketId');
    if (this.reportMarketId.toString() == viewReportMarketId && this.reportCurrencyValue) {
      currencyValue = this.reportCurrencyValue;
    }
    localStorage.setItem('reportMarketId', this.reportMarketId);
    localStorage.setItem('reportCurrencyValue', currencyValue);
    this.changeReportView(currencySymbol, currencyValue);
  }

  changeReportView(currencySymbol:any, currencyValue:any) {

    if (this.reportOutput.length > 0 && !this.isReportConversion) {
      this.reportOutputInUSD = JSON.parse(JSON.stringify(this.reportOutput));
    }

    if (this.scenarioData.length > 0 && this.scenarioData.filter((sd:any) => sd.ScenarioOutput.length == 0).length == 0) {
      this.scenarioDataInUSD = JSON.parse(JSON.stringify(this.scenarioData))
    }

    if (this.reportMarketId != "2") {

      if (this.reportOutputInUSD && !this.isReportConversion) {
        let usdReportOutput = JSON.parse(JSON.stringify(this.reportOutputInUSD));
        this.reportOutput = this.sharedService.convertReportOutputByCurrency(usdReportOutput, currencySymbol, currencyValue);
        this.isReportConversion = true;
      }

      if (this.scenarioDataInUSD && this.scenarioDataInUSD.length == this.scenarioData.length) {
        let usdScenarioOutput = JSON.parse(JSON.stringify(this.scenarioDataInUSD))
        this.scenarioData = this.sharedService.convertScenarioOutputByCurrency(usdScenarioOutput, currencySymbol, currencyValue);
      }

    }

    if (this.sharedService.analysisOutputData) {

      //Analysis Data
      this.sharedService.analysisOutputData.ReportOutput = JSON.parse(JSON.stringify(this.reportOutput));

      if (this.sharedService.analysisOutputData.ScenarioOutput.length > 0 && this.scenarioData.length > 0) {
        for (let i = 0; i < this.sharedService.analysisOutputData.ScenarioOutput.length; i++) {
          this.sharedService.analysisOutputData.ScenarioOutput[i].data = JSON.parse(JSON.stringify(this.scenarioData[i].ScenarioOutput));
        }
      }

      // Populate Generate Report Output Field Groups
      this.dataChangeService.emitLoadGenerateReportOutputFieldGroupdEvent(true);

    }

  }

  updateReportCurrency() {
    this.showLoader = true;
    let reportId = this.report.reportId;
    this.createReportService.updateReportCurrency(reportId, this.reportMarketId).subscribe(res => {
      this.showLoader = false;
      $("#saveCurrencyMsgModal").modal("show");
    });
  }

  ngOnDestroy() {

    if (this.updateReportSubscription != null) {
      this.updateReportSubscription.unsubscribe();
    }

    if (this.reportOutputChangeSubscription != null) {
      this.reportOutputChangeSubscription.unsubscribe();
    }

    if (this.generateScenarioSubscription != null) {
      this.generateScenarioSubscription.unsubscribe();
    }

    if (this.scenarioDeleteSubscription != null) {
      this.scenarioDeleteSubscription.unsubscribe();
    }

    if (this.cancelDataInputSubscription != null) {
      this.cancelDataInputSubscription.unsubscribe();
    }
  }
}
