// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer p{max-width: 450px;
    width: 450px;
    margin: 0 auto;
    background: rgba(255,255,255,0.6);
    border-radius: 70px;
    padding: 7px 0;
    font-weight: bold !important;}`, "",{"version":3,"sources":["webpack://./src/app/login-footer/login-footer.component.css"],"names":[],"mappings":"AAAA,SAAS,gBAAgB;IACrB,YAAY;IACZ,cAAc;IACd,iCAAiC;IACjC,mBAAmB;IACnB,cAAc;IACd,4BAA4B,CAAC","sourcesContent":["footer p{max-width: 450px;\r\n    width: 450px;\r\n    margin: 0 auto;\r\n    background: rgba(255,255,255,0.6);\r\n    border-radius: 70px;\r\n    padding: 7px 0;\r\n    font-weight: bold !important;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
