import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { Router } from '@angular/router';
import { CreateReportService } from '../_services';
import * as _ from 'underscore';
import { DataChangeService } from '../_services/data-change.service';
import { isNullOrUndefined } from 'util';
declare var $: any;
@Component({
  selector: 'app-generate-report-modal',
  templateUrl: './generate-report-modal.component.html',
  styleUrls: ['./generate-report-modal.component.css']
})
export class GenerateReportModalComponent implements OnInit {

  analysisOutputData = this.sharedService.analysisOutputData;
  @Input() selectedScenarioIds: any;
  fieldGroups:any = [];
  analysisMappedData: any;
  analysisColumns:any = [];
  analysisRows:any = [];
  loadGenerateReportOutputFieldGroupdSubscription: any;
  constructor(public sharedService: SharedService,
    private createReportService: CreateReportService,
    private router: Router,
    private dataChangeService: DataChangeService) {
  }

  ngOnInit() {
    this.loadGenerateReportOutputFieldGroupdSubscription = this.dataChangeService.getLoadGenerateReportOutputFieldGroupdEvent()
      .subscribe(item => {
        this.fieldGroups = this.sharedService.getOutputFieldgroups();
      });
  }

  disableContinue() {
    let retVal:boolean = true;
    // $('.analysis-field').each(function () {
    //   if ($(this).find('input')[0].checked) {
    //     retVal = false;
    //   }
    // });
     retVal = !$('.analysis-field input').toArray().some(function (input:any) {
      return input.checked;
    });
  
    return retVal;
  };

  continueGenerateReport() {
    let that: any = this;
    if (this.sharedService.analysisOutputData == null)
      return;
    else
      this.analysisOutputData = this.sharedService.analysisOutputData;
    this.sharedService.analysisReportId = this.analysisOutputData.ReportId;
    this.sharedService.analysisScenarioIds = this.selectedScenarioIds;
    console.log(this.selectedScenarioIds)
    let idArray = this.selectedScenarioIds;
    this.analysisOutputData.ScenarioOutput = this.sharedService.analysisOutputData.ScenarioOutput.filter((s:any) => idArray.indexOf(parseInt(s.scenarioId)) != -1);

    console.log(this.analysisOutputData);
    let selectedFields:any = [];

    let singleSelectionIds = this.sharedService.singleLabelSelections;

    $('.analysis-field').each(function (this: HTMLElement) {
      if ($(this).find('input')[0].checked) {
        let fieldName = $($(this).find('span')[0]).text();
        let fieldId = $(this).find('input')[0].value;
        let filteredFieldGroups = that.fieldGroups.filter((f:any) => f.groupId == fieldId);
        if (filteredFieldGroups.length > 0) {
          let grpName = filteredFieldGroups[0].groupName;
          if (selectedFields.map((s:any) => s.name).indexOf(grpName) == -1) {
            selectedFields.push({ id: fieldId, name: grpName });
          }
        }

        if (singleSelectionIds.indexOf(fieldId) != -1) {
          selectedFields.push({ id: fieldId, name: fieldName });
        } else {
          let pLabels = that.analysisOutputData.ScenarioOutput[0].data.filter((k:any) => k.tabId == fieldId)[0].tabData.map((p:any) => p.primaryLabel);
          if (pLabels.indexOf(fieldName != -1)) {
            let subHeaders = that.sharedService.selectiveGroupCollectionData.filter((i:any) => i.tabId == fieldId)[0].data.map((m:any) => m.display);
            selectedFields.push({ id: fieldId, name: fieldName, subHeaders: subHeaders });
          } else {
            selectedFields.push({ id: fieldId, name: fieldName });
          }
        }
      }
    });

    let analysisReport = [];
    let record: any = {};

    this.sharedService.generateReportReportName = this.analysisOutputData.ReportName;
    this.sharedService.generateReportReportDesc = this.analysisOutputData.ReportDesc;
    record['planName'] = this.analysisOutputData.ReportName;
    // Report Entries
    _.each(selectedFields, function (selectedField: any) {
      let fieldId = selectedField.id;
      let fieldName = selectedField.name;

      if (isNullOrUndefined(record[fieldId])) {
        record[fieldId] = {};
      }
      
      if (singleSelectionIds.indexOf(selectedField["id"]) != -1) {
        record[fieldId][fieldName] = that.getFieldValue(that.analysisOutputData.ReportOutput, fieldId, fieldName, 'report');
      } else {
        let subHeaders = selectedField.subHeaders;
        if (subHeaders) {
          _.each(subHeaders, function (subHeader: any) {
            if (isNullOrUndefined(record[fieldId][fieldName]))
              record[fieldId][fieldName] = {};
            let tabData = that.analysisOutputData.ReportOutput.filter((y:any) => y.tabId == fieldId)[0].tabData;
            record[fieldId][fieldName][subHeader] = that.getFieldValueForMultiSelection(fieldId, tabData, subHeader, fieldName, 'report');
          });
        }
      }
    });

    analysisReport.push(record);

    // Scenario Entries
    _.each(this.analysisOutputData.ScenarioOutput, function (sOutPut: any) {
      record = {};
      record['planName'] = sOutPut.scenarioName;
      _.each(selectedFields, function (selectedField: any) {
        let fieldId = selectedField.id;
        let fieldName = selectedField.name;

        if (isNullOrUndefined(record[fieldId])) {
          record[fieldId] = {};
        }

        if (singleSelectionIds.indexOf(selectedField["id"]) != -1) {
          record[fieldId][fieldName] = that.getFieldValue(sOutPut.data, fieldId, fieldName, 'scenario');
        } else {
          let subHeaders = selectedField.subHeaders;
          if (subHeaders) {
            _.each(subHeaders, function (subHeader: any) {
              if (isNullOrUndefined(record[fieldId][fieldName]))
                record[fieldId][fieldName] = {};
              let tabData = sOutPut.data.filter((y:any) => y.tabId == fieldId)[0].tabData;
              record[fieldId][fieldName][subHeader] = that.getFieldValueForMultiSelection(fieldId, tabData, subHeader, fieldName, 'scenario');
            });
          }
        }
      });
      analysisReport.push(record);
    });

    _.each(this.analysisOutputData.ScenarioOutput, function (sOutPut: any) {
      //Diff
      record = {};
      record['planName'] = sOutPut.scenarioName + '-Plan A';
      _.each(selectedFields, function (selectedField: any) {
        let fieldId = selectedField.id;
        let fieldName = selectedField.name;

        if (isNullOrUndefined(record[fieldId])) {
          record[fieldId] = {};
        }

        if (singleSelectionIds.indexOf(selectedField["id"]) != -1) {
          record[fieldId][fieldName] = that.getFieldValue(sOutPut.data, fieldId, fieldName, 'diff');
        } else {
          let subHeaders = selectedField.subHeaders;
          if (subHeaders) {
            _.each(subHeaders, function (subHeader: any) {
              if (isNullOrUndefined(record[fieldId][fieldName]))
                record[fieldId][fieldName] = {};
              let tabData = sOutPut.data.filter((y:any) => y.tabId == fieldId)[0].tabData;
              record[fieldId][fieldName][subHeader] = that.getFieldValueForMultiSelection(fieldId, tabData, subHeader, fieldName, 'diff');
            });
          }
        }
      });
      analysisReport.push(record);
    });
    console.log('Analysis Report', analysisReport);
    this.analysisMappedData = analysisReport;
    this.sharedService.analysisMappedData = analysisReport;

    this.analysisColumns = [];
    this.analysisColumns.push('');


    _.each(this.analysisMappedData, function (dataItem: any) {
      that.analysisColumns.push(dataItem['planName']);
    });

    this.sharedService.analysisColumns = this.analysisColumns;

    _.each(selectedFields, function (selectedField: any) {
      let analysisRow:any = {};
      let fieldName = selectedField.name;
      let fieldId = selectedField.id;
      analysisRow['fieldName'] = fieldName;
      analysisRow['fieldId'] = fieldId;
      _.each(that.analysisMappedData, function (dataItem: any) {
        if (dataItem[fieldName + '$' + fieldId])
          analysisRow[dataItem['planName']] = dataItem[fieldId][fieldName + '$' + fieldId];
        else
          analysisRow[dataItem['planName']] = dataItem[fieldId][fieldName];
      });
      that.analysisRows.push(analysisRow);
    });

    this.sharedService.analysisRows = this.analysisRows;
    this.preparePPTChartData();
    $('.modal-backdrop').removeClass("modal-backdrop");
    $(document.body).removeClass('modal-open');
    this.createReportService.setLastReportData(null)
    this.router.navigateByUrl('/generatereport');
  }

  getFieldValue(scenarioOutPut: any, tabId:any, fieldName:any, type:any) {

    let returnVal :any= [];
    _.each(scenarioOutPut, function (item: any) {
      if (item.tabId == tabId) {
        let obj: any = _.first(_.filter(item.tabData, function (tDataItem: any) { return tDataItem.primaryLabel.trim() == fieldName.trim(); }));
        if (obj) {
          if (type == 'report')
            returnVal.push(obj.value["ExcelCell_X"]);
          else if (type == 'scenario')
            returnVal.push(obj.value["Scenario_ExcelCell_X"]);
          else if (type == 'diff')
            returnVal.push(obj.value["Scenario_ExcelCell_X-Report_ExcelCell_X"]);
        }
      }
    });

    return _.first(returnVal);
  }

  getFieldValueForMultiSelection(tabId:any, objOutput:any, subHeader:any, fieldName:any, type:any) {
    let returnVal = [];
    let obj = objOutput.filter((o:any) => o.primaryLabel.trim() == fieldName.trim())[0];
    let selectiveGroup = this.sharedService.selectiveGroupCollectionData.filter(f => f.tabId == tabId)[0].data.filter(f => f.display.trim() == subHeader.trim())[0]
    let mappedExcelCell: any;
    if (selectiveGroup)
      mappedExcelCell = selectiveGroup.cellMap;
    if (obj) {
      if (type == 'report') {
        returnVal.push(obj.value["ExcelCell_" + mappedExcelCell]);
      }
      else if (type == 'scenario') {
        returnVal.push(obj.value["Scenario_ExcelCell_" + mappedExcelCell]);
      } else if (type == 'diff') {
        returnVal.push(obj.value["Scenario_ExcelCell_" + mappedExcelCell + '-' + "Report_ExcelCell_" + mappedExcelCell]);
      }
    }

    return _.first(returnVal);
  }

  selectAllGroupFields(event:any) {
    $('.panel-body').find('input[type=checkbox]').prop('checked', $(event.target).prop('checked'));
  }

  fieldSelectionChanged(event:any) {
    let selectAllChecked = !($('.panel-body').find('input[type=checkbox]').filter(function (this:HTMLElement) { return $(this).attr('class').indexOf('select-all') == -1 && !$(this).prop('checked'); }).length > 0);
    $('.select-all').prop('checked', selectAllChecked)
  }

  preparePPTChartData() {
    let that = this;
    let currencySymbol=this.sharedService.getCurrencySymbol();
    let singleSelectionIds = this.sharedService.singleLabelSelections;
    let graphFields = this.sharedService.getAnalyticsPPTChartFields();

    graphFields = graphFields.map(function (gf) {
      if (['CA$', 'HTH$', 'NM$', 'DWP$'].indexOf(gf.fieldName) == -1) {
        gf.fieldName = gf.fieldName.toString().replace('$', currencySymbol).trim();
        gf.fieldDisplayName = gf.fieldDisplayName.toString().replace('$', currencySymbol).trim();
      }
      return gf;
    });

    let pptChartData:any = { graphsData: [] }
    graphFields.forEach(function (gField) {

      //report
      let val = 0;
      if (singleSelectionIds.indexOf(gField.tabId.toString()) != -1) {
          val = that.getFieldValue(that.analysisOutputData.ReportOutput, gField.tabId, gField.fieldName, 'report');
      } else {
        let subHeader = that.getGraphSubheader(gField.tabId);
        let tabData = that.analysisOutputData.ReportOutput.filter((y:any) => y.tabId == gField.tabId)[0].tabData;
        val = that.getFieldValueForMultiSelection(gField.tabId, tabData, subHeader, gField.fieldName, 'report');
      }
      pptChartData.graphsData.push({ tabId: gField.tabId, tabName: gField.tabName, graph: gField.graph, fieldName: gField.fieldName, fieldDisplayName: gField.fieldDisplayName, report: that.analysisOutputData.ReportName, scenario: '', scenarioMinusPlanA: '', isReportVal: true, isScenarioVal: false, isDiffVal: false, value: val });

      //scenario
      _.each(that.analysisOutputData.ScenarioOutput, function (sOutPut: any) {
        let val = 0;
        if (singleSelectionIds.indexOf(gField.tabId.toString()) != -1) {
          val = that.getFieldValue(sOutPut.data, gField.tabId, gField.fieldName, 'scenario');
        } else {
          let subHeader = that.getGraphSubheader(gField.tabId);
          let tabData = sOutPut.data.filter((y:any) => y.tabId == gField.tabId)[0].tabData;
          val = that.getFieldValueForMultiSelection(gField.tabId, tabData, subHeader, gField.fieldName, 'scenario');
        }
        pptChartData.graphsData.push({ tabId: gField.tabId, tabName: gField.tabName, graph: gField.graph, fieldName: gField.fieldName, fieldDisplayName: gField.fieldDisplayName, report: that.analysisOutputData.ReportName, scenario: sOutPut.scenarioName, scenarioMinusPlanA: '', isReportVal: false, isScenarioVal: true, isDiffVal: false, value: val });
      });

      //scenario-Plan-A
      _.each(that.analysisOutputData.ScenarioOutput, function (sOutPut: any) {
        let val = 0;
        if (singleSelectionIds.indexOf(gField.tabId.toString()) != -1) {
          val = that.getFieldValue(sOutPut.data, gField.tabId, gField.fieldName, 'diff');
        } else {
          let subHeader = that.getGraphSubheader(gField.tabId);
          let tabData = sOutPut.data.filter((y:any) => y.tabId == gField.tabId)[0].tabData;
          val = that.getFieldValueForMultiSelection(gField.tabId, tabData, subHeader, gField.fieldName, 'diff');
        }
        pptChartData.graphsData.push({ tabId: gField.tabId, tabName: gField.tabName, graph: gField.graph, fieldName: gField.fieldName, fieldDisplayName: gField.fieldDisplayName, report: that.analysisOutputData.ReportName, scenario: sOutPut.scenarioName, scenarioMinusPlanA: sOutPut.scenarioName + '-Plan A', isReportVal: false, isScenarioVal: false, isDiffVal: true, value: val });
      });
    });
    pptChartData= this.alterPPTChartDataAsPerSelectionIndex(pptChartData);
    this.sharedService['pptChartData'] = pptChartData;
  }

  alterPPTChartDataAsPerSelectionIndex(pptChartData:any){
    let dwpSelections=this.sharedService.outputDataWithSelectionIndex.filter((o:any)=>o.SelectionIndex=='DWP$');
    let currencySymbol=this.sharedService.getCurrencySymbol();
    let graphFields = this.sharedService.getAnalyticsPPTChartFields();
    graphFields = graphFields.map(function (gf) {
      if (['CA$', 'HTH$', 'NM$', 'DWP$'].indexOf(gf.fieldName) == -1) {
        gf.fieldName = gf.fieldName.toString().replace('$', currencySymbol).trim();
        gf.fieldDisplayName = gf.fieldDisplayName.toString().replace('$', currencySymbol).trim();
      }
      return gf;
    });
    let nmFields=graphFields.filter(g=>g.tabId==9).map(f=>f.fieldName);
    let dwpFields=graphFields.filter(g=>g.tabId==10).map(f=>f.fieldName);;
    let nmTotalFields=graphFields.filter(g=>g.tabId==13).map(f=>f.fieldName);
    let dwpTotalFields=graphFields.filter(g=>g.tabId==14).map(f=>f.fieldName);
    dwpSelections.forEach(function(dwp:any){
      if(dwp.IsReport){
       let reportName=dwp.ReportName;

       pptChartData.graphsData=pptChartData.graphsData.map(function(gData:any){
       
        if(gData.report==reportName && gData.tabId==9 && gData.isReportVal==true && nmFields.indexOf(gData.fieldName)!=-1){
         let fieldIndex=nmFields.indexOf(gData.fieldName);
         let equivalentDwpFieldName=dwpFields[fieldIndex];
         let replacementVal=pptChartData.graphsData.filter((h:any)=>h.report==reportName && h.tabId==10 && h.isReportVal==true && h.fieldName==equivalentDwpFieldName)[0].value;
         gData.value=replacementVal;
        }

        if(gData.report==reportName && gData.tabId==13 && gData.isReportVal==true && nmTotalFields.indexOf(gData.fieldName)!=-1){
          let fieldIndex=nmTotalFields.indexOf(gData.fieldName);
          let equivalentDwpTotalFieldName=dwpTotalFields[fieldIndex];
          let replacementVal=pptChartData.graphsData.filter((h:any)=>h.report==reportName && h.tabId==14 && h.isReportVal==true && h.fieldName==equivalentDwpTotalFieldName)[0].value;
          gData.value=replacementVal;
        }

        return gData;
      });

      }else{
        let scenarioName=dwp.ScenarioName;

        pptChartData.graphsData=pptChartData.graphsData.map(function(gData:any){
       
          if(gData.scenario==scenarioName && gData.tabId==9 && gData.isScenarioVal==true && nmFields.indexOf(gData.fieldName)!=-1){
           let fieldIndex=nmFields.indexOf(gData.fieldName);
           let equivalentDwpFieldName=dwpFields[fieldIndex];
           let replacementVal=pptChartData.graphsData.filter((h:any)=>h.scenario==scenarioName && h.tabId==10 && h.isScenarioVal==true && h.fieldName==equivalentDwpFieldName)[0].value;
           gData.value=replacementVal;
          }
  
          if(gData.scenario==scenarioName && gData.tabId==13 && gData.isScenarioVal==true && nmTotalFields.indexOf(gData.fieldName)!=-1){
            let fieldIndex=nmTotalFields.indexOf(gData.fieldName);
            let equivalentDwpTotalFieldName=dwpTotalFields[fieldIndex];
            let replacementVal=pptChartData.graphsData.filter((h:any)=>h.scenario==scenarioName && h.tabId==14 && h.isScenarioVal==true && h.fieldName==equivalentDwpTotalFieldName)[0].value;
            gData.value=replacementVal;
          }
  
          return gData;
        });

      }
    });

    return pptChartData;
  }

  getGraphSubheader(tabId:any) {
    let subHeader = '';
    switch (tabId) {
      case 9:
      case 10:
        subHeader = '△ Value';
        break;
      case 11:
        subHeader = 'sum';
        break;
      case 13:
      case 14:
        subHeader = '△ Value';
        break;
    }
    return subHeader;
  }

  ngOnDestroy() {
    if (this.loadGenerateReportOutputFieldGroupdSubscription != null) {
      this.loadGenerateReportOutputFieldGroupdSubscription.unsubscribe();
    }
  }
}
