import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { HeaderService } from '../_services/header.service';
import { Observable } from 'rxjs';

declare var $: any;
@Injectable()

export class ViewReportService {
  public ClToken = sessionStorage.getItem("Token");
  UserEmail = localStorage.getItem("email");
  refEmployeeId = '';
  public lastRecordData: Object = {};
  constructor(public _http: HttpClient,
    private headerService: HeaderService) { }

  public generateOutputForViewReport(ReportId?: any, reportUmmUserId?: any):Observable<any[]> {
    let UmmUserId = reportUmmUserId;
    let viewReportUrl = AppSettings._viewReportUrl + '?ummUserId='+ UmmUserId + '&reportId=' + ReportId;

    console.log('url:', viewReportUrl);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    return this._http.get<any[]>(viewReportUrl, {headers:options});
  }
}

