import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { AppConstants } from '../appConstants';
import { HeaderService } from '../_services/header.service';

@Component({
  selector: 'app-component-header',
  templateUrl: './component-header.component.html',
  styleUrls: ['./component-header.component.css']
})
export class ComponentHeaderComponent implements OnInit {

  @Input() title: any;
  @Input() fname: any;
  @Input() isDashboard: any = false;
  public isAdmin = localStorage.getItem('isadmin') == '1';
  public currentTime = new Date();
  public currentExcelVersion: any = '';
  constructor(private sharedService: SharedService, private headerService: HeaderService) { }

  ngOnInit() {
    // this.currentExcelVersion = AppConstants._current_excel_version;
    this.excelVersion();
  }

  excelVersion() {
    this.headerService.currentExcelVer().subscribe({
      next:(excelVer: any)=>{
        console.log("Excel Version: ", excelVer);
        if(excelVer && excelVer) {
          const parsedData = excelVer;
          if(parsedData?.ResponseData && parsedData?.ResponseData?.length != 0) {
            this.currentExcelVersion = parsedData?.ResponseData[0]?.VersionNo;
            this.headerService.setCurrentExcelVersion(this.currentExcelVersion);
          }
        }
      }, error:(err:any)=>{
        console.log(err)
      }, complete:()=>{
        console.log('completed')
      }
    })
  }


}
