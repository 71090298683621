import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-add-new-scenario',
  templateUrl: './add-new-scenario.component.html',
  styleUrls: ['./add-new-scenario.component.css']
})
export class AddNewScenarioComponent implements OnInit {
@Input() scenarioInputData:any;
cloneflag:any='none';
@Output() addNewScenario: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    console.log(this.scenarioInputData);
  }

  addScenario(cloneflag:any){
   this.addNewScenario.emit(cloneflag);
  }
}
