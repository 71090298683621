import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {
  transform(items: any[], argName:any,argEmail:any,argCity:any,argState:any,argZipCode:any ): any[] {
    console.log(items)
    if (!items) return [];
    if (argName==null) argName="";
    if (argEmail==null) argEmail="";
    if (argCity==null) argCity="";
    if (argState==null) argState="";
    if (argZipCode==null) argZipCode="";
    if(Object.keys(items).length != 0){
      return items.filter(it => {
        return it.Name !=null && it.Name.toString().toLowerCase().includes(argName.toString().toLowerCase())
           &&  it.emailId !=null && it.emailId.toLowerCase().includes(argEmail.toLowerCase())
           &&  it.city !=null && it.city.toLowerCase().includes(argCity.toLowerCase())
           &&  it.state !=null && it.state.toLowerCase().includes(argState.toLowerCase())
           &&  it.zipCode !=null && it.zipCode.toLowerCase().includes(argZipCode.toLowerCase());
      });
    }else{
      return [];
    }
  }
}