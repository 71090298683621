// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointer{
	cursor: pointer;
}
#head_class{
	background: white ;
}

.selectCustomerDropDown {
	width: 95%;
	border-radius: 20px;
	border: 1px solid #ccc; 
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
	transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	height: 35px;
	/* color: #3d7576; */
	color: black;
	text-align: center;
	
}

#customerOptions {
	background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/select-customer/select-customer.component.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;AACA;CACC,kBAAkB;AACnB;;AAEA;CACC,UAAU;CACV,mBAAmB;CACnB,sBAAsB;CACtB,2EAA2E;CAC3E,qEAAqE;CACrE,YAAY;CACZ,oBAAoB;CACpB,YAAY;CACZ,kBAAkB;;AAEnB;;AAEA;CACC,uBAAuB;AACxB","sourcesContent":[".pointer{\r\n\tcursor: pointer;\r\n}\r\n#head_class{\r\n\tbackground: white ;\r\n}\r\n\r\n.selectCustomerDropDown {\r\n\twidth: 95%;\r\n\tborder-radius: 20px;\r\n\tborder: 1px solid #ccc; \r\n\tbox-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);\r\n\ttransition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;\r\n\theight: 35px;\r\n\t/* color: #3d7576; */\r\n\tcolor: black;\r\n\ttext-align: center;\r\n\t\r\n}\r\n\r\n#customerOptions {\r\n\tbackground-color: black;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
