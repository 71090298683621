import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CreateReportService } from '../_services/index';
import { SharedService } from '../_services/shared-service.service';
import { EditReportService } from '../_services/edit-report.service';
import { DataChangeService } from '../_services/data-change.service'
import { ReportListService } from '../_services/index';
import { SupportRequestFor } from '../_enums/support-request-for';
import { SupportService } from '../_services/support.service';
import { MarketSetupService } from '../_services/market-setup.service';
declare var $: any;

@Component({
  selector: 'app-data-input',
  templateUrl: './data-input.component.html',
  styleUrls: ['./data-input.component.css']
})

export class DataInputComponent implements OnInit {
  isnum = false;
  public currentTime = new Date();
  businessNameTitle = this.sharedService.businessNameTitle || localStorage.getItem('businessNameTitle');
  saveSucessMessage: any = 'Request For Report Creation Sent Successfully';
  public isadmin = localStorage.getItem('isadmin');
  public date: any;
  public editReportData: any;
  public isscenarioedit = 1;
  public button = {
    index: 1,
    button1: 'BACK',
    button2: 'NEXT',
    button3: 'SAVE'
  }
  public isSettingsTouched = false;
  reportObject: {};
  @Input() inputData: any;
  @Input() inputDataType: any;
  @Input() ReportStatus: any = null;
  @Input() NoOfScenarioInReport: any = null;
  @Input() sIndex: number = 0;
  businessNameChangeSubscription: any;
  settingsData: any;
  ummUserId = localStorage.getItem('UmmUserId');
  c:any=localStorage.getItem('customer');
  customer = this.sharedService.getCustomer() || JSON.parse(this.c);
  @Input() defaultAdditionalInputSelections: any;
  @Output() inputSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() copyFrom: any;
  defaultAdditionalInputSelectedValues = [];
  r:any=localStorage.getItem('report');
  report = this.sharedService.getReport() || this.r;
  settingsId: any;
  popOverSaveDraftValidationText = 'Completed report with scenario can not be drafted.';
  apiErrorMessageText = 'Something went wrong.Please retry or notify the support team.';
  supportRequestFor:any=SupportRequestFor;
  markets = [];
  reportMarketId = "2";
  reportMarketCurrency = "USD";
  reportCurrencyValue = 1;
  currencySymbol = '$';
  showLoader = false;
  constructor(private router: Router,
    private createReportService: CreateReportService,
    private sharedService: SharedService,
    private editReportService: EditReportService,
    private dataChangeService: DataChangeService,
    private reportListService: ReportListService,
    private supportService: SupportService,
    private marketSetupService: MarketSetupService) {
    let r:any=localStorage.getItem('report');
    this.report = this.sharedService.getReport() || r;
    let c:any=localStorage.getItem('customer');
    this.customer = this.sharedService.getCustomer() || JSON.parse(c);
    this.ummUserId = localStorage.getItem('UmmUserId');
    this.reportObject = {};
    this.date = this.sharedService.getData('date');
    this.businessNameChangeSubscription = this.dataChangeService.getBusinessNameTitleChangeEmitter()
      .subscribe((item:any) => {
        this.businessNameTitle = item.businessNameTitle;
      });
  }

  ngOnInit() {
    //removed settings touched thing
    localStorage.removeItem('isSettingsTouched');
    let that = this;
    this.sharedService.validateLoggedInToken();
    this.settingsId = this.inputDataType == 'report' ? this.inputData.reportId : this.inputData.scenarioId;
    //edit report  or Scenario
    if (this.sharedService.editreport === 1 || this.sharedService.editscenario === 1) {
      //If edit report
      if (this.sharedService.editreport === 1) {
        this.saveSucessMessage = 'Report Updated Successfully';
      }
      //else  edit scenario
      else if (this.sharedService.editscenario === 1) {
        this.isscenarioedit = 0;
        this.saveSucessMessage = 'Scenario Updated Successfully';
      }
    }

    this.getMarkets();
  }

  ngAfterViewInit() {

    $('.report-tab-content input').mouseenter(function (e:any) {
      if (e.target.value == '0') {
        e.target.value = '';
      }
    });

    $('.report-tab-content input').mouseleave(function (e:any) {
      if (e.target.value == '') {
        e.target.value = '0';
      }
    });

    $('.report-tab-content input').keypress(function (e:any) {
      if (e.target.value == '0') {
        e.target.value = '';
      }
    });

    $('.report-tab-content input').change(function (e:any) {
      e.target.value = Math.abs(e.target.value)
    });

  }

  getMarkets() {
    let that = this;
    this.showLoader = true;
    this.marketSetupService.getAllMarkets().subscribe(res => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.markets = responseData.map((r:any) => r);

      let mId:any=localStorage.getItem("MarketId");
      let userMarketIds = mId.split(',');
      if (localStorage.getItem('isadmin') == '1') {
        let sUmId:any=localStorage.getItem('SelectedUserMarketIds');
        userMarketIds = sUmId.split(',');
      }

      if (userMarketIds.indexOf('2') == -1)
        userMarketIds.push('2');
      this.markets = this.markets.filter((m:any) => userMarketIds.indexOf(m.Id.toString()) != -1);
      localStorage.setItem('reportMarketId', this.reportMarketId);
      localStorage.setItem('reportCurrencyValue', this.reportCurrencyValue.toString());
      localStorage.setItem('reportMarketCurrency', this.reportMarketCurrency.toString());
      if (this.router.url != '/createreport') {
        this.currencySymbol = this.sharedService.getCurrencySymbol();
      }
      this.showLoader = false;
    });
  }

  isGenerateOutputDisabled = false;
  validateDataInput() {
    let isInvalidInput = false;
    let additionalInputInvalid1 = $('#additional-input-1' + this.sIndex).find('input[type=number]').filter(function (this:HTMLElement) { return $(this).attr('class').indexOf('ng-invalid') > 0 });
    let additionalInputInvalid2 = $('#additional-input-2' + this.sIndex).find('input[type=number]').filter(function (this:HTMLElement) { return $(this).attr('class').indexOf('ng-invalid') > 0 });
    let heifers = $('#heifers' + this.sIndex).find('input[type=number]').filter(function (this:HTMLElement) { return $(this).attr('class').indexOf('ng-invalid') > 0 });

    if (additionalInputInvalid1.length > 0 || additionalInputInvalid2.length > 0 || heifers.length > 0) {
      isInvalidInput = true;
    }

    if (this.inputData != null && this.inputData.name != null && this.inputData.description != null)
      if (this.inputData.name.trim() != "" && this.inputData.description.trim() != "" && !this.isGenerateOutputDisabled) {

        if (!isInvalidInput)
          return true;
      }

    return false;
  }

  getValidationMessage() {

    let validationMessage = "Name & Description are required.";

    if (this.inputData != null && this.inputData.name != null && this.inputData.description != null)
      if (this.inputData.name.trim() != "" && this.inputData.description.trim() != "") {
        validationMessage = "Valid input range values are required.";
      } else {
        validationMessage = "Name & Description are required.";
      }

    return validationMessage;
  }

  validateDataInputPopover(field:any, min:any, max = Number.MAX_SAFE_INTEGER) {
    let val = this.inputData[field];
    if (val >= min && val <= max) {
      $('#input-' + field).css('border', '');
      return true;
    }

    $('#input-' + field).css('border', '1px solid red');

    return false;
  }

  onBlurReportName(name:any) {
    let val = this.inputData[name];
    if (val == null) {
      $('#description').addClass('errorDesc');
    }
  }

  onKeyUpReportName(name:any) {
    this.isnum = /^\d+$/.test(name);
    if (this.isnum) {
      $('#reportname').css("border", "red solid 1px");
    }
    else {
      $('#reportname').css("border", "");

    }
  }


  isNumber(evt:any) {
    var iKeyCode = (evt.which) ? evt.which : evt.keyCode
    if (iKeyCode != 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57))
      return false;
    return true;
  }

  titles = ['Recipients', 'Beef', 'Convent', 'Sexed', 'Donors'];
  onBlurMethod(event:any,value:any, tabValue:any, title:any, breedings:any) {
    let otherTitlesExceptConvent = this.titles.filter(t => t != title && t != 'Convent');

    let total = Math.round(value);
    for (let i = 0; i < otherTitlesExceptConvent.length; i++) {
      total += parseInt(this.inputData[tabValue + otherTitlesExceptConvent[i] + breedings]);
    }
    let conventValue = 100 - total;
    if (conventValue >= 0) {
      this.inputData[tabValue + title + breedings] = Math.round(value);
      this.inputData[tabValue + 'Convent' + breedings] = conventValue;
      $(event.target).closest(".form-group").find('input[type=number]').removeClass('ng-invalid');
    }
    else {
      $(event.target).closest(".form-group").find('input[type=number]').addClass('ng-invalid');
    }
  }

  onBlurAdditionalInput(field:any, min:any, max=Number.MAX_SAFE_INTEGER) {
    let value = this.inputData[field];
    if (value >= min && value <= max) {
      this.inputData[field] = Math.abs(value);
    }
  }

  onBlurMilkingCow(value:any, field:any) {
    if (value < 100) {
      this.inputData[field] = 100;
    } else if (value > 100000) {
      this.inputData[field] = 100000;
    }
  }

  validateInput(e:any) {

    let keyCode = e.keyCode || e.charCode;
    if (keyCode >= 48 && keyCode <= 57)
      return true;

    return false;
  }

  validateAnnualCowCullRateInput(e:any) {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode == 46))
      return true;

    return false;
  }

  toggleTabContent(activeTabHref:any, index:any) {
    let tabContentIdList = ['#heifers' + index, '#parity1' + index, '#parity2' + index, '#additional-input-1' + index, '#additional-input-2' + index];
    let nonActiveTabIds = tabContentIdList.filter(t => t != activeTabHref);
    $(activeTabHref).removeClass('active').removeClass('in').addClass('active').addClass('in');
    for (let i = 0; i < nonActiveTabIds.length; i++) {
      $(nonActiveTabIds[i]).removeClass('active').removeClass('in');
    }
  }

  cNextButton(index:any) {

    let activeTabHref = $(".data-input-tab" + index + " > .active").next('li').find('a').attr("href");
    if (activeTabHref == ("#parity1" + index) || activeTabHref == ("#parity2" + index) || activeTabHref == ("#additional-input-1" + index) || activeTabHref == ("#additional-input-2" + index)) {
      $(".data-input-tab" + index + " > .active").next('li').find('a').trigger('click');
    }

    this.toggleTabContent(activeTabHref, index);
    this.pageindex('next', index)
  }

  pageindex(name:any, index:any) {

    if (name === 'previous') {

      this.button.index--;
      let activeTabHref = $(".data-input-tab" + index + " > .active").prev('li').find('a').attr("href");
      $(".data-input-tab" + index + " > .active").prev('li').find('a').trigger('click');
      this.toggleTabContent(activeTabHref, index);
    }
    else if (name === 'next') {
      this.button.index++;
    }
    //both generate output and save draft will come here
    else if (name === 'save') {
      this.button.index = 1;
    }
  }

  cReport(status:any) {
    this.inputData.InputCheckBoxId = this.defaultAdditionalInputSelections.filter((item:any) => item.checked)[0].id;
    if (this.inputDataType == 'report') {
      this.inputData.reportName = this.inputData.name;
      this.inputData.reportDesc = this.inputData.description;
    } else if (this.inputDataType == 'scenario') {
      this.inputData.scenarioName = this.inputData.name;
      this.inputData.scenarioDesc = this.inputData.description;
    }

    if (this.ReportStatus != null && this.NoOfScenarioInReport != null) {
      if (this.inputDataType == 'report' && this.ReportStatus.toLowerCase() == 'completed' && this.NoOfScenarioInReport > 0 && status == 'draft') {
        console.log("Completed report with scenario can not be drafted.");
        $('#draftValidationModal').modal('show');
        return;
      }
    }

    this.isGenerateOutputDisabled = true;

    let that = this;

    if (status == 'draft') {
      this.saveSucessMessage = 'Report Saved as Draft';
      localStorage.setItem('issavedraft', 'true');
      this.inputData.status = 'Draft';
    }
    else {
      this.inputData.status = 'Completed';
    }

    if (this.sharedService.editreport === 1) {

      this.inputData.reportId = this.sharedService.getData('editreportID');
      this.dataChangeService.emitUpdateReportEvent({ reportId: this.inputData.reportId, status: this.inputData.status });
      this.editReportService.updateReport(this.inputData, this.settingsData).subscribe(obj => {
        if (this.inputData.status != 'Draft') {

          that.dataChangeService.emitReportChangeEvent({ reportId: this.inputData.reportId, scenarioId: null, startLevelPTAindex: this.settingsData['startLevelPTAindex'] });
        }
        that.isGenerateOutputDisabled = false;
      });
    }
    else if (this.sharedService.editscenario === 1) {

      this.inputData.reportId = this.report.reportId;
      this.inputData.scenarioId = this.sharedService.getData('editscenarioId');

      this.dataChangeService.emitUpdateScenarioEvent({ reportId: this.inputData.reportId, scenarioId: this.inputData.scenarioId, status: this.inputData.status });
      this.dataChangeService.emitAddScenarioEvent({ index: this.sIndex, status: this.inputData.status });

      this.editReportService.updateScenario(this.inputData, this.settingsData).subscribe(obj => {
        that.inputData['startLevelPTAindex'] = that.settingsData['startLevelPTAindex'];
        that.dataChangeService.emitScenarioAddedEvent({ scenarioInputData: this.inputData, index: this.sIndex });
        that.dataChangeService.emitLoadGenerateReportOutputFieldGroupdEvent(true);
        that.isGenerateOutputDisabled = false;
      });
    }
    else {
      if (this.inputDataType == 'report') {
        this.sharedService.createReportInputData = this.inputData;
        // G28 Value to get minused by X140 (Next Generation-> Genetic Progress= X140-G28)
        this.sharedService.createReportInputData['startLevelPTAindex'] = this.settingsData['startLevelPTAindex'];
        this.createReportService.createReport(this.inputData, this.settingsData, function (obj:any) {
          if (obj) {
            that.reportObject = obj[0];
            that.sharedService.createReportInputData.reportId = obj[0].reportId;
            that.sharedService.createReportInputData.ummUserId = that.ummUserId;
            that.sharedService.createReportInputData.showStatusBlock = true;
            that.sharedService.createReportInputData.businessName = that.customer.businessName;
            that.sharedService.report = that.sharedService.createReportInputData;
            localStorage.setItem('report', JSON.stringify(that.sharedService.report));
          }
          $('#myModal').modal({ backdrop: 'static', keyboard: false });
          that.isGenerateOutputDisabled = false;
          that.dataChangeService.emitNewReportAddedEvent(true);
        })
        // .catch(() => {
        //   that.throwApiErrorMessage(SupportRequestFor.CreateReport);
        // });
      } else if (this.inputDataType == 'scenario') {
        if (this.inputData.reportId == undefined || this.inputData.reportId == null) {
          this.inputData.reportId = this.report.reportId;
        }
        this.dataChangeService.emitAddScenarioEvent({ index: this.sIndex, status: this.inputData.status });
        this.reportListService.createScenario(this.inputData, this.settingsData)
          .subscribe({
            next:(res:any) => {
              let body = res;
              let scenarioId = body[0].secnarioId;
              that.inputData.scenarioId = scenarioId;
              that.inputData.ummUserId = localStorage.getItem("UmmUserId");
              that.inputData['startLevelPTAindex'] = that.settingsData['startLevelPTAindex'];
              that.dataChangeService.emitScenarioAddedEvent({ scenarioInputData: this.inputData, index: this.sIndex });
              that.dataChangeService.emitUpdateScenarioEvent({ reportId: this.inputData.reportId, scenarioId: scenarioId, status: this.inputData.status });
              that.isGenerateOutputDisabled = false;
            },error:(err:any)=>{
              that.throwApiErrorMessage(SupportRequestFor.CreateScenario);
            }
          });
      }
    }
  }

  throwApiErrorMessage(requestFor: SupportRequestFor) {
    this.supportRequestFor = requestFor;
    this.isGenerateOutputDisabled = false;
    $('#apiErrorMessage').modal({ backdrop: 'static', keyboard: false });
    $('#apiErrorMessage').modal('show');
  }
  openSettingModal() {
    $("#mySetting").modal("show");
    $('.modal-backdrop').removeClass("modal-backdrop");
  }

  switchPage() {

    if (this.router.url === "/createreport") {
      this.router.navigateByUrl('/reportlist');
    }
    this.isGenerateOutputDisabled = false;
  }

  setNavBtnIndex(index:any) {
    this.button.index = index;
  }
  saveAsDraft() {
    $("#sDModal").modal("show");
  }

  cancelDataInput() {
    if (this.router.url === "/createreport") {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.dataChangeService.emitCancelDataInputEvent({ reportId: this.inputData.reportId, scenarioId: this.inputData.scenarioId, index: this.sIndex, type: this.inputDataType });
    }
  }

  settingsDataChange(data:any) {
    this.settingsData = data;
  }

  clearInput(e:any) {
    e.target.value = '';
  }

  changeAdditionalInputCheck(selection:any) {
    this.defaultAdditionalInputSelections = this.sharedService.markCheckedAdditionalInputSelection(selection.id);
    this.inputSelectionChange.emit(this.defaultAdditionalInputSelections)
    this.inputData = this.sharedService.setAdditionalInputSelection(this.inputData, selection)
  }

  notifySupport() {
    let that = this;
    this.supportService.notifySupport(this.inputData.reportId, '', SupportRequestFor[this.supportRequestFor], JSON.stringify(this.inputData), function (obj:any) {
      that.router.navigateByUrl('/dashboard');
    })
  }

  onCurrencyChange() {
    let market:any = this.markets.find((m:any) => m.Id == this.reportMarketId);
    this.reportCurrencyValue = market.MarExcVal;
    this.currencySymbol = market.Symbol;
    localStorage.setItem('reportMarketId', this.reportMarketId);
    localStorage.setItem('reportCurrencyValue', this.reportCurrencyValue.toString());
    localStorage.setItem('reportMarketCurrency',market.Currency);
    this.dataChangeService.emitCurrencySymbolChangeEvent(this.currencySymbol);
  }

  ngOnDestroy() {
    this.sharedService.editreport = 0;
    this.sharedService.editscenario = 0;
    if (this.businessNameChangeSubscription != null) {
      this.businessNameChangeSubscription.unsubscribe();
    }
  }
}
