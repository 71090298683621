import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/index';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { ReportListComponent } from './report-list/report-list.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { GenerateReportComponent } from './generate-report/generate-report.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { HistoricalReportsComponent } from './historical-reports/historical-reports.component';
import { AdminSearchComponent } from './admin-search/admin-search.component';
import { ViewReportComponent } from './view-report/view-report.component';
import { AdminMyReportsComponent } from './admin-my-reports/admin-my-reports.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { RegisterComponent } from './register/register.component';
import { MarketSetupComponent } from './market-setup/market-setup.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageCustomersComponent } from './manage-customers/manage-customers.component';
import { UserHasNoAccesComponent } from './user-has-no-acces/user-has-no-acces.component';
import { SessionOutComponent } from './session-out/session-out.component';
import { PocComponent } from './poc/poc.component';
import { LogsViewComponent } from './logs-view/logs-view.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { NgModule } from '@angular/core';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard] },
    { path: 'createreport', component: CreateReportComponent },
    { path: 'searchcustomer', component: SearchCustomerComponent, canActivate: [AuthGuard]},
    { path: 'reportlist', component: ReportListComponent },
    { path: 'customerdetails', component: CustomerDetailsComponent },
    { path: 'viewreport', component: ViewReportComponent },
    { path: 'resetpassword', component: ResetpasswordComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'generatereport', component: GenerateReportComponent },
    { path: 'admindashboard', component: AdminDashboardComponent },
    { path: 'historicalreports', component: HistoricalReportsComponent },
    { path: 'adminsearch', component: AdminSearchComponent },
    { path: 'adminmyreports', component: AdminMyReportsComponent },
    { path: 'change-password', component: ChangePasswordComponent },
    { path: 'market-setup', component: MarketSetupComponent, canActivate: [AuthGuard] },
    { path: 'manage-users', component: ManageUsersComponent, canActivate: [AuthGuard] },
    { path: 'manage-customers', component: ManageCustomersComponent },
    { path: 'user-has-no-access', component: UserHasNoAccesComponent },
    { path: 'session-out', component: SessionOutComponent },
    { path: 'app-logs-view', component: LogsViewComponent },


    // Poc only for test
    // { path: 'poc', component: PocComponent },

    // Poc only for test


    // otherwise redirect to Login
    { path: '**', redirectTo: '/login' },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    }
];

// export const routing = RouterModule.forRoot(appRoutes, { 
//     useHash: true
// });

@NgModule({
    imports: [RouterModule.forRoot(appRoutes,{
        useHash: true,
        initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    })],
    declarations: [
      // Your components, including AppComponent
    ],
  })
  export class AppRoutingModule { }