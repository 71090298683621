import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../appSettings';
declare var $: any;
declare var widgetlib: any;
declare var gigya: any;

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  public currentTime = new Date();
  web_url = AppSettings.web_url;
  resetWidgetLoadInProgress = true;
  loadIntervalId: any;
  constructor() { }

  ngOnInit() {
    let that = this;
    this.resetPasswordGigyaWidgetLoadHandler();
    this.loadIntervalId = setInterval(function () {
      if ($('#reset-password-gigya-widget-container form').length > 0) {
        $('#reset-password-gigya-widget-container form').ready(function () {
          clearInterval(that.loadIntervalId);
          that.resetWidgetLoadInProgress = false;
        });
      }
    }, 1000);
  }

  resetPasswordGigyaWidgetLoadHandler() {
    gigya.accounts.showScreenSet({ screenSet: 'RegistrationLogin', containerID: 'reset-password-gigya-widget-container', startScreen: 'gigya-forgot-password-screen', onBeforeScreenLoad: this.fnOnBeforeScreenLoad });
  }

  fnOnBeforeScreenLoad(eventObj:any) {
    if (eventObj.nextScreen == 'gigya-login-screen') {
      window.location.href = '/#/login';
    }
  }

}
