// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-access-msg{
    font-size: large !important;
    font-family: sans-serif;
}

.outer{     height: 100vh;
    /* background-image: url(clarifide_plus_lead_image.jpg); */
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #e4e4e4;}
    .panel.panel-default{    border-radius: 40px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 390px;
    box-shadow: 5px 6px 17px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffffbf;}
    .btn-sky {
    display: block;
    width: 100%;
    background: #01C1D6 !important;
    border: none;
    border-radius: 40px;
}
.fa-exclamation-triangle{color: #FF9800;}
.display_flex{display: flex;justify-content: center;}`, "",{"version":3,"sources":["webpack://./src/app/session-out/session-out.component.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA,YAAY,aAAa;IACrB,0DAA0D;IAC1D,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,4BAA4B;IAC5B,sBAAsB;IACtB,yBAAyB,CAAC;IAC1B,yBAAyB,mBAAmB;IAC5C,WAAW;IACX,4BAA4B;IAC5B,gCAAgC;IAChC,sBAAsB;IACtB,8CAA8C;IAC9C,2BAA2B,CAAC;IAC5B;IACA,cAAc;IACd,WAAW;IACX,8BAA8B;IAC9B,YAAY;IACZ,mBAAmB;AACvB;AACA,yBAAyB,cAAc,CAAC;AACxC,cAAc,aAAa,CAAC,uBAAuB,CAAC","sourcesContent":[".no-access-msg{\r\n    font-size: large !important;\r\n    font-family: sans-serif;\r\n}\r\n\r\n.outer{     height: 100vh;\r\n    /* background-image: url(clarifide_plus_lead_image.jpg); */\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    background-color: #e4e4e4;}\r\n    .panel.panel-default{    border-radius: 40px;\r\n    width: 100%;\r\n    background-repeat: no-repeat;\r\n    background-position: bottom left;\r\n    background-size: 390px;\r\n    box-shadow: 5px 6px 17px 0 rgba(0, 0, 0, 0.11);\r\n    background-color: #ffffffbf;}\r\n    .btn-sky {\r\n    display: block;\r\n    width: 100%;\r\n    background: #01C1D6 !important;\r\n    border: none;\r\n    border-radius: 40px;\r\n}\r\n.fa-exclamation-triangle{color: #FF9800;}\r\n.display_flex{display: flex;justify-content: center;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
