import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { HeaderService } from './header.service';

declare var $: any;
@Injectable()

export class SupportService {
  public ClToken = sessionStorage.getItem("Token");
  UserEmail = localStorage.getItem("email");
  refEmployeeId = '';
  public lastRecordData: Object = {};
  constructor(public _http: HttpClient,
    private headerService: HeaderService) { }

  public notifySupport(reportId:any, message:any, requestFor:any, inputString:any, callback:any) {
    let supportUrl = AppSettings._supportUrl;
    let ummUserId: any = localStorage.getItem('UmmUserId');
    let email = localStorage.getItem('email');
    let postModel = {
      reportid: reportId,
      message: message,
      requestFor: requestFor,
      inputString: inputString,
      ummUserId: ummUserId,
      userEmail: email
    }

    console.log('url:', supportUrl);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    return this._http.post(supportUrl, JSON.stringify(postModel), {headers:options}).subscribe({
     next:(retObj:any)=> {
      $('#suportSuccessMessage').modal({ backdrop: 'static', keyboard: false });
      $('#suportSuccessMessage').modal('show');
      callback(retObj); 
    },error:(err:any)=>{
      callback('exception');
    }
  }) 
    // }).catch(function (error) {
    //   callback('exception');
    // });;
  }
}

