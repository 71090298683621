import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-user-has-no-acces',
  templateUrl: './user-has-no-acces.component.html',
  styleUrls: ['./user-has-no-acces.component.css']
})

export class UserHasNoAccesComponent implements OnInit {
  noAccessMessage = 'You do not have permission to access the CLARIFIDE Profit Planner.  Please contact cpp@zoetis.com if you would like access to the CLARIFIDE Profit Planner.';
  ssoName = localStorage.getItem('ssoName');
  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  tryAgain() {
    this.authService.logoutSSO();
  }
}
