import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataChangeService } from '../_services/data-change.service';
import { MarketSetupService } from '../_services/market-setup.service';
import { SharedService } from '../_services/shared-service.service';
declare var $: any;

@Component({
  selector: 'app-market-setup',
  templateUrl: './market-setup.component.html',
  styleUrls: ['./market-setup.component.css']
})
export class MarketSetupComponent implements OnInit {
  market: any = {};
  allMarkets:any=[];
  markets:any = [];
  showLoader = false;
  pagedItems: any;
  countryWithCurrencyList:any = [];
  editMkt = false;
  validationMessage = "";
  selectedMarketId=null;
  searchCurrencyCode="";
  constructor(private sharedService: SharedService,
    private marketSetupService: MarketSetupService,
    private dataChangeService:DataChangeService,
    private _router: Router) { }

  ngOnInit() {
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Market Setup', url: '/market-setup' });
    this.resetMarket();
    this.getAllCountriesWithCurrency();
  }

  getAllCountriesWithCurrency() {
    this.showLoader = true;
    this.marketSetupService.getAllCountriesWithCurrency().subscribe({next:(res:any) => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.countryWithCurrencyList = responseData;
      this.getAllMarkets();
    },error:(err:any)=> console.log(err)});
  }

  getAllMarkets() {
    let that = this;
    this.showLoader = true;
    this.marketSetupService.getAllMarkets().subscribe({next:(res:any) => {
      let response: any = res;
      let responseData = response.ResponseData;
      this.markets = responseData.map((r:any) => r);
      this.markets = this.markets.sort((a:any, b:any) => (a.Id > b.Id ? -1 : 1));
      this.markets = this.markets.map(function (m:any) {

        let objCc = that.countryWithCurrencyList.filter((cc:any) => cc.Id == m.MarId)[0];

        if (objCc) {
          m.Market = objCc.Country;
          m.MarCurr = objCc.CurrencyName;
          m.MarCurrCode = objCc.Currency;
          m.MarCurrSym = objCc.Symbol;
        }

        return m;
      })

      this.allMarkets=this.markets;
      this.pagedItems = this.markets.slice(0, 10);
      this.showLoader = false;
    }, error:(err:any)=>{
      console.log(err)
    }
  });
  }

  resetMarket() {
    this.editMkt = false;
    this.market =
    {
      Id: 0,
      CountryId: 0,
      MarExcVal: "",
      IsActive: true
    };

  }

  validateMandatoryFields() {

    if (this.market.CountryId == 0
      || this.market.MarExcVal == "") {
      return false;
    }

    return true;
  }

  validateMarket() {

    let marketAlreadyExist = this.markets.filter((m:any) => m.MarId == this.market.CountryId).length > 0;

    if (marketAlreadyExist) {
      if (!this.editMkt) {
        this.validationMessage = "This market is already exist.";
        $("#marketValidationModal").modal("show");
      }
      return this.editMkt;
    }

    return true;
  }

  saveMarket() {
    if(!this.market.CountryId || !this.market.MarExcVal) {
      // console.log("all fields are mandatory");
      return;
    }
    let isValidMarket = this.validateMarket();
    if (!isValidMarket) {
      return;
    }
    let postModel =
    {
      Id: this.market.Id,
      CountryId: this.market.CountryId,
      MarExcVal: this.market.MarExcVal,
      IsActive: true
    };

    this.showLoader = true;
    this.marketSetupService.saveMarket(postModel).subscribe({
      next:(res:any) => {
        this.getAllMarkets();
      },
      error:(err:any)=>{
        console.log(err)
      }
    })
  }

  editMarket(market:any) {
    this.editMkt = true;
    this.market = market;
    this.market.CountryId = market.MarId;
  }

  pagedItemsChange(items:any) {
    this.pagedItems = items;
  }

  validateInput(e:any) {

    let keyCode = e.keyCode || e.charCode;
    if ((keyCode >= 48 && keyCode <= 57) || keyCode == 46) {
      return true;
    }
    return false;
  }

  onBlur(e:any) {
    var value = e.target.value;
    this.market.MarExcVal = parseFloat(value).toFixed(6);
  }

  onChangeStatus(status:any, market:any) {
    this.showLoader = true;

    let postModel =
    {
      Id: market.Id,
      CountryId: market.MarId,
      MarExcVal: market.MarExcVal,
      IsActive: status
    };

    this.marketSetupService.saveMarket(postModel).subscribe({
      next:(res:any)=>{
        this.getAllMarkets();
      }, error:(err:any)=>{
        console.log(err)
      }
    });
  }

  filterMarkets() {
    this.markets = this.selectedMarketId != null ? this.allMarkets.filter((u:any) => u.Id==this.selectedMarketId) : this.allMarkets;
    this.markets = this.searchCurrencyCode != '' ? this.markets.filter((x:any) => x.MarCurrCode.toLowerCase().startsWith(this.searchCurrencyCode.toLowerCase().trim())) : this.markets;
    this.dataChangeService.emitPagerReloadEvent(this.markets);
  }

}
