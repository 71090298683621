import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
import { SharedService } from '../_services/shared-service.service';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { HeaderService } from '../_services/header.service';
import { AppConstants } from '../appConstants';

@Injectable()
export class ReportListService {
    private _urlPlanB: string = AppSettings._urlPlanB;
    public vdata: any;
    excelVersion: string = "";
    constructor(
    public _http: HttpClient,
    public sharedService: SharedService,
    public headerService: HeaderService) {
        this.headerService.getCurrentExcelVersion().subscribe((version: string) => {
            this.excelVersion = version;
        })
    }

    createScenario(body:any,settingsData:any) {
        let data: any = {};

        data = this.sharedService.bindBodyData(data, body);

        this.checkZeroAndConvertToString(data);

        data = this.sharedService.bindDataNumberInputFields(data, body);
        data["annualCowCullRateGoal"] = parseInt(body.annualCowCullRateGoal) / 100;
        data["status"] = body.status;

        data["ummUserId"] = localStorage.getItem("UmmUserId");
        data["scenarioName"] = body.scenarioName;
        data["scenarioDesc"] = body.scenarioDesc;
        data["reportId"] = body.reportId;
        data['flagscenario'] = "scenario";
        data["excelVersion"] = this.excelVersion;

        this.vdata = data;

        data = this.sharedService.bindSettingsData(data, settingsData);
        data.InputCheckBoxId = body.InputCheckBoxId;

        //data=this.sharedService.inputConversionUSD(data);
        console.log('url:', this._urlPlanB);
        console.log('data passed with the url:', data);
        let options = this.headerService.getHeader();

        return this._http.post(this._urlPlanB, data, {headers:options});
    }

    generateOutput(reportid:any, scenarioid:any) {
        let generateOutputUrl = AppSettings._generateOutputUrl + reportid;

        console.log('url:', generateOutputUrl);
        console.log('data passed with the url:', '');
        let options = this.headerService.getHeader();
        return this._http.get(generateOutputUrl, {headers:options});
    }

    generateScenarioOutput(reportid:any, scenarioid:any) {

        let planBOutputUrl = AppSettings._scenarioOutputUrl + reportid + '/' + scenarioid;
        console.log('url:', planBOutputUrl);
        console.log('data passed with the url:', '');
        let options = this.headerService.getHeader();
        return this._http.get(planBOutputUrl, {headers:options});
    }

    checkZeroAndConvertToString(data:any) {

        for (var key in data) {
            if (key != 'scenarioName' && key != 'scenarioDesc' && key != 'reportId') {
                if (data[key] === 0) {
                    data[key] = data[key].toString();
                }
                else { data[key] = data[key].toString() + "%" };
            }
        }
        return data;
    }


    //methods for POC

    getPocData(){
      let options = this.headerService.getHeader();
      return this._http.get(AppSettings._getOriginalExcelsheet, {headers:options});
    }

    postPocPlanA(data:any){
      let options = this.headerService.getHeader();
      return this._http.post(AppSettings._postPlanAresult, data, {headers:options})
    }

    postPocPlanB(data:any){
      let options = this.headerService.getHeader();
      return this._http.post(AppSettings._postPlanBresult, data, {headers:options})
    }

    getPlaneAData(sessionId:string){
      let options = this.headerService.getHeader();
      return this._http.get(AppSettings._getPlaneAData+'?sessionId='+sessionId, {headers:options});
    }
    //methods for POC

}

