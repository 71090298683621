import { CommonModule } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerDetailService } from '../_services/customer-detail.service';
import { SharedService } from '../_services/shared-service.service';
import { EditReportService } from '../_services/edit-report.service';
import { DeleteReportService } from '../_services/deleteReport.service';
import { AppSettings } from '../appSettings';
import { CreateReportService } from '../_services';
import { SupportService } from '../_services/support.service';
import { SupportRequestFor } from '../_enums/support-request-for';
declare var $: any;
@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css'],
  providers: [DeleteReportService]
})
export class CustomerDetailsComponent implements OnInit {
  public currentTime = new Date();
  public isadmin = localStorage.getItem('isadmin');

  ClToken = sessionStorage.getItem("Token");
  UmmUserId = localStorage.getItem("UmmUserId");
  public editReportData: any;
  public vCustomerData: any;
  public vScenarioCount: any;
  public vreportCount: number=0;
  public vData: any = {};
  public slength: any;
  public vReports: any = [];
  public error: any;
  public errorMessage = "";
  public vScenario: any;
  public vReportData: any;
  public pdfUrl: any;
  public excelUrl: any;
  c:any=localStorage.getItem('customer');
  public customer = this.sharedService.getCustomer() || JSON.parse(this.c);
  public vCustomer:any;
  public date: any;
  public pagecomingfrom = localStorage.getItem('pagecomingfrom');

  deleteReportId: any;
  deleteConfirmationMessage = this.sharedService.deleteConfirmationMessage;
  reportAuthorizeMessage = this.sharedService.reportAuthorizeMessage
  deleteSuccessMessage = this.sharedService.deleteSuccessMessage;;
  showLoader = false;
  viewReportErrorMessage: string = this.sharedService.viewReportErrorMessageText;
  notifyReport: any;
  constructor(public router: Router,
    public CustomerDetailService: CustomerDetailService,
    public sharedService: SharedService,
    public deleteReportService: DeleteReportService,
    public EditReportService: EditReportService,
    public createReportService: CreateReportService,
    private supportService: SupportService) {
    this.date = this.sharedService.getData('date');
  }

  ngOnInit() {
    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'Customer Details', url: '/customerdetails' });
    let c:any=localStorage.getItem('customer');
    this.vCustomer = this.sharedService.getCustomer() || JSON.parse(c);
    console.log(c)
    console.log(this.vCustomer)
    console.log(this.sharedService.getCustomer())

    if (!!this.vCustomer) {
      sessionStorage.setItem("mdmAddressId", this.vCustomer.mdmAddressId);
      sessionStorage.setItem("businessName", this.vCustomer.businessName);
    }

    this.showLoader = true;
    this.CustomerDetailService.getReportScenario(this.customer.businessName, this.customer.city, this.customer.stateProvince, this.customer.postalCode, this.customer.customerId).subscribe((responce:any) => {
      this.showLoader = false;
      const data = JSON.stringify(responce);
      console.log(data)
      this.vData = responce;
      if(this.vData.customer.length){
        this.vReports = this.vData.customer[0].report;
      }

      console.log(this.vReports);

      for (var i = this.vReports.length - 1; i >= 0; i--) {
        this.vReports[i].scenariocount = this.vReports[i].scenario.length;
        this.vReports[i].scenarioempty = 10 - this.vReports[i].scenariocount;
        console.log(this.vReports[i].scenariocount);
        console.log('scenarioempty', this.vReports[i].scenarioempty);
      }

      this.error = 0;
      this.errorMessage = '';
      if ('' === this.vReports || 0 === this.vReports.length) {
        this.error = 1;
        this.errorMessage = 'This customer has not created any reports yet!';
      }
    });
    this.vCustomer = this.customer;

  }

  public viewReportClickfunction(report:any) {
    this.notifyReport = report;
    this.sharedService.setReport(report);
    this.router.navigate(['./viewreport']);
  }

  deleteReport(reportInput: any) {
    if (reportInput.ummUserId == localStorage.getItem('UmmUserId')) {
      this.deleteReportId = reportInput.reportId;
      $("#confirmReportDeleteModal").modal("show");
    } else {
      $('#authorizeEditDeleteReportModal').modal("show");
    }
  }

  deleteReportConfirm() {
    let type = 'report';
    let scenarioId = 0;
    let result = this.deleteReportService.deleteReport(this.deleteReportId, scenarioId, type);
    result.subscribe({next:() => {
      $('#deleteReportSuccessModal').modal("show");
    }});
  }

  clickDeleteSuccess() {
    this.router.navigate(['/dashboard']);
  }

  downloadAnalyticsReport(analyticsReportUrl:any, reportId:any) {
    this.showLoader = true;
    this.createReportService.viewAnalyticsReportPdfBlob(AppSettings.base_url + "/ReportPdf/" + analyticsReportUrl).subscribe(
      (res) => {
        let fileName = 'analyticsReport' + '_' + reportId;
        this.sharedService.savePdfFile(res, fileName);
        this.showLoader = false;
      }
    );
  }

  onViewReportError() {
  }

  notifySupport() {
    this.supportService.notifySupport(this.notifyReport.reportId, '', SupportRequestFor[SupportRequestFor.ViewReport], null, function (obj:any) {
    })
  }
}
