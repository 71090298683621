import { Component, OnInit, ViewChild } from '@angular/core';
import { Log, Logs, SearchLogs } from '../_interfaces/logs-view';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { LogsViewService } from '../_services/logs-view.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataChangeService } from '../_services/data-change.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/en-US';

@Component({
  selector: 'app-logs-view',
  templateUrl: './logs-view.component.html',
  styleUrls: ['./logs-view.component.css']
})
export class LogsViewComponent implements OnInit {

  pageNo: any = 1;
  pagedItems: any;

  logsForm: UntypedFormGroup = new UntypedFormGroup({});

  logsData: Log[] = [];

  UsersData = [];

  
// options sample with default values
options: DatepickerOptions = {
  minYear: getYear(new Date()) - 30, // minimum available and selectable year
  maxYear: getYear(new Date()) + 30, // maximum available and selectable year
  placeholder: '', // placeholder in case date model is null | undefined, example: 'Please pick a date'
  format: 'LLLL do yyyy', // date format to display in input
  formatTitle: 'LLLL yyyy',
  formatDays: 'EEEEE',
  firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
  locale: locale, // date-fns locale
  position: 'bottom',
  inputClass: '', // custom input CSS class to be applied
  calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
  scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
  // keyboardEvents: true // enable keyboard events
};
  constructor(
    private logsViewService: LogsViewService,
    private userService: UserService,
    private router: Router,
    private dataChangeService: DataChangeService
    ) { }

  ngOnInit(): void {
    this.initLogsForm();
    this.makeInitalCalls();
  }

  initLogsForm() {
    this.logsForm = new UntypedFormGroup({
      CreatedBy: new UntypedFormControl(null, [Validators.required]),
      ModuleName: new UntypedFormControl(null),
      CreatedAt: new UntypedFormControl(null)
    })
  }

  pagedItemsChange(items:any) {
    this.pagedItems = items;
  }

  makeInitalCalls() {
    this.searchLogs();
    this.userService.getAllUsers({Page: 1,}).subscribe((data: any) => {
      this.UsersData = data.ResponseData;
    })
  }

  pageChange(event: any) {
    this.pageNo = event;
  }

  refresh() {
    this.initLogsForm();
    this.searchLogs();
  }

  searchLogs() {
    if(!this.logsForm.valid) return;
    console.log(this.logsForm.value);
    this.pageNo = 1;
    let createdAt = null;
    if(this.logsForm.get('CreatedAt')?.value){
      // let d = new Date(this.logsForm.get('CreatedAt')?.value?.singleDate.date.year, this.logsForm.get('CreatedAt')?.value?.singleDate.date.month-1, this.logsForm.get('CreatedAt')?.value?.singleDate.date.day); 
      let d = new Date(this.logsForm.get('CreatedAt')?.value)
      createdAt = d.getFullYear() + "-" + (d.getMonth() + 1) +  "-" + (d.getDate()+1);
    }
    
    const payload: any = {
      CreatedBy: this.logsForm.get('CreatedBy')?.value,
      ModuleName: this.logsForm.get('ModuleName')?.value,
      CreatedAt: createdAt
    }
    console.log(payload);
    this.logsViewService.searchLogs(payload).subscribe((logs: any) => {
      if(logs && logs) {
        const parsedData = logs;
        console.log(parsedData);
        this.logsData = parsedData;
        this.pagedItems = parsedData?.slice(0, 10);
      } else {
        this.logsData = [];
        this.pagedItems = [];
      }
      this.dataChangeService.emitPagerReloadEvent(this.logsData)
    }, (error: Error) => {
      console.log(error);
    })
  }

}
