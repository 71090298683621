import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { DataChangeService } from '../_services/data-change.service';
import { SessionService } from '../_services/session.service';
import { LogsViewComponent } from '../logs-view/logs-view.component';
declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  public isadmin = localStorage.getItem('isadmin');
  UmmUserId = localStorage.getItem("UmmUserId");
  ClToken = sessionStorage.getItem("Token");
  AdminUmmUserId = localStorage.getItem("AdminUmmUserId");
  public name = '';
  allCustomer: any;
  getAllCustcopy: any;
  selectedcustomer: any;
  public intervalID:any;
  comparestring = "Selected Customer";
  isvalid: boolean = false;
  newReportSubscription: any;
  showLoader = false;
  logsViewComponent:any=LogsViewComponent;
  supportSuccessMessage = 'Your ticket has been successfully sent to the Support Team.';

  isAdmin: boolean=false;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private dataChangeService: DataChangeService,
    private sessionService: SessionService) {
    this.selectedcustomer = '';
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != '/createreport') {
          this.selectedcustomer = null;
        }
      }
    });
  }

  @HostListener('window:mousemove') refreshUserState() {
    this.sessionService.resetTimer(this.sessionService);
  }

  ngOnInit() {

    if (localStorage.getItem('ssoName')) {
      let ssoName:any = localStorage.getItem('ssoName');
      this.name = ssoName.split(',').length == 2 ? ssoName.split(',')[1].trim() : ssoName.trim();
    }

    if (localStorage.getItem('isadmin') == '1') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    $('.modal-backdrop').removeClass("modal-backdrop");
    $(".easy-sidebar-toggle").click(function (e:any) {
      e.preventDefault();
      e.stopPropagation();
      $("body").toggleClass("toggled");
      $(".navbar.easy-sidebar").removeClass("toggled");
    });

    $('#toggle-wrapper').on('click', function () {
      $("body").removeClass("toggled");
    });

    this.sessionService.initSession();
    this.sessionService.userInactive.subscribe(() => {
      this.router.navigate(['/session-out']);
    });
  }

  logout() {
    this.showLoader = true;
    this.authService.logoutSSO();
  }

  clickCreateReport() {
    this.dataChangeService.emitResetSelectCustomerEvent(true);
  }

}

