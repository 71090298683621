import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/index';
import { AppSettings } from '../appSettings';
import { SharedService } from '../_services/shared-service.service';
import * as _ from 'underscore';
declare var $: any;

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  returnUrl: string = '';
  public currentTime = new Date();
  web_url: string = AppSettings.web_url;
  logInWidgetLoadInProgress = true;
  loadIntervalId: any;
  updateProfileConfirmationMessage = "To continue with login, please update your profile by visiting the update profile url https://www.zoetisus.com/user-profile/profile.aspx.";
  showNewUser = false;
  noAccessMessage = 'You do not have permission to access the CLARIFIDE Profit Planner.  Please contact cpp@zoetis.com if you would like access to the CLARIFIDE Profit Planner.';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    // private msalService: MsalService
  ) { }


  public ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
    // this.authService.logoutSSO();

    // this.initiateAzureSSOLogin()
    let ssoAccount = this.authService.getLoggedInAccount();
    console.log(ssoAccount)
    // if (ssoAccount == null || "1" == localStorage.getItem("LoggedOut")) {
    if (ssoAccount == null) {
      this.authService.clearStorage();
      this.initiateAzureSSOLogin();
      console.log('initiateAzureSSOLogin')
    } else {
      this.navigateToDashboard();
      console.log('navigateToDashboard')
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    console.log(this.msalService.instance.getAllAccounts())
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[-1]);
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  initiateAzureSSOLogin() {
    let that = this;
    var loginRequest = {
      authority: AppSettings.authority
    };
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          console.log(response)
          this.msalService.instance.setActiveAccount(response.account);
          that.onAzureSSOLogin(response);
          console.log('MSAL Service Response After Login:- ', response);
          localStorage.removeItem("LoggedOut");
        });
    } else {
      this.msalService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          console.log(response)
          this.msalService.instance.setActiveAccount(response.account);
          that.onAzureSSOLogin(response);
          console.log('MSAL Service Response After Login:- ', response);
          localStorage.removeItem("LoggedOut");
        });
    }
    //Login Popup
    // this.msalService.loginPopup(loginRequest).then(response => {
    //   that.onAzureSSOLogin(response);
    //   console.log('MSAL Service Response After Login:- ', response);
    //   localStorage.removeItem("LoggedOut");
    // });
  }


  onAzureSSOLogin(response: any) {
    let that = this;
    var uid = response.uniqueId;
    var email = response.account.username;
    var token = response.idToken
    localStorage.setItem("ssoName", response.account.name);
    localStorage.setItem("ssoUserName", response.account.username);

    that.logInWidgetLoadInProgress = true;

    that.authService.authorizeSSOLogin(email, uid, token).subscribe((data: any) => {
      console.log(data)
      let responseData = data.ResponseData;

      if (!responseData.IsActive) {
        that.noAccess();
        return;
      }

      localStorage.setItem("UmmUserId", responseData.ummUserID);
      localStorage.setItem("fname", responseData.firstName);
      localStorage.setItem("lname", responseData.lastName);
      localStorage.setItem("email", responseData.emailId);

      localStorage.setItem('UserId', responseData.UserId);
      localStorage.setItem('MarketId', responseData.MarketId);

      //Token API Call
      that.getToken(email, responseData, response.account.name);

    },
      (err: any) => {
        console.log(err)
        if (err.hasOwnProperty("error")) {
          let data = err['error']
          if (!data.IsSuccess) {
            that.authService.clearStorage();
            that.noAccess();
          }
        }
      })
  }

  noAccess() {
    //No Access Message
    console.log(this.noAccessMessage);
    //$("#userHasNoAccessModal").modal("show");
    this.router.navigate(['/user-has-no-access']);
  }

  getToken(email: any, data: any, firstName: any) {
    let that = this;
    let userRole = data.userRole.toString();

    var body = {
      username: email,
      password: "",
      grant_type: 'password'
    };

    console.log(AppSettings._tokenUrl);
    $.ajax({
      type: "post",
      url: AppSettings._tokenUrl,
      data: body,
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      datatype: "json",
      async: false,
      success: function (data: any) {
        var clinfo = data;
        var cltoken = clinfo.access_token;
        sessionStorage.setItem("Token", cltoken);
        localStorage.setItem("Token", cltoken);
        localStorage.removeItem("LoggedOut");

        if ("1" === userRole) {
          localStorage.setItem("isadmin", "1");
          localStorage.setItem("adminFname", firstName);
          console.log('index.html :- admin dashboard called');
          that.navigateToDashboard();
        }
        else if ("0" === userRole) {
          localStorage.setItem("isadmin", "0");
          console.log('index.html :- dashboard called');
          that.navigateToDashboard();
        }
        else {
          window.location.href = '/#/login';
        }
      },
      error: function (result: any) {
        console.log(result)
      }
    });
  }


  navigateToDashboard() {
    //var Tkn = sessionStorage.getItem("Token");
    //if (Tkn) {
    localStorage.removeItem("breadcrumbs");
    this.sharedService.breadCrumbs = [];
    let isAdmin = localStorage.getItem("isadmin");
    if ("1" == isAdmin)
      this.router.navigate(['/admindashboard']);
    else
      this.router.navigate(['/dashboard']);
    //}
  }

  noAccessConfirm() {
    this.authService.logoutSSO();
  }
}
