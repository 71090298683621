import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpResponse } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { AppSettings } from '../appSettings';
import { SharedService } from '../_services/shared-service.service';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { HeaderService } from '../_services/header.service';
import { AppConstants } from '../appConstants';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError,map } from 'rxjs/operators';

declare var $: any;

@Injectable()
export class CreateReportService {

  private _urlPlanA: string = AppSettings._urlPlanA;
  public lastRecordData: Object = {};
  public data = null;
  cloneReportId: any;
  cloneCustomerId: any;
  reportExcelVersion: any;
  cloneReportConfirmationMessage = "Are you sure to clone the report?";
  cloneReportSuccessMessage = "The report is cloned successfully.";

  excelVersion: string = "";

  constructor(public _http: HttpClient,
    public sharedService: SharedService,
    public _router: Router,
    private headerService: HeaderService) {
      this.headerService.getCurrentExcelVersion().subscribe((version: string) => {
        this.excelVersion = version;
      })
  }

  getLastReportData(): any {
    return this.lastRecordData;
  }

  setLastReportData(data:any) {
    this.lastRecordData = data;
  }

  createReport(body:any, settingsData:any, callback:any) {

    let data: any = {};

    data = this.sharedService.bindBodyData(data, body);

    this.sharedService.checkZeroAndConvertToString(data);

    data = this.sharedService.bindDataNumberInputFields(data, body);

    data["annualCowCullRateGoal"] = parseInt(body.annualCowCullRateGoal) / 100;

    data["ReportName"] = body.name;
    data["ReportDesc"] = body.description;
    data["status"] = body.status;
    let cc:any=localStorage.getItem('customer');
    let customer = this.sharedService.getCustomer() || JSON.parse(cc);
    console.log(customer)
    if (customer != null) {
      data['CustomerId'] = customer.hasOwnProperty('custId')?parseInt(customer["custId"]):customer['customerId'];
      data['GlobalCustomerId'] = parseInt(customer["globalCustomerId"]);
      data["businessName"] = customer["businessName"];
      data["addressLine1"] = customer["addressLine1"];
      data["stateProvince"] = customer["stateProvince"];
      data["city"] = customer["city"];
      data["postalCode"] = customer["postalCode"];
    }

    let ummUserId = localStorage.getItem("AdminUmmUserId") || localStorage.getItem("UmmUserId");
    data["ummUserId"] = ummUserId;
    data["excelVersion"] = this.excelVersion;
    data = this.sharedService.bindSettingsData(data, settingsData);
    data.InputCheckBoxId = body.InputCheckBoxId;
    data['IsOldService']=false
    data['MarketId'] =localStorage.getItem('reportMarketId');

    this.data = data;
    let that = this;

    let fileInfo = { ReportName: body.name, ReportDesc: body.description };

    //data=this.sharedService.inputConversionUSD(data);
    console.log('url:', this._urlPlanA);
    console.log('data passed with the url:', data);
    let options = this.headerService.getHeader();
    return this._http.post(this._urlPlanA, data, {headers:options}).subscribe({
      next:(res:any)=>{
        that.extractData(res, callback, fileInfo)
      }
      ,error:(error:any) =>{
        // this.handleErrorObservable(error);
        callback('exception');
      }
    })  
  }
  
  private extractData(res: Response, callback:any, fileInfo:any) {
    let body:any = res;
    let data = body[0];
    var statusres = data.status;
    data["ReportName"] = fileInfo.ReportName;
    data["ReportDesc"] = fileInfo.ReportDesc;
    data["reportId"] = data.reportId;

    localStorage.setItem('lastReportID', data.reportId);

    this.lastRecordData = data;
    callback(body);
  }

  // private handleErrorObservable(error: Response | any) {
  //   console.log('handleErrorObservable');
  //   console.error(error.message || error);
  //   return Observable.throw(error.message || error);
  // }


private handleErrorObservable(error: Response | any) {
  console.log('handleErrorObservable');
  console.error(error.message || error);
  return throwError(error.message || error);
}

  generateAnalyticsReportPdfBlob(htmlString:any, repId:any, scenarioIds:any) {
    let that = this;
    let generateReportPdfUrl = AppSettings._generateAnalyticsReportPdfBlob;
    let postData =
    {
      inputString: htmlString,
      reportId: repId,
      scenarioIdstring: scenarioIds
    }

    console.log('url:', generateReportPdfUrl);
    console.log('data passed with the url:', JSON.stringify(postData));

    return this._http.post(generateReportPdfUrl, JSON.stringify(postData), { headers: this.headerService.getHeader(), responseType: 'blob' as 'json' })
      .pipe(map(
        (res:any) => {
          return new Blob([res], { type: 'application/pdf' })
        }))

  }

  viewAnalyticsReportPdfBlob(pdfUrl:any) {

    let that = this;
    let viewGeneratedReportPdfUrl = AppSettings._viewAnalyticsReportPdfBlob_url + '?fileUrl=' + pdfUrl;

    console.log('url:', viewGeneratedReportPdfUrl);
    console.log('data passed with the url:', '');

    return this._http.get(viewGeneratedReportPdfUrl, { headers: this.headerService.getHeader(), responseType: 'blob' as 'json' })
      .pipe(map(
        (res:any) => {
          return new Blob([res], { type: 'application/pdf' })
        }))
  }

  generateAnalyticsReportExcelBlob(htmlString:any, paramColumns:any, repId:any, scenarioIds:any) {
    let that = this;
    let selectedReportMarket:any= localStorage.getItem('selectedCurrencyMarket');
    let generateAnalyticsReportExcelUrl = AppSettings._generateAnalyticsReportExcelBlob_url;
    let postData =
    {
      inputString: htmlString,
      reportId: repId,
      scenarioIdstring: scenarioIds,
      columnNamesString: paramColumns,
      localMarketId: JSON.parse(selectedReportMarket).Id
    }

    console.log('url:', generateAnalyticsReportExcelUrl);
    console.log('data passed with the url:', JSON.stringify(postData));
    return this._http.post(generateAnalyticsReportExcelUrl, JSON.stringify(postData), { headers: this.headerService.getHeader(), responseType: 'blob' as 'json' })
      .pipe(map(
        (res:any) => {
          return new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
        }))
  }

  cloneReport(reportId:any, customerId:any) {

    let ummUserId = localStorage.getItem("AdminUmmUserId") || localStorage.getItem("UmmUserId");
    let cloneUrl = AppSettings._cloneReporturl + '?ummUserId=' + ummUserId + '&reportId=' + reportId + '&customerId=' + customerId;
    let that = this;

    console.log('url:', cloneUrl);
    console.log('data passed with the url:', {});
    let options = this.headerService.getHeader();
    return this._http.post(cloneUrl, {}, {headers:options});
  }

  cloneReportToUpgradeVersion(reportId:any, customerId:any) {
    let ummUserId = localStorage.getItem("AdminUmmUserId") || localStorage.getItem("UmmUserId");
    let cloneReportUpgradeUrl = AppSettings._cloneReportToUpgradeVersionUrl + '?ummUserId=' + ummUserId + '&reportId=' + reportId + '&customerId=' + customerId;
    let that = this;

    console.log('url:', cloneReportUpgradeUrl);
    console.log('data passed with the url:', {});
    let options = this.headerService.getHeader();
    return this._http.get(cloneReportUpgradeUrl, {headers:options});
  }

  cloneReportConfirm(reportId: any, cloneCustomerId: any, reportExcelVersion: any) {
    this.cloneReportId = reportId;
    this.cloneCustomerId = cloneCustomerId;
    this.reportExcelVersion = reportExcelVersion;
    $('#confirmCloneReportModal').modal({ backdrop: 'static', keyboard: false });
    $('#confirmCloneReportModal').modal('show');
  }

  continueCloneReport() {
    let that = this;
    if (this.reportExcelVersion == this.excelVersion) {
      this.cloneReport(this.cloneReportId, this.cloneCustomerId).subscribe({
        next:(res:any)=>{
          console.log("dashboard clone report", res);
          that.openCloneReportSuccessModal();
        }, error:(err:any)=>{
          console.log(err)
        }
      }
      );
    } else {
      this.cloneReportToUpgradeVersion(this.cloneReportId, this.cloneCustomerId).subscribe(
      {
        next:(res:any)=>{
          console.log("dashboard clone report", res);
          that.openCloneReportSuccessModal();
        },error:(err:any)=>{
          console.log(err)
        }
      });
    }
  }

  openCloneReportSuccessModal() {
    $('#cloneReportSuccessModal').modal({ backdrop: 'static', keyboard: false });
    $('#cloneReportSuccessModal').modal('show');
  }

  cloneReportSuccess() {
    this._router.navigate(['/admindashboard']);
  }

  updateReportCurrency(reportId:any,marketId:any){
    let updateReportMarketUrl = AppSettings._updateReportMarketUrl + "?ReportId=" + reportId + "&MarketId=" + marketId;
    let options = this.headerService.getHeader();
    return this._http.get(updateReportMarketUrl, options);
  }
}








