import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { HeaderService } from '../_services/header.service';
import { Observable } from 'rxjs';

declare var $: any;
@Injectable()
export class CustomerDetailService {
  ClToken = sessionStorage.getItem("Token");
  UserEmail = localStorage.getItem("email");
  refEmployeeId = '';
  constructor(public _http: HttpClient,
    public headerService: HeaderService) { }

  public getReportScenario(sBusinessName?: string, sCity?: any, sState?: any, sZipCode?: any, sCustomerId?: any):Observable<any[]> {
    let _customerDetailurl: string = AppSettings._customerDetailurl + sCustomerId;

    console.log('url:', _customerDetailurl);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    return this._http.get<any[]>(_customerDetailurl, {headers:options});
  }

  public getCustomerReportCount(mdmAddressId:any):Observable<any[]> {
    let _customerReportCountUrl: string = AppSettings._customerReportCountUrl + mdmAddressId;

    console.log('url:', _customerReportCountUrl);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    return this._http.get<any[]>(_customerReportCountUrl, {headers:options});
  }

  public getReportPDFBlob(sReportId?: any) {
    let UmmUserId = localStorage.getItem("UmmUserId");
    let _url: string = AppSettings._exportToPdfReportBlobUrl + UmmUserId + '?reportId=' + sReportId;

    console.log('url:', _url);
    console.log('data passed with the url:', '');

    return this._http.get(_url, { headers: this.headerService.getHeader(), responseType: 'blob' as 'json' }).pipe(map(
      (res:any) => {

        return new Blob([res], { type: 'application/pdf' })
      }))
  }

  //   Create Report Excel
  public createReportExcelBlob(outputString:any, sReportId?: any) {
    let selectedReportMarket:any= localStorage.getItem('selectedCurrencyMarket');
    let ummUserId = localStorage.getItem("UmmUserId");
    let _exportExcelReportUrl: string = AppSettings._exportExcelReportBlobUrl;
    console.log('url:', _exportExcelReportUrl);
    console.log('data passed with the url:', '');
    let options = this.headerService.getHeader();
    let postData =
    {
      outputString: outputString,
      reportId: sReportId,
      ummUserId: ummUserId,
      localMarketId: JSON.parse(selectedReportMarket).Id
    }

    return this._http.post<any[]>(_exportExcelReportUrl, JSON.stringify(postData), { headers: options, responseType: 'blob' as 'json' })
      .pipe(map(
        (res:any) => {
          return new Blob([res], { type: 'application/pdf' })
        }));
  }

  public generateScenarioPDFBlob(reportId:any, sScenarioId?: any) {
    let UmmUserId = localStorage.getItem("UmmUserId");
    let _url: string = AppSettings._exportToPdfScenariotBlobUrl + UmmUserId + '?reportId=' + reportId + '&scenarioId=' + sScenarioId;
    console.log('url:', _url);
    console.log('data passed with the url:', '');
    return this._http.get(_url, { headers: this.headerService.getHeader(), responseType: 'blob' as 'json' }).pipe(map(
      (res:any) => {
        return new Blob([res], { type: 'application/pdf' })
      }))
  }

  public createScenarioExcelBlob(outputString:any, reportId:any, sScenarioId:any, scenarioName:any) {
    let selectedReportMarket:any= localStorage.getItem('selectedCurrencyMarket');
    let _exportExcelScenarioUrl: string = AppSettings._exportExcelScenarioBlobUrl;
    console.log('url:', _exportExcelScenarioUrl);
    console.log('data passed with the url:', '');

    let postData = {
      outputString: outputString,
      reportId: reportId,
      scenarioId: sScenarioId,
      scenarioName: scenarioName,
      localMarketId: JSON.parse(selectedReportMarket).Id
    };

    return this._http.post(_exportExcelScenarioUrl, JSON.stringify(postData),{ headers: this.headerService.getHeader(), responseType: 'blob' as 'json' })
      .pipe(map(
        (res:any) => {
          return new Blob([res], { type: 'application/pdf' })
        }));
  }

}
