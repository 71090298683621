import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { AppSettings } from '../appSettings';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { SharedService } from '../_services/shared-service.service';
import { HeaderService } from '../_services/header.service';
import { Observable, throwError, catchError } from 'rxjs';

@Injectable()
export class CustomerListService {
  clToken:any;
  userId:any;
  constructor(public _http: HttpClient,
    public sharedService: SharedService,
    private headerService: HeaderService) {
  }

  public getCustomerListByUserId():Observable<any[]> {
    let header = this.headerService.getHeader();
    let ummUserId = localStorage.getItem("UmmUserId");
    if (localStorage.getItem('isadmin') == '1') {
      let selectedUmmUserId = localStorage.getItem('SelectedUmmUserId');
      if (selectedUmmUserId) {
        ummUserId = localStorage.getItem('SelectedUmmUserId');
      }
    }

    let getCustomerListUrl = AppSettings._customerListServiceurl + '?userId=' + ummUserId;

    console.log('url:', getCustomerListUrl);
    console.log('data passed with the url:', '');
    console.log(header)
    return this._http.get<any[]>(getCustomerListUrl, { headers: header });
  }
}
