import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { AppSettings } from '../appSettings';
import { HeaderService } from './header.service';
import { Observable } from 'rxjs';
declare var $: any;

@Injectable()
export class MarketSetupService {
  
  constructor(public _http: HttpClient,private headerService:HeaderService) { }

  public getAllMarkets() {
    
    let getAllMarketsUrl: string = AppSettings._getAllMarketsUrl;
    let options = this.headerService.getHeader();

    console.log('url:', getAllMarketsUrl);
    console.log('data passed with the url:', '');

    return this._http.get<any[]>(getAllMarketsUrl, {headers:options});
  }

  public getAllCountriesWithCurrency() {
    
    let getAllCountriesWithCurrencyUrl: string = AppSettings._getAllCountriesWithCurrencyUrl;
    let options = this.headerService.getHeader();

    console.log('url:', getAllCountriesWithCurrencyUrl);
    console.log('data passed with the url:', '');

    return this._http.get(getAllCountriesWithCurrencyUrl, {headers:options});
  }

  saveMarket(market:any){
    let saveMarketUrl=AppSettings._saveMarketUrl;
    let options = this.headerService.getHeader();

    console.log('url:', saveMarketUrl);
    console.log('data passed with the url:', market);

    return this._http.post(saveMarketUrl, market, {headers:options});
  }

}
