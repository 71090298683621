import { Component, OnInit } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { Router } from '@angular/router';
import { ViewReportService } from '../_services/view-report.service';
import { DataChangeService } from '../_services/data-change.service';
import { HeaderService } from '../_services/header.service';
import { ReportListService } from '../_services';
import * as _ from 'underscore';
import { SupportService } from '../_services/support.service';
import { SupportRequestFor } from '../_enums/support-request-for';

declare var $: any;

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.css'],
})
export class ViewReportComponent implements OnInit {

  public currentTime = new Date();
  public isadmin = localStorage.getItem('isadmin');
  public r:any=localStorage.getItem('report')
  public report:any = this.sharedService.getReport() || this.r?JSON.parse(this.r):null;
  public date;
  public reportID: any;
  ClToken = sessionStorage.getItem("Token");
  UmmUserId = localStorage.getItem("UmmUserId");
  reportChangeSubscription: any;
  public reportData: any = {};
  public scenarioData: any = [];
  public businessNameTitle: any;
  showLoader = false;
  openReportErrorMessageText = "Something went wrong while opening the report, please view the report later or notify the support team by clicking the support button."
  constructor(public sharedService: SharedService, public router: Router,
    private viewReportService: ViewReportService,
    private dataChangeService: DataChangeService,
    private headerService: HeaderService,
    private reportListService: ReportListService,
    private supportService: SupportService) {
    this.date = this.sharedService.formatDate(new Date());

    // Init Excel Mapping Data if not initiated till now
    let reportExcelVersion = this.report.excelVersion;
    if (_.isUndefined(this.sharedService.excelMappingData) || this.sharedService.excelVersion != reportExcelVersion) {
      this.sharedService.initExcelMappingsData(reportExcelVersion);
    }
  }

  reportCallIntervalId: any;
  ngOnInit() {

    this.sharedService.validateLoggedInToken();
    this.sharedService.setBreadcrumbs({ display: 'View Report', url: '/viewreport' });
    this.businessNameTitle = this.report.businessName;
    this.callServiceToGetReportData(this.report.reportId, 0);

    this.reportChangeSubscription = this.dataChangeService.getReportChangeEmitter()
      .subscribe((item:any) => {
        this.callServiceToGetReportData(item.reportId, item.scenarioId);
      });

    this.sharedService.outputDataWithSelectionIndex = [];
  }

  callServiceToGetReportData(reportId:any, scenarioId:any) {
    this.showLoader = true;
    let waitTime = 0;
    let intervalTime = 5000;
    this.reportCallIntervalId = setInterval(() => {

      if (waitTime >= 300000) {
        clearInterval(this.reportCallIntervalId);
        $('#openReportErrorMessage').modal({ backdrop: 'static', keyboard: false });
        $('#openReportErrorMessage').modal('show');
      }
      waitTime = waitTime + intervalTime;
      this.createOutputForSaveReports(reportId, scenarioId);
    }, intervalTime);
  }

  createOutputForSaveReports(reportId: any, scenarioId: any): void {
    let reportUmmUserId = this.report.ummUserId;
    let that = this;
    this.viewReportService.generateOutputForViewReport(reportId, reportUmmUserId).subscribe(response => {
      let resultData: any = response;
      if (!_.isEmpty(resultData)) {
        let dataBody =resultData;
        let reportData = dataBody.reportData;
        let scenarioData = dataBody.scenarioData;
        //Update report parameters in local storage
        this.updateReportParamsInLocalStorage(reportData);

        //Convert decimal to equivalent % value 
        reportData.ReportInput.annualCowCullRateGoal = (parseFloat(reportData.ReportInput.annualCowCullRateGoal) * 100).toFixed(0);
        let reportResponseData =
        {
          ReportInput: reportData.ReportInput,
          ReportOutput: this.sharedService.mapExcelOutputData(true, reportData.ReportInput, reportData.ReportOutput)
        };
        reportResponseData.ReportOutput = that.sharedService.selectionBasedIndexOutputChange(reportResponseData.ReportOutput, true, reportResponseData.ReportInput.InputCheckBoxId);


        if (reportResponseData.ReportInput.reportStatus == "Draft") {
          clearInterval(this.reportCallIntervalId);
          this.showLoader = false;
          this.reportData = reportResponseData;
          this.reportData.status = "Draft";
          this.reportData.showReportInput = false;
          this.reportData.showReportOutput = false;
          this.reportData.showStatusBlock = true;
          this.reportData.ReportInput.name = this.reportData.ReportInput.reportName;
          this.reportData.ReportInput.description = this.reportData.ReportInput.reportDesc;
          return;
        }
        //if (reportData.ReportOutput.length > 0) {
        //IsReportCreated equals to 1 if out generated else it equlas to 0
        if ((reportData.ReportOutput.length > 0) || (reportData.ReportInput.IsReportCreated == 0)) {
          this.reportData = reportResponseData;
          clearInterval(this.reportCallIntervalId);
          this.showLoader = false;
          this.reportData.status = "Completed";
          this.reportData.showReportInput = false;
          this.reportData.showReportOutput = true;
          this.reportData.showStatusBlock = true;
          this.reportData.ReportInput.name = this.reportData.ReportInput.reportName;
          this.reportData.ReportInput.description = this.reportData.ReportInput.reportDesc;
          this.dataChangeService.emitReportOutputChangeEvent({ reportInput: this.reportData.ReportInput, reportOutput: this.reportData.ReportOutput });
          this.scenarioData = [];
          for (let i = 0; i < 10; i++) {

            if (scenarioData[i]) {

              //Convert decimal to equivalent % value 
              scenarioData[i].ScenarioInput.annualCowCullRateGoal = (parseFloat(scenarioData[i].ScenarioInput.annualCowCullRateGoal) * 100).toFixed(0);

              scenarioData[i].reportId = this.reportData.ReportInput.reportId;

              let scenario =
              {
                ScenarioInput: scenarioData[i].ScenarioInput,
                ScenarioOutput: this.sharedService.mapExcelOutputData(false, scenarioData[i].ScenarioInput, scenarioData[i].ScenarioOutput)
              };

              scenario.ScenarioOutput = that.sharedService.selectionBasedIndexOutputChange(scenario.ScenarioOutput, false, scenario.ScenarioInput.InputCheckBoxId);

              this.scenarioData.push(scenario);

              if (scenario.ScenarioInput.status.toLowerCase() == "draft") {
                this.scenarioData[i].status = "Draft";
              }
              else if (scenario.ScenarioInput.status.toLowerCase() == "completed") {
                this.scenarioData[i].status = "Completed";
              }

              this.scenarioData[i].showScenarioInput = false;
              this.scenarioData[i].showScenarioOutput = true;
              this.scenarioData[i].showStatusBlock = true;
              this.scenarioData[i].ScenarioInput.name = this.scenarioData[i].ScenarioInput.scenarioName;
              this.scenarioData[i].ScenarioInput.description = this.scenarioData[i].ScenarioInput.scenarioDesc;
              this.scenarioData[i].defaultAdditionalInputSelections = this.sharedService.markCheckedAdditionalInputSelection(this.scenarioData[i].ScenarioInput.InputCheckBoxId);
            }

          }
          // Analysis Data
          this.sharedService.analysisOutputData = {
            ReportId: this.reportData.ReportInput.reportId,
            ReportName: this.reportData.ReportInput.reportName,
            ReportDesc: this.reportData.ReportInput.reportDesc,
            ReportOutput: this.reportData.ReportOutput,
            ScenarioOutput: this.scenarioData.filter((d:any) => d.status.toLowerCase() != 'draft').map((s:any) => {
              return {
                data: s.ScenarioOutput,
                scenarioId: s.ScenarioInput.scenarioId,
                scenarioName: s.ScenarioInput.scenarioName,
                scenarioDesc: s.ScenarioInput.scenarioDesc,
                showScenarioOutput: true
              };
            }),
          };

        } else {
          console.log('Trying again for view report details..');
        }

        console.log("View Report: Report Data-", this.reportData);
        console.log("View Report: Scenario Data-", this.scenarioData);
      }
    });
  }

  updateReportParamsInLocalStorage(reportData:any) {
    let r:any=localStorage.getItem('report')
    let report = this.sharedService.getReport() || r?JSON.parse(r):null;
    report.IsReportCreated = reportData.ReportInput.IsReportCreated;
    report.excelVersion = reportData.ReportInput.excelVersion;
    this.sharedService.setReport(report);
  }

  onReportOpenError() {
    this.router.navigate(['/dashboard']);
  }

  notifySupport() {
    let that = this;
    this.supportService.notifySupport(this.report.reportId, '', SupportRequestFor[SupportRequestFor.ViewReport], null, function (obj:any) {
      that.router.navigate(['/dashboard']);
    })
    // .catch(() => {
    //   that.router.navigate(['/dashboard']);
    // });
  }

  ngOnDestroy() {
    //Clear all existing intervals for this component
    clearInterval(this.reportCallIntervalId);
    if (this.reportChangeSubscription != null) {
      this.reportChangeSubscription.unsubscribe();
    }
  }
}
