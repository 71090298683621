// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ppt-icon {
    background: #00a6c6;
    padding: 5px;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/analysis-report-ppt/analysis-report-ppt.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".ppt-icon {\r\n    background: #00a6c6;\r\n    padding: 5px;\r\n    color: #fff;\r\n    border-radius: 50%;\r\n    font-size: 12px;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
