import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services';

@Injectable()
export class AuthGuard  {

    constructor(private router: Router, private authService:AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let path:any=''
        if(route.routeConfig && route.routeConfig.path){
            path=route.routeConfig.path
            console.log(path)
        }
        let isRouteAllowed= true;
        
        if(localStorage.getItem("isadmin")!="1" && ['manage-users','market-setup'].indexOf(path)!=-1){
            isRouteAllowed=false;
        }

        if (localStorage.getItem('Token') && isRouteAllowed) {
            // logged in so return true
            return true;
        }

    this.authService.logoutSSO()
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}