import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logs, SearchLogs } from '../_interfaces/logs-view';
import { HeaderService } from './header.service';
import { AppSettings } from '../appSettings';
import { Observable } from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class LogsViewService {

  constructor(private http: HttpClient, public headerService: HeaderService) { }

  searchLogs(payload:any) {
    const options = this.headerService.getHeader();
    const url = this.getUrlWithParams(payload);
    return this.http.get(url, {headers:options});
  }

  getUrlWithParams(payload:any) {
    let url = AppSettings._searchLogs;
    let alreadyGotQueryParam = false;
    if(payload.ModuleName && payload.ModuleName.length != 0) {
      url += "?ModelName=" + payload.ModuleName;
      alreadyGotQueryParam = true;
    }
    if(payload.CreatedBy) {
      if(alreadyGotQueryParam) {
        url += "&";
      } else {
        url += "?";
      }
      url += "CreatedBy=" + payload.CreatedBy;
      alreadyGotQueryParam = true;
    }

    if(payload.CreatedAt) {
      if(alreadyGotQueryParam) {
        url += "&";
      } else {
        url += "?";
      }
      url += "CreatedAt=" + payload.CreatedAt;
    }
    return url;
  }
}
