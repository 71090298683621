import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../_services/shared-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from "@angular/common";
import { CustomerDetailService } from '../_services/customer-detail.service';
import { isNullOrUndefined } from 'util';
declare var $: any;
declare var _: any;

@Component({
  selector: 'app-report-output',
  templateUrl: './report-output.component.html',
  styleUrls: ['./report-output.component.css']
})
export class ReportOutputComponent implements OnInit {

  @Input() reportOutput: any;
  @Input() businessNameTitle: any;
  isNullOrUndefined = isNullOrUndefined;
  constructor(public sharedService: SharedService, private CustomerDetailService: CustomerDetailService) { }

  ngOnInit() {
    console.log('Passed reportOutput in ReportOutputComponent', this.reportOutput);
  }

}
