// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=radio]
{
    margin: 4px 6px 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/add-new-scenario/add-new-scenario.component.css"],"names":[],"mappings":"AAAA;;IAEI,4BAA4B;AAChC","sourcesContent":["input[type=radio]\r\n{\r\n    margin: 4px 6px 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
