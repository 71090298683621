import { AppSettings } from '../app/appSettings';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  msalConfig: {
    auth: {
        clientId: AppSettings.clientId,
        authority: AppSettings.authority
    }
},
apiConfig: {
  scopes: ['user.read'],
  uri: 'https://graph.microsoft.com/v1.0/me'
}
  //Local - Hari's Azure - Test App 
  // clientId: '25b4a8d4-bf69-4b75-8bb6-57d1169102b4',
  // authority: 'https://login.microsoftonline.com/9e026c5a-9d05-440b-aff5-86f99b57b13c',
  // redirectUrl: 'http://localhost:4200'
};
