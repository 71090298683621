import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../appSettings';
declare var $: any;
declare var widgetlib: any;

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {

  public currentTime = new Date();
  web_url = AppSettings.web_url;
  updateProfileWidgetLoadInProgress = true;
  loadIntervalId: any;

  constructor() { }
  ngOnInit() {
   }
}

